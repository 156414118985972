import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import {MokasSaleStates} from "./types/MokasSale";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Image from "antd/lib/image";
import Form from "antd/lib/form";
import NormalHeader from "../../components/layouts/NormalHeader";
import FooterSection from "../../components/footer/FooterSection";
import Input from "antd/lib/input";
import {Breadcrumb, Popover, Upload} from "antd";
import TextArea from "antd/es/input/TextArea";
import DatePicker from "antd/lib/date-picker";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import AreaProvinceSelect from "../../components/area/AreaProvinceSelect";
import AreaCitySelect from "../../components/area/AreaCitySelect";
import AreaDistrictSelect from "../../components/area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../../components/area/AreaSubdistrictSelect";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import moment from "moment";
import Modal from "antd/lib/modal";
import Tabs from "antd/lib/tabs";
import Select from "antd/lib/select";
import VehicleModelSelect from "../../components/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../components/vehicle-copan/VehicleVariantSelect";
import VehicleVariantColorSelect from "../../components/vehicle-copan/VehicleVariantColorSelect";
import CurrencyInput from "react-currency-input-field";
import Checkbox from "antd/lib/checkbox";
import {InfoCircleOutlined} from "@ant-design/icons";
import Button from "antd/lib/button";
import Collapse from "antd/lib/collapse";
import CropIdCardImage from "../../components/image/CropIdCardImage";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import Spin from "antd/lib/spin";
import UploadMultiImage from "../../components/image/UploadMultiImage";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";

const {Panel} = Collapse;

class MokasSale extends Component<any, MokasSaleStates> {
    private readonly initState!: MokasSaleStates;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda | Jual Motor";

        this.initState = {
            company: "AMARTA",
            areaCode: "bandung",
            isDesktop: false,

            idCardNumber: "",
            idCardName: "",
            birthPlace: "",
            birthDate: moment(new Date(), 'YYYY-MM-DD'),
            fullAddress: "",
            provinceCode: null,
            provinceName: null,
            cityCode: null,
            cityName: null,
            districtCode: null,
            districtName: null,
            subdistrictCode: null,
            subdistrictName: null,
            postalCode: "",
            hamlet: "",
            neighbourhood: "",
            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,

            showOldUploadRawImage: false,
            freezeStatus: false,
            base64IdCardOwner: "",

            licensePlate: "",
            vehicleBrandUuid: "afce6884-fc4d-4f7a-b784-09d2a2f7b903",
            vehicleBrandName: "honda",
            vehicleModelUuid: null,
            vehicleModelName: null,
            vehicleModelOtherName: null,
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
            vehicleColorCode: null,
            vehicleColorName: null,
            vehicleYear: new Date().getFullYear(),
            vehiclePrice: 0,

            completeCertificate: true,
            permittedMakeOffer: true,
            activeCertificate: true,
            ownerAsCertificate: true,

            caption: "",
            description: "",
            mileage: null,
            listingOption: null,
            agentCode: "",
            loading: false,
            phoneNumber: "",

            listImages: [],
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
            source: "amartahonda.com",
            notes: "cash forms"
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    onFieldChange = <T extends keyof Pick<any, "vehicleModelOtherName" | "agentCode" | "licensePlate" | "listingOption" | "permittedMakeOffer" | "activeCertificate" | "ownerAsCertificate" | "completeCertificate" | "vehiclePrice" | "mileage" | "idCardNumber" | "idCardName" | "birthPlace" | "birthDate" | "fullAddress" | "postalCode" | "vehicleYear" | "hamlet" | "neighbourhood" | "familyCardNumber" | "caption" | "description" | "phoneNumber">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "vehicleModelOtherName":
                currentState.vehicleModelOtherName = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
            case "licensePlate":
                currentState.licensePlate = value?.toUpperCase().replace(/^([A-Z]+)(\d+)([A-Z]+)/, '$1 $2 $3');
                break;
            case "listingOption":
                currentState.listingOption = value;
                break;
            case "permittedMakeOffer":
                currentState.permittedMakeOffer = value;
                break;
            case "activeCertificate":
                currentState.activeCertificate = value;
                break;
            case "ownerAsCertificate":
                currentState.ownerAsCertificate = value;
                break;
            case "completeCertificate":
                currentState.completeCertificate = value;
                break;
            case "vehiclePrice":
                currentState.vehiclePrice = value;
                break;
            case "mileage":
                currentState.mileage = value;
                break;
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "caption":
                currentState.caption = value;
                break;
            case "description":
                currentState.description = value;
                break;
            case "idCardName":
                currentState.idCardName = value;
                break;
            case "birthPlace":
                currentState.birthPlace = value;
                break;
            case "birthDate":
                if (value) {
                    currentState.birthDate = value;
                }
                break;
            case "fullAddress":
                currentState.fullAddress = value;
                break;
            case "postalCode":
                currentState.postalCode = value.replace(/[^0-9.]/g, '');
                break;
            case "vehicleYear":
                currentState.vehicleYear = value.replace(/[^0-9.]/g, '');
                break;
            case "hamlet":
                currentState.hamlet = value.replace(/[^0-9.]/g, '');
                break;
            case "neighbourhood":
                currentState.neighbourhood = value.replace(/[^0-9.]/g, '');
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            provinceCode: data.value,
            provinceName: data.children,
            cityCode: "",
            cityName: "",
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            cityCode: data.value,
            cityName: data.children,
            districtCode: "",
            districtName: "",
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            districtCode: data.value,
            districtName: data.children,
            subdistrictCode: "",
            subdistrictName: "",
            postalCode: ""
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            subdistrictCode: data.value,
            subdistrictName: data.children,
            postalCode: data.postalCode
        });
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            vehicleModelUuid: data.uuid,
            vehicleModelName: data.value,
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantChange = (data: any) => {
        this.setState({
            vehicleVariantUuid: data.uuid,
            vehicleVariantCode: data.value,
            vehicleVariantName: data.variant_name,
            vehicleColorCode: null,
            vehicleColorName: null,
        });
    }

    onVehicleVariantColorChange = (data: any) => {
        console.log(data);
        this.setState({
            vehicleColorCode: data.key,
            vehicleColorName: data.children,
        });
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.setState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            });
        }
    }

    onImageUpdate = (data: any) => {
        this.setState({
            listImages: data,
        })
    }

    sendSaleRequest = async () => {
        let warnNotice: string = "";
        warnNotice += (!this.state.licensePlate) ? "Plat Nomor Kendaaan, " : "";
        if (this.state.vehicleModelName === "lain-lain") {
            warnNotice += (!this.state.vehicleModelOtherName) ? "Nama Model Kendaraan, " : "";
        } else {
            warnNotice += (!this.state.vehicleModelName) ? "Model Kendaraan, " : "";
        }
        warnNotice += (!this.state.vehicleYear) ? "Tahun Kendaraan, " : "";
        warnNotice += (!this.state.mileage) ? "Jarak tempuh, " : "";
        warnNotice += (!this.state.vehiclePrice) ? "Harga yang ditawarkan, " : "";
        warnNotice += (!this.state.caption) ? "Judul Kendaraan yang Dijual , " : "";
        warnNotice += (!this.state.description) ? "Deskripsi Kendaraan, " : "";
        warnNotice += (!this.state.listingOption) ? "Opsi Listing Kendaraan, " : "";
        warnNotice += (!this.state.idCardName) ? "Nama Pemilik, " : "";
        warnNotice += (!this.state.phoneNumber) ? "Nomor whatsapp, " : "";
        warnNotice += (!this.state.provinceName) ? "Provinsi Lokasi Kendaraan, " : "";
        warnNotice += (!this.state.cityName) ? "Kota Lokasi Kendaraan, " : "";
        warnNotice += (!this.state.districtName) ? "Kalurahan Lokasi Kendaraan, " : "";
        warnNotice += (this.state.listImages.length < 1) ? "Mohon Minimal Upload 1 gambar kendaraan anda, " : "";

        if (warnNotice !== "") {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }

        await this.promisedSetState({
            loading: true,
        });

        const dataRequest = {
            area: this.state.areaCode,
            company: this.state.company,
            caption: this.state.caption,
            description: this.state.description,
            price: this.state.vehiclePrice,
            name: this.state.idCardName,
            phone_number: this.state.phoneNumber,
            listing_option: this.state.listingOption,
            permitted_make_offer: this.state.permittedMakeOffer,
            active_certificate: this.state.activeCertificate,
            owner_as_certificate: this.state.ownerAsCertificate,
            agent_code: this.state.agentCode,
            vehicle_condition: {
                mileage: this.state.mileage
            },
            vehicle_certificate: {
                year: Number(this.state.vehicleYear),
                license_plate: this.state.licensePlate,
                ownership_certificate: this.state.completeCertificate,
                registration_certificate: this.state.completeCertificate
            },
            vehicle_data: {
                brand_uuid: this.state.vehicleBrandUuid ?? "",
                brand_name: this.state.vehicleBrandName ?? "",
                model_uuid: this.state.vehicleModelUuid ?? "",
                model_name: this.state.vehicleModelName ?? "",
                model_other_name: this.state.vehicleModelOtherName ?? "",
                variant_uuid: this.state.vehicleVariantUuid ?? "",
                variant_code: this.state.vehicleVariantCode ?? "",
                variant_name: this.state.vehicleVariantName ?? "",
                color_code: this.state.vehicleColorCode ?? "",
                color_name: this.state.vehicleColorName ?? "",
                image_url: ""
            },
            id_card: {
                birth_date: this.state.birthDate,
                birth_place: this.state.birthPlace,
                full_address: this.state.fullAddress,
                full_name: this.state.idCardName,
                id_card_image: this.state.idCardImageUrl,
                id_card_number: this.state.idCardNumber,
                marital_status: "",
                occupation_code: "",
                occupation: "",
                sex: ""
            },
            address: {
                full_address: this.state.fullAddress,
                province_name: this.state.provinceName,
                province_code: this.state.provinceCode,
                city_name: this.state.cityName,
                city_code: this.state.cityCode,
                district_name: this.state.districtName,
                district_code: this.state.districtCode,
                sub_district_name: this.state.subdistrictName,
                sub_district_code: this.state.subdistrictCode,
                zip_code: this.state.postalCode
            },
            vehicle_address: {
                full_address: this.state.fullAddress,
                province_name: this.state.provinceName,
                province_code: this.state.provinceCode,
                city_name: this.state.cityName,
                city_code: this.state.cityCode,
                district_name: this.state.districtName,
                district_code: this.state.districtCode,
                sub_district_name: this.state.subdistrictName,
                sub_district_code: this.state.subdistrictCode,
                zip_code: this.state.postalCode
            },
            vehicle_images: this.state.listImages
        };

        try {
            await trimitraCatalogServices.createUsedVehicle(
                dataRequest
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'data telah kami terima dan akan kami proses. terimakasih telah melakukan input form penjualan motor.'
                });
                setTimeout(function () {
                    window.location.reload();
                }, 3000);
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                loading: false,
            });
        }
    }


    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const contentInfo1 = (
            <div style={{fontSize: "90%", color: "gray"}}>
                <b>Tertutup:</b> <span>amartahonda tidak akan menampilkan nomor telpon anda, kami akan memberikan informasi kepada anda jika ada calon pembeli yang tertarik.</span><br/>
                <b>Terbuka:</b> <span>amartahonda akan menampilkan nomor telpon anda.</span>
            </div>
        );

        const years = [];
        for (let i = this.state.vehicleYear; i >= this.state.vehicleYear - 8; i--) {
            years.push(i.toString());
        }

        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>
                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>AmartaHonda</Breadcrumb.Item>
                            <Breadcrumb.Item>Jual Motor</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>AmartaHonda - Jual Motor</div>
                                <br/>
                                <div className={`detail-product-caption`}>Jual motor anda dengan mudah di amartahonda.com Jangkau ribuan calon pembeli dengan mudah. <br/><br/> Ingin menjual motor Anda? lengkapi data pemilik dan data kendaraan.</div>
                                <br/>
                            </Col>
                            <Col className={`p-10`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/money-transfer.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>

                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            items={[
                                {
                                    label: `(1 of 2) Data Kendaraan`,
                                    key: '1',
                                    children:
                                        <React.Fragment>
                                            <Row>
                                                <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                                    &nbsp; <i> form dengan tanda bintang ( <b style={{color: "red"}}>*</b> ) wajib diisi</i>
                                                </div>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Plat Nomor <b style={{color: "red"}}>*</b> (kami tidak akan menampilkan data ini pada amartahonda.com)</i>
                                                        <Input
                                                            value={this.state.licensePlate}
                                                            placeholder="D 1234 DDD"
                                                            onChange={event => this.onFieldChange("licensePlate", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Model Kendaraan <b style={{color: "red"}}>*</b></span>
                                                        <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} value={this.state.vehicleModelName} brandCode={this.state.vehicleBrandName} category={"motorcycle"} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item style={this.state.vehicleModelName === "lain-lain" ? {} : {display: 'none'}}>
                                                        <i className={`text-small-grey-nopadding`}>Nama Model Kendaraan <b style={{color: "red"}}>*</b></i>
                                                        <Input
                                                            value={this.state.vehicleModelOtherName}
                                                            placeholder="Nama Model Kendaraan"
                                                            onChange={event => this.onFieldChange("vehicleModelOtherName", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Variant Kendaraan</span>
                                                        <VehicleVariantSelect showUnActive={true} onVehicleVariantChangeProps={this.onVehicleVariantChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} value={this.state.vehicleVariantCode} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={`text-small-grey`}>Warna Kendaraan</span>
                                                        <VehicleVariantColorSelect onVehicleVariantColorChangeProps={this.onVehicleVariantColorChange} brandCode={this.state.vehicleBrandName} modelCode={this.state.vehicleModelName} variantCode={this.state.vehicleVariantCode} value={this.state.vehicleColorCode} time={Math.floor(+new Date() / 1000)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Tahun Kendaraan <b style={{color: "red"}}>*</b></i>
                                                        <Select
                                                            onChange={value => this.onFieldChange("vehicleYear", value)}
                                                            value={this.state.vehicleYear}
                                                            style={{width: '100%'}}
                                                            placeholder={`Tahun Kendaraan`}
                                                        >
                                                            {years.map((item: any, i: number) => {
                                                                return (
                                                                    <Select.Option key={i} value={item}>{item}</Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Jarak Tempuh <b style={{color: "red"}}>*</b></i>
                                                        <Select
                                                            onChange={value => this.onFieldChange("mileage", value)}
                                                            value={this.state.mileage}
                                                            style={{width: '100%'}}
                                                            placeholder={`Jarak Tempuh / Odometer Kendaraan`}
                                                        >
                                                            <Select.Option value={15000}>0 - 15.000 KM</Select.Option>
                                                            <Select.Option value={50000}>15.001 - 50.000 KM</Select.Option>
                                                            <Select.Option value={100000}>50.001 - 100.000 KM</Select.Option>
                                                            <Select.Option value={200000}>lebih dari 100.000 KM</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item style={{marginTop: 10}}>
                                                        <i className={`text-small-grey-nopadding`}>Harga yang ditawarkan <b style={{color: "red"}}>*</b></i>
                                                        <CurrencyInput
                                                            className={'ant-input'}
                                                            groupSeparator={'.'}
                                                            decimalSeparator={','}
                                                            prefix={'Rp. '}
                                                            style={{marginTop: 5}}
                                                            value={this.state.vehiclePrice}
                                                            onValueChange={value => this.onFieldChange("vehiclePrice", value ? parseFloat(value) : 0)}
                                                        />
                                                    </Form.Item>
                                                    <Checkbox onChange={e => this.onFieldChange("completeCertificate", e.target.checked)} checked={this.state.completeCertificate}>
                                                        <i className={`text-small-grey-nopadding`}>BPKB & STNK kendaraan lengkap.</i>
                                                    </Checkbox><br/>
                                                    <Checkbox style={{marginTop: 15}} onChange={e => this.onFieldChange("activeCertificate", e.target.checked)} checked={this.state.activeCertificate}>
                                                        <i className={`text-small-grey-nopadding`}>BPKB dan STNK masih aktif.</i>
                                                    </Checkbox><br/>
                                                    <Checkbox style={{marginTop: 15}} onChange={e => this.onFieldChange("ownerAsCertificate", e.target.checked)} checked={this.state.ownerAsCertificate}>
                                                        <i className={`text-small-grey-nopadding`}>Nama Pemilik Sesuai STNK.</i>
                                                    </Checkbox><br/>
                                                    <Checkbox style={{marginTop: 15}} onChange={e => this.onFieldChange("permittedMakeOffer", e.target.checked)} checked={this.state.permittedMakeOffer}>
                                                        <i className={`text-small-grey-nopadding`}>Izinkan calon pembeli memberikan penawaran harga.</i>
                                                    </Checkbox>
                                                </Col>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Caption / Judul Kendaraan yang Dijual <b style={{color: "red"}}>*</b></i>
                                                        <Input
                                                            value={this.state.caption}
                                                            placeholder="Judul Dari Kendaraan yang akan dijual"
                                                            onChange={event => this.onFieldChange("caption", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Deskripsi Kendaraan <b style={{color: "red"}}>*</b></i>
                                                        <TextArea
                                                            showCount maxLength={500}
                                                            rows={3}
                                                            value={this.state.description}
                                                            onChange={event => this.onFieldChange("description", event.target.value)}
                                                            placeholder="Deskripsi Dari Kendaraan yang akan dijual, anda dapat menjelaskan kondisi, kelebihan dan kekurangan kendaraan"
                                                        />
                                                    </Form.Item>
                                                    <Form.Item style={{marginTop: 17}}>
                                                        <i className={`text-small-grey-nopadding`}>Opsi Listing <b style={{color: "red"}}>*</b></i>
                                                        <Popover content={contentInfo1} trigger="hover">
                                                            <InfoCircleOutlined style={{fontSize: "100%", paddingLeft: 5}}/>
                                                        </Popover>
                                                        <Select
                                                            onChange={value => this.onFieldChange("listingOption", value)}
                                                            value={this.state.listingOption}
                                                            style={{width: '100%'}}
                                                            placeholder={`Pilih Opsi Listing`}
                                                        >
                                                            <Select.Option value={'PRIVATE'}>Tertutup</Select.Option>
                                                            <Select.Option value={'GLOBAL'}>Terbuka</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item style={{marginTop: 17}}>
                                                        <i className={`text-small-grey-nopadding`}>Upload Gambar <b style={{color: "red"}}>*</b> (minimal 1 gambar) <br/> * <b>Gambar 1</b>, akan dijadikan thumbnail pada listing jual-motor <br/> * Kami menyarankan gambar dengan posisi horizontal</i>
                                                        <UploadMultiImage onImageUpdateProps={this.onImageUpdate}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </React.Fragment>,
                                },
                                {
                                    label: `(2 of 2) Data Pemilik`,
                                    key: '2',
                                    children:
                                        <React.Fragment>
                                            <Row>
                                                <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                                                    &nbsp; <i> form dengan tanda bintang ( <b style={{color: "red"}}>*</b> ) wajib diisi</i>
                                                </div>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Nama Sesuai KTP <b style={{color: "red"}}>*</b></i>
                                                        <Input
                                                            value={this.state.idCardName}
                                                            placeholder="Nama Sesuai KTP"
                                                            onChange={event => this.onFieldChange("idCardName", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Nomor Whatsapp <b style={{color: "red"}}>*</b></i>
                                                        <Input
                                                            value={this.state.phoneNumber}
                                                            maxLength={17}
                                                            placeholder="Format Telpon: 08xxxxxxxx"
                                                            onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Nomor KTP</i>
                                                        <Input
                                                            maxLength={16}
                                                            value={this.state.idCardNumber}
                                                            placeholder="Nomor Sesuai KTP"
                                                            onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Tempat Lahir</i>
                                                        <Input
                                                            value={this.state.birthPlace}
                                                            placeholder="Tempat Lahir Sesuai KTP"
                                                            onChange={event => this.onFieldChange("birthPlace", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item className={'padTop-7'}>
                                                        <i className={`text-small-grey-nopadding`}>Tanggal Lahir</i>
                                                        <DatePicker value={moment(this.state.birthDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("birthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                                    </Form.Item>
                                                    <div className={`card-box-shadow`}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <IdcardOutlined className={`icon-title-info`}/>
                                                                <b className={`text-title-info`}>KTP</b>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{paddingTop: 10}}>
                                                            <Col span={23} offset={1}>
                                                                <Row>
                                                                    <Col span={12}>
                                                                        <div className="font-old-grey-100">
                                                                            <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                                            <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardName} </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        {this.state.idCardImageUrl
                                                                            ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.idCardImageUrl}/></div>
                                                                            : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                                <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                                    {this.state.idCardImageUrl
                                                                        ? <span>Update Gambar KTP</span>
                                                                        : <span>Lengkapi Gambar KTP</span>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Provinsi <b style={{color: "red"}}>*</b></i>
                                                        <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.state.provinceCode} provinceName={this.state.provinceName}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kota / Kabupaten <b style={{color: "red"}}>*</b></i>
                                                        <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.provinceCode} cityName={this.state.cityName}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kecamatan <b style={{color: "red"}}>*</b></i>
                                                        <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.cityCode} districtName={this.state.districtName}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kelurahan</i>
                                                        <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.districtCode} subdistrictName={this.state.subdistrictName}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Alamat</i>
                                                        <TextArea showCount maxLength={200} value={this.state.fullAddress} onChange={event => this.onFieldChange("fullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>Kode Pos</i>
                                                        <Input
                                                            value={this.state.postalCode}
                                                            placeholder="Kodepos"
                                                            onChange={event => this.onFieldChange("postalCode", event.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Row>
                                                        <Col span={12} style={{paddingRight: '2px'}}>
                                                            <Form.Item>
                                                                <i className={`text-small-grey-nopadding`}>RT</i>
                                                                <Input maxLength={3} value={this.state.hamlet} onChange={event => this.onFieldChange("hamlet", event.target.value)} placeholder="RT"/>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12} style={{paddingLeft: '2px'}}>
                                                            <Form.Item>
                                                                <i className={`text-small-grey-nopadding`}>RW</i>
                                                                <Input maxLength={3} value={this.state.neighbourhood} onChange={event => this.onFieldChange("neighbourhood", event.target.value)} placeholder="RW"/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </React.Fragment>,
                                },
                            ]}
                        />

                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 10}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Agent / Affiliate Code</i>
                                    <Input
                                        value={this.state.agentCode}
                                        placeholder="abaikan jika tidak memiliki"
                                        onChange={event => this.onFieldChange("agentCode", event.target.value)}
                                    />
                                </Form.Item>

                                <Button onClick={this.sendSaleRequest} loading={this.state.loading} style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f", marginTop: 10, marginBottom: 20}}> Ajukan Form Penjualan </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <FooterSection areaCode={this.state.areaCode}/>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.idCardNumber,
                                source: "amartahonda.com",
                                notes: "cash forms"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(MokasSale);
