import React, {Component} from "react";
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import {NewVehicleVariantDetailFormCreditStates} from "./types/NewVehicleVariantDetailFormCreditTypes";
import {withRouter} from "../../hoc/withRouter";
import {b2bService} from "../../services/b2b/b2bService";
import {connect} from "react-redux";
import moment from "moment";
import CreditSubmissionLayout from "../../components/layouts/CreditSubmissionLayout";
import CreditSubmissionOwner from "../../components/submission/CreditSubmissionOwner";
import CreditSubmissionOrderMaker from "../../components/submission/CreditSubmissionOrderMaker";
import CreditSubmissionPhoneAndDocuments from "../../components/submission/CreditSubmissionPhoneAndDocuments";
import CreditSubmissionPage5 from "../../components/submission/CreditSubmissionResume";
import CreditSubmissionPage6 from "../../components/submission/CreditSubmissionSurvey";
import {ocrApiService} from "../../services/trimitraCdn/OcrApiService";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";
import Result from "antd/lib/result";
import Spin from "antd/lib/spin";
import Tooltip from "antd/lib/tooltip";
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import {trackLogServices} from "../../services/track/TrackLogService";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import SnippetsOutlined from "@ant-design/icons/SnippetsOutlined";
import reformatPhoneNumber from "../../helper/reformatPhoneNumber";
import {crmService} from "../../services/b2b/crmService";
import {Link} from "react-router-dom";

const debug = false;

class NewVehicleVariantDetailFormCredit extends Component<any, NewVehicleVariantDetailFormCreditStates> {
    private readonly initState!: NewVehicleVariantDetailFormCreditStates;
    private readonly offercode: any;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        this.offercode = match.params.offercode;

        document.title = `Amartahonda | AjuKredit | ${this.offercode}`;

        this.initState = {
            offerCode: this.offercode,
            pageShow: 2,
            area: "",
            offerBroker: "",
            agentCode: "",
            agentName: "",
            amartaVipUid: "",
            amartaVipType: "",
            contact: {},
            orderMaker: {},
            submitting: false,
            completeFetchData: false,
            finalLoadingModal: false,
            showModalNeedLogin: false,
            processFetchBiodata: false,
            isWhatsappAvailable: false,
            token: this.cookies.get('_amh-t'),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        const loginData = await authApiService.isExistAmartaVipInternalAffiliateData();
        const amartaVipUid = (loginData.userUid) ? Buffer.from(loginData.userUid, 'base64').toString() : null;
        const amartaVipName = (loginData.userName) ? Buffer.from(loginData.userName, 'base64').toString() : null;
        const amartaVipType = (loginData.userType) ? Buffer.from(loginData.userType, 'base64').toString() : null;
        const amartaVipCode = (loginData.userAgentCode) ? Buffer.from(loginData.userAgentCode, 'base64').toString() : null;

        if (amartaVipUid) {
            await this.promisedSetState({
                amartaVipUid: amartaVipUid,
                amartaVipType: amartaVipType,
                agentCode: amartaVipCode,
                agentName: amartaVipName,
            });
        }

        try {
            await b2bService.getDetailOffer({offerCode: this.offercode})
                .then(async (resp) => {
                    if (Object.keys(resp.data.data).length === 0) {
                        Modal.error({
                            title: 'Error',
                            content: 'Data Tidak Ditemukan'
                        });
                        return;
                    } else {
                        await this.reduxFullFill(resp.data.data);
                        await this.promisedSetState({
                            area: resp.data.data.area,
                            pageShow: resp.data.data.external_id,
                            agentCode: resp.data.data.agent_code,
                            agentName: resp.data.data.agent_name,
                            offerBroker: resp.data.data.offer_broker,
                            contact: resp.data.data.contact,
                            orderMaker: resp.data.data.id_card_order_maker,
                            completeFetchData: true,
                        });
                    }
                });
        } catch (error: any) {
            if (error.response?.status === 422) {
                let errorMsg = "";
                for (const [, value] of Object.entries(error.response.data.data)) {
                    errorMsg += (value as any).msg + ", ";
                }
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error :' + errorMsg
                });
            } else {
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Error Server B2B'
                });
            }
            await this.promisedSetState({
                submitting: false,
            });
        }

        trackLogServices.sendTrackLog({
            eventName: `vehicle credit form page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    reduxFullFill = (data: any) => {
        this.props.changeParamData({
            credit: data?.credit,
            promo_code: data?.promo_code,
        });

        const dpChosen: any = Math.round((data?.vehicle?.price * 10 / 100) / 100000) * 100000;
        this.props.changeVehicleData(data?.vehicle);
        this.props.changeDownPayment(dpChosen);
        this.props.changeOwnerIdCardName(data?.id_card_owner?.full_name);
        this.props.changeOwnerIdCardNumber(data?.id_card_owner?.id_card_number);
        this.props.changeOwnerIdCardImage(data?.id_card_owner?.id_card_image);

        this.props.changeSpouseIdCardNumber(data?.id_card_guarantor_spouse?.id_card_number);
        this.props.changeSpouseIdCardName(data?.id_card_guarantor_spouse?.full_name);
        this.props.changeSpouseIdCardImage(data?.id_card_guarantor_spouse?.id_card_image);

        this.props.changeBirthMother(data?.id_card_guarantor?.birth_mother);
        this.props.changeOwnerMaritalStatus(data?.id_card_guarantor?.marital_status_code);
        this.props.changeOwnerMaritalStatusName(data?.id_card_guarantor?.marital_status);
        this.props.changeOwnerBirthPlace(data?.id_card_owner?.birth_place);
        this.props.changeOwnerFullAddress(data?.address_owner?.full_address);
        this.props.changeOwnerProvinceCode(data?.address_owner?.province_code);
        this.props.changeOwnerProvinceName(data?.address_owner?.province_name);
        this.props.changeOwnerCityCode(data?.address_owner?.city_code);
        this.props.changeOwnerCityName(data?.address_owner?.city_name);
        this.props.changeOwnerDistrictCode(data?.address_owner?.district_code);
        this.props.changeOwnerDistrictName(data?.address_owner?.district_name);
        this.props.changeOwnerSubdistrictCode(data?.address_owner?.sub_district_code);
        this.props.changeOwnerSubdistrictName(data?.address_owner?.sub_district_name);
        this.props.changeOwnerPostalCode(data?.address_owner?.zip_code);
        this.props.changeOwnerHamlet((data?.address_owner?.hamlet !== "undefined") ? data?.address_owner?.hamlet : "");
        this.props.changeOwnerNeighbourhood((data?.address_owner?.neighbourhood !== "undefined") ? data?.address_owner?.neighbourhood : "");
        this.props.changeOwnerOccupationCode(data?.id_card_guarantor?.occupation_code);
        this.props.changeOwnerOccupationName(data?.id_card_guarantor?.occupation);
        this.props.changeOwnerLastEducationCode(data?.id_card_guarantor?.last_education_code);
        this.props.changeOwnerLastEducationName(data?.id_card_guarantor?.last_education_name);
        this.props.changeOwnerPlaceToStayCode(data?.home_ownership_status_code);
        this.props.changeOwnerPlaceToStayName(data?.home_ownership_status);
        this.props.changeOwnerDifferentAddress((!!data?.address_shipping?.full_address));
        this.props.changeShippingDifferentAddress(data?.address_shipping?.full_address);
        this.props.changeShippingProvinceCode(data?.address_shipping?.province_code);
        this.props.changeShippingProvinceName(data?.address_shipping?.province_name);
        this.props.changeShippingCityCode(data?.address_shipping?.city_code);
        this.props.changeShippingCityName(data?.address_shipping?.city_name);
        this.props.changeShippingDistrictCode(data?.address_shipping?.district_code);
        this.props.changeShippingDistrictName(data?.address_shipping?.district_name);
        this.props.changeShippingSubdistrictCode(data?.address_shipping?.sub_district_code);
        this.props.changeShippingSubdistrictName(data?.address_shipping?.sub_district_name);
        this.props.changeShippingPostalCode(data?.address_shipping?.zip_code);
        this.props.changeShippingHamlet((data?.address_shipping?.hamlet !== "undefined") ? data?.address_shipping?.hamlet : "");
        this.props.changeShippingNeighbourhood((data?.address_shipping?.neighbourhood !== "undefined") ? data?.address_shipping?.neighbourhood : "");
        if (data?.id_card_owner?.birth_date) {
            this.props.changeOwnerBirthDate(moment(new Date(data?.id_card_owner?.birth_date), 'YYYY-MM-DD') ?? moment(new Date(), 'YYYY-MM-DD'));
        }

        this.props.changeGuarantorIdCardNumber(data?.id_card_guarantor?.id_card_number);
        this.props.changeGuarantorIdCardName(data?.id_card_guarantor?.full_name);
        this.props.changeGuarantorIdCardImage(data?.id_card_guarantor?.id_card_image);
        this.props.changeGuarantorBirthPlace(data?.id_card_guarantor?.birth_place);

        this.props.changeGuarantorFullAddress(data?.address_guarantor?.full_address);
        this.props.changeGuarantorProvinceCode(data?.address_guarantor?.province_code);
        this.props.changeGuarantorProvinceName(data?.address_guarantor?.province_name);
        this.props.changeGuarantorCityCode(data?.address_guarantor?.city_code);
        this.props.changeGuarantorCityName(data?.address_guarantor?.city_name);
        this.props.changeGuarantorDistrictCode(data?.address_guarantor?.district_code);
        this.props.changeGuarantorDistrictName(data?.address_guarantor?.district_name);
        this.props.changeGuarantorSubdistrictCode(data?.address_guarantor?.sub_district_code);
        this.props.changeGuarantorSubdistrictName(data?.address_guarantor?.sub_district_name);
        this.props.changeGuarantorPostalCode(data?.address_guarantor?.zip_code);
        this.props.changeGuarantorHamlet((data?.address_guarantor?.hamlet !== "undefined") ? data?.address_guarantor?.hamlet : "");
        this.props.changeGuarantorNeighbourhood((data?.address_guarantor?.neighbourhood !== "undefined") ? data?.address_guarantor?.neighbourhood : "");
        this.props.changeGuarantorMarriageAge(data?.guarantor_marriage_age);
        this.props.changeGuarantorOwnerRelation(data?.guarantor_relationship);
        if (data?.id_card_guarantor?.birth_date) {
            this.props.changeGuarantorBirthDate(moment(new Date(data?.id_card_guarantor?.birth_date), 'YYYY-MM-DD') ?? moment(new Date(), 'YYYY-MM-DD'));
        }

        this.props.changeGuarantorAndOwnerDifferent(data?.id_card_owner_and_guarantor_different ?? false);

        this.props.changeGuarantorCurrentAddressDifferent(data?.address_guarantor_current_address?.different);
        this.props.changeGuarantorCurrentAddressFullAddress(data?.address_guarantor_current_address?.full_address);
        this.props.changeGuarantorCurrentAddressProvinceCode(data?.address_guarantor_current_address?.province_code);
        this.props.changeGuarantorCurrentAddressProvinceName(data?.address_guarantor_current_address?.province_name);
        this.props.changeGuarantorCurrentAddressCityCode(data?.address_guarantor_current_address?.city_code);
        this.props.changeGuarantorCurrentAddressCityName(data?.address_guarantor_current_address?.city_name);
        this.props.changeGuarantorCurrentAddressDistrictCode(data?.address_guarantor_current_address?.district_code);
        this.props.changeGuarantorCurrentAddressDistrictName(data?.address_guarantor_current_address?.district_name);
        this.props.changeGuarantorCurrentAddressSubdistrictCode(data?.address_guarantor_current_address?.sub_district_code);
        this.props.changeGuarantorCurrentAddressSubdistrictName(data?.address_guarantor_current_address?.sub_district_name);
        this.props.changeGuarantorCurrentAddressPostalCode(data?.address_guarantor_current_address?.zip_code);
        this.props.changeGuarantorCurrentAddressHamlet((data?.address_guarantor_current_address?.hamlet !== "undefined") ? data?.address_guarantor_current_address?.hamlet : "");
        this.props.changeGuarantorCurrentAddressNeighbourhood((data?.address_guarantor_current_address?.neighbourhood !== "undefined") ? data?.address_guarantor_current_address?.neighbourhood : "");

        this.props.changeFamilyCardNumber(data?.family_register_owner?.family_register_number);
        this.props.changeFamilyCardImage(data?.family_register_owner?.family_register_image);
        this.props.changeIncomeDocumentImage(data?.documents?.income_documents[0]?.document_image ?? "");
        this.props.changePlaceToStayDocumentImage(data?.documents?.place_to_stay_documents[0]?.document_image ?? "");
        this.props.changeMaritalDocumentImage(data?.documents?.marital_documents[0]?.document_image ?? "");

        this.props.changeContactOwnerPhoneNumber(data?.contact?.phone_number_owner);
        this.props.changeContactGuarantorPhoneNumber(data?.contact?.phone_number_guarantor);
        this.props.changeContactOwnerAlternativePhoneNumber(data?.contact?.phone_number_alternative_owner);
        this.props.changeOfferNote(data?.notes);
    }

    onBackLayout = (data: any) => {
    }

    onClickNext = async (page: number, buttonType: string) => {
        if (!debug) {
            let warnNotice = "";
            if (page === 4) {
                if (this.props?.guarantorIdCardNumber === this.props?.ownerIdCardNumber) {
                    const diffYearsOwner = moment().diff(this.props.ownerBirthDate, 'years');
                    if (diffYearsOwner <= 17) {
                        warnNotice += "Umur Pemilik minimal 17 tahun. Periksa kembali tanggal lahir pemilik, ";
                    }
                }

                warnNotice += (this.props?.ownerIdCardNumber?.length !== 16) ? "Nomor KTP Pemilik tidak valid, " : "";
                warnNotice += (!this.props?.ownerIdCardName) ? "Nama KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerBirthPlace) ? "Tempat Lahir KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerBirthDate) ? "Tanggal Lahir KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerFullAddress) ? "Alamat KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerProvinceCode) ? "Provinsi KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerCityCode) ? "Kota KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerDistrictCode) ? "Kecamatan KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerSubdistrictCode) ? "Kelurahan KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerHamlet) ? "RW KTP Pemilik, " : "";
                warnNotice += (!this.props?.ownerNeighbourhood) ? "RT KTP Pemilik, " : "";
                warnNotice += (this.props?.ownerPostalCode?.trim().length !== 5) ? "Kode Pos KTP Pemilik tidak valid, " : "";

                if (this.props?.ownerDifferentAddress) {
                    warnNotice += (!this.props?.shippingFullAddress) ? "Alamat Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingProvinceCode) ? "Provinsi Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingCityCode) ? "Kota Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingDistrictCode) ? "Kecamatan Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingSubdistrictCode) ? "Kelurahan Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingHamlet) ? "RW Pengiriman, " : "";
                    warnNotice += (!this.props?.shippingNeighbourhood) ? "RT Pengiriman, " : "";
                    warnNotice += (this.props?.shippingPostalCode?.trim().length !== 5) ? "Kode Pos Pengiriman tidak valid, " : "";
                }
            }

            if (page === 5) {
                if (this.props?.ownerIdCardNumber === this.props?.guarantorIdCardNumber) {
                    await this.props.changeOwnerIdCardImage(this.props?.guarantorIdCardImage);
                }

                if (this.props.ownerMaritalStatus === 'married') {
                    warnNotice += (this.props?.spouseIdCardNumber?.length !== 16) ? "Nomor KTP Pasangan tidak valid, " : "";
                    warnNotice += (!this.props?.spouseIdCardName) ? "Nama Pasangan, " : "";
                    warnNotice += (!this.props?.spouseIdCardImage) ? "Upload Gambar KK, " : "";
                }

                if (this.props?.ownerIdCardImage && this.state.offerBroker) {
                    this.setState({
                        submitting: true,
                    });
                    try {
                        // const resultCheck = await ocrApiService.getImageIdCardNumber({image_url: this.props?.ownerIdCardImage});
                        // const idCardNumberChecked = resultCheck?.data?.data?.id_card_number;
                        // if (idCardNumberChecked?.trim() !== this.props?.ownerIdCardNumber?.trim()) {
                        //     console.log("error");
                        //     Modal.error({title: 'Proses Gagal', content: "Nomor KTP PEMILIK pada image berbeda dengan nomor KTP yang diinput"});
                        //     this.setState({
                        //         submitting: false,
                        //     });
                        //     return false;
                        // }

                        await ocrApiService.getImageIdCardNumber({image_url: this.props?.ownerIdCardImage});
                        this.setState({
                            submitting: false,
                        });
                    } catch (e) {
                        Modal.error({title: 'Proses Gagal', content: "KTP PEMILIK tidak terdeteksi, mohon upload image ktp secara utuh dan jelas"});
                        this.setState({
                            submitting: false,
                        });
                        return false;
                    }
                }

                if (this.props?.guarantorIdCardImage && this.state.offerBroker) {
                    this.setState({
                        submitting: true,
                    });
                    try {
                        // const resultCheck = await ocrApiService.getImageIdCardNumber({image_url: this.props?.guarantorIdCardImage});
                        // const idCardNumber2Checked = resultCheck?.data?.data?.id_card_number;
                        // if (idCardNumber2Checked?.trim() !== this.props?.guarantorIdCardNumber?.trim()) {
                        //     console.log("error");
                        //     Modal.error({title: 'Proses Gagal', content: "Nomor KTP PEMOHON pada image berbeda dengan nomor KTP yang diinput"});
                        //     this.setState({
                        //         submitting: false,
                        //     });
                        //     return false;
                        // }

                        await ocrApiService.getImageIdCardNumber({image_url: this.props?.guarantorIdCardImage});
                        this.setState({
                            submitting: false,
                        });
                    } catch (e) {
                        Modal.error({title: 'Proses Gagal', content: "KTP PEMOHON tidak terdeteksi, mohon upload image ktp secara utuh dan jelas"});
                        this.setState({
                            submitting: false,
                        });
                        return false;
                    }
                }
                warnNotice += (!this.props?.familyCardImage) ? "Upload Gambar KK, " : "";
                warnNotice += (!this.props?.guarantorIdCardImage) ? "Upload Gambar KTP Pemohon, " : "";
                warnNotice += (!this.props?.ownerIdCardImage) ? "Upload Gambar KTP Pemilik, " : "";
            }

            if (page === 6) {
                warnNotice += (!this.props?.contactGuarantorPhoneNumber) ? "Nomor Telpon Pemohon " : "";
                if (this.props.guarantorAndOwnerDifferent) {
                    warnNotice += (!this.props?.contactOwnerPhoneNumber) ? "Nomor Telpon Pemilik, " : "";
                }
            }

            if (page === 3) {
                const diffYears = moment().diff(this.props.guarantorBirthDate, 'years');
                if (diffYears <= 17) {
                    warnNotice += "Umur pemohon minimal 17 tahun. Periksa kembali tanggal lahir pemohon, ";
                }

                warnNotice += (this.props?.guarantorIdCardNumber?.length !== 16) ? "Nomor KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.birthMother) ? "Nama Ibu Kandung, " : "";
                warnNotice += (!this.props?.guarantorIdCardName) ? "Nama KTP Pemohon tidak valid, " : "";
                warnNotice += (this.props?.familyCardNumber?.length !== 16) ? "KK Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorBirthPlace) ? "Tempat lahir Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorFullAddress) ? "Alamat KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorProvinceCode) ? "Provinsi KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorCityCode) ? "Kota KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorDistrictCode) ? "Kecamatan KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorSubdistrictCode) ? "Kelurahan KTP Pemohon tidak valid, " : "";
                warnNotice += (this.props?.guarantorPostalCode?.trim().length !== 5) ? "Kode Pos KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorHamlet) ? "RW KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.guarantorNeighbourhood) ? "RT KTP Pemohon tidak valid, " : "";
                warnNotice += (!this.props?.ownerMaritalStatus) ? "Status Perkawinan, " : "";
                // warnNotice += (!this.props?.ownerLastEducationCode) ? "Pendidikan Terakhir, " : "";
                warnNotice += (!this.props?.ownerOccupationCode) ? "Pekerjaan, " : "";
                warnNotice += (!this.props?.ownerPlaceToStayCode) ? "Status Tempat Tinggal, " : "";
                if (this.props.guarantorAndOwnerDifferent) {
                    warnNotice += (!this.props?.guarantorOwnerRelation) ? "Hubungan Dengan Pemohon, " : "";
                }
                // if (this.props.ownerMaritalStatus === 'married') {
                //     // warnNotice += (!this.props?.guarantorMarriageAge) ? "Usia Pernikahan Pemohon, " : "";
                // } else {
                //     warnNotice += (!this.props?.guarantorOwnerRelation) ? "Hubungan Dengan Pemohon, " : "";
                // }
                if (this.props?.guarantorCurrentAddressDifferent) {
                    warnNotice += (!this.props?.guarantorCurrentAddressFullAddress) ? "Alamat Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressProvinceCode) ? "Provinsi Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressCityCode) ? "Kota Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressDistrictCode) ? "Kecamatan Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressSubdistrictCode) ? "Kelurahan Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (this.props?.guarantorCurrentAddressPostalCode?.trim().length !== 5) ? "Kode Pos Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressHamlet) ? "RW Tempat Tinggal Pemohon tidak valid, " : "";
                    warnNotice += (!this.props?.guarantorCurrentAddressNeighbourhood) ? "RT Tempat Tinggal Pemohon tidak valid, " : "";
                }

                if (!this.props.guarantorAndOwnerDifferent) {
                    if (warnNotice !== "") {
                        const objError = warnNotice.split(",");
                        Modal.error({
                            title: 'Proses Gagal',
                            content: (<div>Mohon Lengkapi data-data berikut: <br/><br/>{objError.map(function (itemError, i) {
                                return <div key={i}>{itemError}</div>;
                            })}</div>)
                        });
                        return false;
                    }
                    await this.props.changeGuarantorOwnerRelation("other");
                    this.props.changeOwnerIdCardNumber(this.props.guarantorIdCardNumber);
                    this.props.changeOwnerIdCardName(this.props.guarantorIdCardName);
                    this.props.changeOwnerBirthPlace(this.props.guarantorBirthPlace);
                    this.props.changeOwnerBirthDate(this.props?.guarantorBirthDate);
                    this.props.changeOwnerFullAddress(this.props.guarantorFullAddress);
                    this.props.changeOwnerProvinceCode(this.props.guarantorProvinceCode);
                    this.props.changeOwnerProvinceName(this.props.guarantorProvinceName);
                    this.props.changeOwnerCityCode(this.props?.guarantorCityCode);
                    this.props.changeOwnerCityName(this.props?.guarantorCityName);
                    this.props.changeOwnerDistrictCode(this.props?.guarantorDistrictCode);
                    this.props.changeOwnerDistrictName(this.props?.guarantorDistrictName);
                    this.props.changeOwnerSubdistrictCode(this.props?.guarantorSubdistrictCode);
                    this.props.changeOwnerSubdistrictName(this.props?.guarantorSubdistrictName);
                    this.props.changeOwnerPostalCode(this.props?.guarantorPostalCode);
                    this.props.changeOwnerHamlet((this.props.guarantorHamlet !== "undefined") ? this.props.guarantorHamlet : "");
                    this.props.changeOwnerNeighbourhood((this.props.guarantorNeighbourhood !== "undefined") ? this.props.guarantorNeighbourhood : "");

                    if (buttonType === "next") {
                        page = 4;
                    } else {
                        page = 2;
                    }
                }
            }

            if (warnNotice !== "") {
                const objError = warnNotice.split(",");
                Modal.error({
                    title: 'Proses Gagal',
                    content: (<div>
                        Mohon Lengkapi data-data berikut: <br/><br/>
                        {objError.map(function (itemError, i) {
                            return <div key={i}>{itemError}</div>;
                        })}
                    </div>)
                });
                return false;
            }

            if (page === 6) {
                console.log(reformatPhoneNumber(this.props?.contactOwnerPhoneNumber));

                try {
                    const resultCrm: any = crmService.checkPhoneNumberCrm({
                        name: "Customer",
                        event: "Check Phone Number",
                        source: "amartahonda",
                        phoneNumber: reformatPhoneNumber(this.props?.contactOwnerPhoneNumber),
                    });
                    if (resultCrm?.data?.data?.check?.isRegistered) {
                        this.setState({
                            isWhatsappAvailable: true
                        });
                    } else {
                        this.setState({
                            isWhatsappAvailable: false
                        });
                    }
                } catch (e) {
                    this.setState({
                        isWhatsappAvailable: false
                    });
                }
            }
        }

        if (this.state.offerBroker && !this.state.amartaVipUid) {
            this.setState({
                showModalNeedLogin: true,
            });
            return;
        }

        await this.promisedSetState({
            submitting: true
        });

        const dataInput: any = await this.dataMapping();

        try {
            await b2bService.createB2bTemporaryProspect(
                dataInput
            ).then(async () => {
                await this.promisedSetState({
                    pageShow: page,
                    submitting: false,
                });
            });
        } catch (error: any) {
            if (error.response?.status === 422) {
                let errorMsg = "";
                for (const [, value] of Object.entries(error.response.data.data)) {
                    errorMsg += (value as any).msg + ", ";
                }
                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Server Menolak Inputan Anda :' + errorMsg
                });
            } else {
                Modal.error({
                    title: 'Proses Gagal',
                    content: error?.response?.data?.data?.offer_code?.msg ?? 'Error Server B2B'
                });
            }
            await this.promisedSetState({
                submitting: false,
            });
        }
    }

    onClickCredit = async () => {
        if (!debug) {
            let warnNotice = "";
            if (this.state.amartaVipUid) {
                warnNotice += (!this.props?.installmentAmount) ? "Pengajuan Angsuran, " : "";
            }
            warnNotice += (!this.props?.downPayment) ? "Uang Muka, " : "";
            warnNotice += (!this.props?.tenor) ? "Tenor, " : "";
            warnNotice += (!this.props?.fincoChosenCode) ? "Leasing, " : "";
            warnNotice += (!this.props?.creditStartDate) ? "Tanggal Mulai Kredit, " : "";
            warnNotice += (!this.props?.surveyDate) ? "Tanggal Survey, " : "";
            warnNotice += (!this.props?.surveyTime) ? "Waktu Survey, " : "";

            if (warnNotice !== "") {
                Modal.error({
                    title: 'Proses Gagal',
                    content: (<div>Mohon Lengkapi data-data berikut: <br/> {warnNotice}</div>)
                });
                return false;
            }
        }

        if (this.props?.creditRate !== 0 && this.props?.creditInstallmentAmount === 0) {
            Modal.error({
                title: 'Warning',
                content: (<div>Mohon Klik tombol estimasi cicilan terlebih dahulu, untuk mendapatkan informasi angsuran.</div>)
            });
            return false;
        }

        if (this.state.offerBroker && !this.state.amartaVipUid) {
            this.setState({
                showModalNeedLogin: true,
            });
            return;
        }

        await this.promisedSetState({
            submitting: true,
            finalLoadingModal: true,
        });

        const dataInput: any = await this.dataMapping();
        dataInput.external_id = 7;
        dataInput.succeed = true; // only when form completed
        // dataInput.credit.installment_amount = this.props?.creditInstallmentAmount; (sudah menggunakan inputan)

        try {
            await b2bService.createB2bTemporaryProspect(
                dataInput
            ).then(() => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Kami Telah menerima data anda, silakan tunggu informasi dari kami'
                });
                setTimeout(async () => {
                    await this.promisedSetState({
                        pageShow: 7,
                        finalLoadingModal: false,
                    });
                }, 1000);
            });
        } catch (error: any) {
            if (error.response?.status === 422) {
                let errorMsg = "";
                for (const [, value] of Object.entries(error.response.data.data)) {
                    errorMsg += (value as any).msg + ", ";
                }

                Modal.error({
                    title: 'Proses Gagal',
                    content: 'Server Menolak Inputan Anda :' + errorMsg
                });
            } else {
                Modal.error({
                    title: 'Proses Gagal',
                    content: error?.response?.data?.data?.promo_code?.msg ?? 'Error Server B2B'
                });
            }
            await this.promisedSetState({
                submitting: false,
                finalLoadingModal: false,
            });
        }
    }

    async dataMapping() {

        const promoCodes = [];
        if (this.props?.promoChosenData?.promo_code?.toUpperCase()) {
            promoCodes.push(this.props?.promoChosenData?.promo_code?.toUpperCase());
        }

        return {
            area: this.state.area,
            offer_code: this.state.offerCode,
            external_id: this.state.pageShow,
            company: 'amarta',
            source: 'amartahonda.com',
            purchase_method: 'credit',
            amarta_vip_uid: this.state.amartaVipUid,
            amarta_vip_type: this.state.amartaVipType,
            agent_code: this.state.agentCode?.trim(),
            agent_name: this.state.agentName,
            offer_broker: this.state.offerBroker,
            notes: this.props?.offerNote?.trim(),
            vehicle: this.props?.vehicleData,
            home_ownership_status: this.props?.ownerPlaceToStayName,
            home_ownership_status_code: this.props?.ownerPlaceToStayCode,
            guarantor_marriage_age: this.props?.guarantorMarriageAge,
            guarantor_relationship: this.props?.guarantorOwnerRelation,
            contact: {
                phone_number_owner: (this.props?.contactOwnerPhoneNumber?.trim()) ? this.props?.contactOwnerPhoneNumber?.trim() : this.props?.contactGuarantorPhoneNumber?.trim(),
                phone_number_alternative_owner: this.props?.contactOwnerAlternativePhoneNumber?.trim(),
                phone_number_wa_available_owner: this.state.isWhatsappAvailable,
                phone_number_order_maker: this.props?.contactGuarantorPhoneNumber?.trim(),
                phone_number_wa_available_order_maker: this.state.contact?.phone_number_wa_available_order_maker,
                phone_number_guarantor: this.props?.contactGuarantorPhoneNumber?.trim()
            },

            id_card_owner_and_guarantor_different: this.props.guarantorAndOwnerDifferent,

            id_card_owner: {
                id_card_number: this.props?.ownerIdCardNumber?.trim(),
                id_card_image: this.props?.ownerIdCardImage,
                full_name: this.props?.ownerIdCardName?.trim(),
                full_address: this.props?.ownerFullAddress?.trim(),
                birth_date: moment(this.props?.ownerBirthDate).format("YYYY-MM-DD"),
                birth_place: this.props?.ownerBirthPlace?.trim(),
            },
            id_card_guarantor_spouse: {
                id_card_number: this.props?.spouseIdCardNumber?.trim() || "",
                id_card_image: this.props?.spouseIdCardImage || "",
                full_name: this.props?.spouseIdCardName?.trim() || "",
            },
            address_owner: {
                full_address: this.props?.ownerFullAddress?.trim(),
                province_code: this.props?.ownerProvinceCode,
                province_name: this.props?.ownerProvinceName,
                city_code: this.props?.ownerCityCode,
                city_name: this.props?.ownerCityName,
                district_code: this.props?.ownerDistrictCode,
                district_name: this.props?.ownerDistrictName,
                sub_district_code: this.props?.ownerSubdistrictCode,
                sub_district_name: this.props?.ownerSubdistrictName,
                zip_code: this.props?.ownerPostalCode?.trim(),
                hamlet: this.addLeadingZeros(this.props?.ownerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.props?.ownerNeighbourhood, 3),
            },
            address_shipping: {
                full_address: (this.props?.shippingFullAddress) ? this.props?.shippingFullAddress?.trim() : this.props?.ownerFullAddress?.trim(),
                province_code: (this.props?.shippingFullAddress) ? this.props?.shippingProvinceCode : this.props?.ownerProvinceCode,
                province_name: (this.props?.shippingFullAddress) ? this.props?.shippingProvinceName : this.props?.ownerProvinceName,
                city_code: (this.props?.shippingFullAddress) ? this.props?.shippingCityCode : this.props?.ownerCityCode,
                city_name: (this.props?.shippingFullAddress) ? this.props?.shippingCityName : this.props?.ownerCityName,
                district_code: (this.props?.shippingFullAddress) ? this.props?.shippingDistrictCode : this.props?.ownerDistrictCode,
                district_name: (this.props?.shippingFullAddress) ? this.props?.shippingDistrictName : this.props?.ownerDistrictName,
                sub_district_code: (this.props?.shippingFullAddress) ? this.props?.shippingSubdistrictCode : this.props?.ownerSubdistrictCode,
                sub_district_name: (this.props?.shippingFullAddress) ? this.props?.shippingSubdistrictName : this.props?.ownerSubdistrictName,
                zip_code: (this.props?.shippingFullAddress) ? this.props?.shippingPostalCode?.trim() : this.props?.ownerPostalCode?.trim(),
                hamlet: (this.props?.shippingFullAddress) ? this.addLeadingZeros(this.props?.shippingHamlet, 3) : this.addLeadingZeros(this.props?.ownerHamlet, 3),
                neighbourhood: (this.props?.shippingFullAddress) ? this.addLeadingZeros(this.props?.shippingNeighbourhood, 3) : this.addLeadingZeros(this.props?.ownerNeighbourhood, 3),
            },
            document_creator_name: this.state.orderMaker?.full_name?.trim(),

            // disamakan dengan guarantor
            id_card_order_maker: {
                id_card_number: this.props?.guarantorIdCardNumber?.trim(),
                id_card_image: this.props?.guarantorIdCardImage,
                full_name: this.props?.guarantorIdCardName?.trim(),
                birth_date: moment(this.props?.guarantorBirthDate).format("YYYY-MM-DD"),
                birth_place: this.props?.guarantorBirthPlace?.trim(),
                birth_mother: this.props?.birthMother?.trim(),
                marital_status_code: this.props?.ownerMaritalStatus,
                marital_status: this.props?.ownerMaritalStatusName,
                full_address: this.props?.guarantorFullAddress?.trim(),
                occupation_code: this.props?.ownerOccupationCode,
                occupation: this.props?.ownerOccupationName,
                last_education_code: this.props?.ownerLastEducationCode,
                last_education_name: this.props?.ownerLastEducationName,
            },
            address_order_maker: {
                full_address: this.props?.guarantorFullAddress?.trim(),
                province_code: this.props?.guarantorProvinceCode,
                province_name: this.props?.guarantorProvinceName,
                city_code: this.props?.guarantorCityCode,
                city_name: this.props?.guarantorCityName,
                district_code: this.props?.guarantorDistrictCode,
                district_name: this.props?.guarantorDistrictName,
                sub_district_code: this.props?.guarantorSubdistrictCode,
                sub_district_name: this.props?.guarantorSubdistrictName,
                zip_code: this.props?.guarantorPostalCode?.trim(),
                hamlet: this.addLeadingZeros(this.props?.guarantorHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.props?.guarantorNeighbourhood, 3),
            },

            id_card_guarantor: {
                id_card_number: this.props?.guarantorIdCardNumber?.trim(),
                id_card_image: this.props?.guarantorIdCardImage,
                full_name: this.props?.guarantorIdCardName?.trim(),
                birth_date: moment(this.props?.guarantorBirthDate).format("YYYY-MM-DD"),
                birth_place: this.props?.guarantorBirthPlace?.trim(),
                birth_mother: this.props?.birthMother?.trim(),
                marital_status_code: this.props?.ownerMaritalStatus,
                marital_status: this.props?.ownerMaritalStatusName,
                full_address: this.props?.guarantorFullAddress?.trim(),
                occupation_code: this.props?.ownerOccupationCode,
                occupation: this.props?.ownerOccupationName,
                last_education_code: this.props?.ownerLastEducationCode,
                last_education_name: this.props?.ownerLastEducationName,
            },
            address_guarantor: {
                full_address: this.props?.guarantorFullAddress?.trim(),
                province_code: this.props?.guarantorProvinceCode,
                province_name: this.props?.guarantorProvinceName,
                city_code: this.props?.guarantorCityCode,
                city_name: this.props?.guarantorCityName,
                district_code: this.props?.guarantorDistrictCode,
                district_name: this.props?.guarantorDistrictName,
                sub_district_code: this.props?.guarantorSubdistrictCode,
                sub_district_name: this.props?.guarantorSubdistrictName,
                zip_code: this.props?.guarantorPostalCode?.trim(),
                hamlet: this.addLeadingZeros(this.props?.guarantorHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.props?.guarantorNeighbourhood, 3),
            },
            address_guarantor_current_address: {
                different: this.props?.guarantorCurrentAddressDifferent,
                full_address: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressFullAddress?.trim() : this.props?.guarantorFullAddress?.trim(),
                province_code: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressProvinceCode : this.props?.guarantorProvinceCode,
                province_name: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressProvinceName : this.props?.guarantorProvinceName,
                city_code: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressCityCode : this.props?.guarantorCityCode,
                city_name: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressCityName : this.props?.guarantorCityName,
                district_code: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressDistrictCode : this.props?.guarantorDistrictCode,
                district_name: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressDistrictName : this.props?.guarantorDistrictName,
                sub_district_code: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressSubdistrictCode : this.props?.guarantorSubdistrictCode,
                sub_district_name: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressSubdistrictName : this.props?.guarantorSubdistrictName,
                zip_code: (this.props?.guarantorCurrentAddressFullAddress) ? this.props?.guarantorCurrentAddressPostalCode?.trim() : this.props?.guarantorPostalCode?.trim(),
                hamlet: (this.props?.guarantorCurrentAddressFullAddress) ? this.addLeadingZeros(this.props?.guarantorCurrentAddressHamlet, 3) : this.addLeadingZeros(this.props?.guarantorHamlet, 3),
                neighbourhood: (this.props?.guarantorCurrentAddressFullAddress) ? this.addLeadingZeros(this.props?.guarantorCurrentAddressNeighbourhood, 3) : this.addLeadingZeros(this.props?.guarantorNeighbourhood, 3),
            },

            family_register_owner: {
                family_register_number: this.props?.familyCardNumber?.trim(),
                family_register_image: this.props?.familyCardImage,
            },
            documents: {
                income_documents: [
                    {
                        document_type: "slip gaji",
                        document_image: this.props?.incomeDocumentImage,
                    }
                ],
                place_to_stay_documents: [
                    {
                        document_type: "pbb",
                        document_image: this.props?.placeToStayDocumentImage,
                    }
                ],
                marital_documents: [
                    {
                        document_type: "buku menikah",
                        document_image: this.props?.maritalDocumentImage,
                    }
                ]
            },
            credit: {
                tenor: this.props?.tenor,
                dp_amount: this.props?.downPayment,
                installment_amount: this.props?.installmentAmount,
                finco_id: this.props?.fincoId,
                finco_code: this.props?.fincoChosenCode,
                finco_name: this.props?.fincoChosenName,
                credit_start_date: moment(this.props?.creditStartDate).format("YYYY-MM-DD"),
                survey_date: moment(this.props?.creditStartDatesurveyDate).format("YYYY-MM-DD"),
                survey_time: this.props?.surveyTime,
            },
            promo_code: this.props?.promoChosenData?.promo_code?.toUpperCase() ?? "",
            promo_codes: promoCodes,
            take_vehicle_in_dealer: this.props.takeVehicleInDealer
        }
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor AJU KREDIT berhasil dicopy');
    }

    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0');
    }

    async onClickDuplicateProfile() {
        await this.promisedSetState({
            processFetchBiodata: true,
        });

        const isExistAmartaVipToken = await authApiService.isExistAmartaVipToken();
        if (!isExistAmartaVipToken) {
            await this.promisedSetState({
                showModalNeedLogin: true,
                processFetchBiodata: false,
            });
            return;
        }

        try {
            await authApiService.setToken(isExistAmartaVipToken);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;
            const dataIdCard = dataBio?.id_card;
            const dataAddress = dataBio?.address;
            // const dataCurrentAddress = dataBio?.current_address;
            const dataFamilyCard = (dataBio?.other_documents ?? []).find((o: any) => o.type === 'family_register') ?? {};

            if (dataIdCard?.birth_date) {
                this.props.changeGuarantorBirthDate(dataIdCard?.birth_date);
            }
            if (dataIdCard?.id_card_number) {
                this.props.changeGuarantorIdCardNumber(dataIdCard?.id_card_number);
            }
            if (dataIdCard?.full_name) {
                this.props.changeGuarantorIdCardName(dataIdCard?.full_name);
            }
            if (dataIdCard?.id_card_image) {
                this.props.changeGuarantorIdCardImage(dataIdCard?.id_card_image);
            }
            if (dataIdCard?.birth_place) {
                this.props.changeGuarantorBirthPlace(dataIdCard?.birth_place);
            }
            if (dataFamilyCard?.document_number) {
                this.props.changeFamilyCardNumber(dataFamilyCard?.document_number);
            }
            if (dataFamilyCard?.document_image) {
                this.props.changeFamilyCardImage(dataFamilyCard?.document_image);
            }

            if (dataAddress?.full_address) {
                this.props.changeGuarantorFullAddress(dataAddress?.full_address);
            }
            if (dataAddress?.province_code) {
                this.props.changeGuarantorProvinceCode(dataAddress?.province_code);
            }
            if (dataAddress?.province_name) {
                this.props.changeGuarantorProvinceName(dataAddress?.province_name);
            }
            if (dataAddress?.city_code) {
                this.props.changeGuarantorCityCode(dataAddress?.city_code);
            }
            if (dataAddress?.city_name) {
                this.props.changeGuarantorCityName(dataAddress?.city_name);
            }
            if (dataAddress?.district_code) {
                this.props.changeGuarantorDistrictCode(dataAddress?.district_code);
            }
            if (dataAddress?.district_name) {
                this.props.changeGuarantorDistrictName(dataAddress?.district_name);
            }
            if (dataAddress?.sub_district_code) {
                this.props.changeGuarantorSubdistrictCode(dataAddress?.sub_district_code);
            }
            if (dataAddress?.sub_district_name) {
                this.props.changeGuarantorSubdistrictName(dataAddress?.sub_district_name);
            }
            if (dataAddress?.postal_code) {
                this.props.changeGuarantorPostalCode(dataAddress?.postal_code);
            }
            if (dataAddress?.hamlet) {
                this.props.changeGuarantorHamlet(dataAddress?.hamlet);
            }
            if (dataAddress?.neighbourhood) {
                this.props.changeGuarantorNeighbourhood(dataAddress?.neighbourhood);
            }

            setTimeout(() => {
            }, 100);
            await this.promisedSetState({
                processFetchBiodata: false,
            });
        } catch (e) {
            await authApiService.removeAllToken();
            setTimeout(() => {
            }, 100);
            await authApiService.removeAllToken();

            setTimeout(() => {
                this.setState({
                    showModalNeedLogin: true,
                    processFetchBiodata: false
                });
            }, 500);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                {!this.state.completeFetchData &&
                    <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                        <div className="loading">
                            <Spin size={'large'} tip="Loading..."/>
                        </div>
                    </CreditSubmissionLayout>
                }
                {this.state.completeFetchData &&
                    <React.Fragment>

                        <div style={{display: (this.state.pageShow === 2) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <img style={{marginTop: -80, zIndex: -1}} src={'../assets/svg/top-wave.svg'} alt={`amartahonda-logo`}/>
                                <div style={{marginTop: -40}} className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>Data Pemohon (2/6)</div>
                                    <Row style={{display: (this.state.amartaVipUid) ? '' : 'none'}}>
                                        <Col span={24} style={{textAlign: "center", paddingTop: 10, paddingBottom: 10}}>
                                            <Button loading={this.state.processFetchBiodata} onClick={() => {
                                                this.onClickDuplicateProfile()
                                            }} style={{width: "100%", height: 65, borderRadius: 10}}>
                                                <span style={{fontSize: "105%", color: "#000", fontWeight: 500}}><SnippetsOutlined/> Gunakan Biodata Saya</span><br/>
                                                <span style={{fontSize: "85%", color: "gray"}}>Gunakan Data Biodata saya sebagai data pemohon</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <CreditSubmissionOrderMaker/>
                                    <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f", marginBottom: 10}} block={true} loading={this.state.submitting} onClick={() => this.onClickNext(3, "next")} type="primary"> Lanjut</Button>
                                </div>
                            </CreditSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 3) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <img style={{marginTop: -80, zIndex: -1}} src={'../assets/svg/top-wave.svg'} alt={`amartahonda-logo`}/>
                                <div style={{marginTop: -40}} className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>Data Pemilik Kendaraan (3/6)</div>
                                    <CreditSubmissionOwner/>
                                    <Row>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(2, "prev")} style={{marginRight: 3}}> Kembali</Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(4, "next")} style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}} type="primary"> Lanjut</Button><br/><br/>
                                        </Col>
                                    </Row>
                                </div>
                            </CreditSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 4) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <img style={{marginTop: -80, zIndex: -1}} src={'../assets/svg/top-wave.svg'} alt={`amartahonda-logo`}/>
                                <div style={{marginTop: -40}} className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>Data Kontak & Dokumen (4/6)</div>
                                    <CreditSubmissionPhoneAndDocuments/>
                                    <Row>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(3, "prev")} style={{marginRight: 3}}> Kembali</Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(5, "next")} style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}} type="primary"> Lanjut</Button><br/><br/>
                                        </Col>
                                    </Row>
                                </div>
                            </CreditSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 5) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <img style={{marginTop: -80, zIndex: -1}} src={'../assets/svg/top-wave.svg'} alt={`amartahonda-logo`}/>
                                <div style={{marginTop: -40}} className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>RESUME PENGAJUAN (5/6)</div>
                                    <CreditSubmissionPage5/><br/>
                                </div>
                            </CreditSubmissionLayout>
                            <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex: 500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                                <Row>
                                    <Col span={12} style={{padding: 5}}>
                                        <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(4, "prev")}> Kembali</Button>
                                    </Col>
                                    <Col span={12} style={{padding: 5}}>
                                        <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(6, "next")} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} type="primary"> Lanjut</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </div>

                        <div style={{display: (this.state.pageShow === 6) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <img style={{marginTop: -80, zIndex: -1}} src={'../assets/svg/top-wave.svg'} alt={`amartahonda-logo`}/>
                                <div style={{marginTop: -40}} className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>JADWAL SURVEY (6/6)</div>
                                    <CreditSubmissionPage6 areaProps={this.state.area}/><br/>
                                    <Row>
                                        <Col span={12}>
                                            <Button block={true} loading={this.state.submitting} onClick={() => this.onClickNext(5, "prev")} style={{marginRight: 3}}> Kembali</Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button block={true} loading={this.state.submitting} onClick={() => this.onClickCredit()} type="primary" style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}}> Ajukan Pembelian Kredit</Button><br/><br/>
                                        </Col>
                                    </Row>
                                </div>
                            </CreditSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 7) ? '' : 'none'}}>
                            <CreditSubmissionLayout onClickBack={this.onBackLayout} backToHome={""} headerTitle={`Pengajuan Kredit`}>
                                <div className={`content-top-padding-20 height-100`}>
                                    <Result
                                        status="success"
                                        title="Pengisian Data Sukses"
                                        subTitle="Silakan tunggu konfirmasi dari kami atau dari pihak leasing untuk proses selanjutnya"
                                    />

                                    Nomor Pengajuan: {this.state.offerCode}
                                    <Tooltip title="Click untuk copy nomor SPK" color={'cyan'}>
                                        <CopyOutlined style={{color: "#259261"}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.offerCode)}/>
                                    </Tooltip> <br/> <br/>

                                    <Alert
                                        message="Informasi"
                                        description={`Untuk memudahkan pelacakan dan informasi terkait transaksi anda, kami menghimbau anda untuk menyimpan nomor AJU KREDIT. click untuk copy`}
                                        type="info"
                                        style={{cursor: "pointer"}}
                                        showIcon
                                        onClick={event => this.copyToClipboard(this.state.offerCode)}
                                    /><br/><br/>

                                    <div style={{display: (this.state.token) ? '' : 'none', textAlign: "center"}}>
                                        <Link to={`/my-order`}>
                                            <Button type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}}>Lihat Order Saya</Button>
                                        </Link>
                                    </div>

                                    <br/><br/>
                                    <Link to={`/`}>
                                        <div style={{textAlign: "center", fontSize: "80%", paddingTop: 30}}>
                                            Kembali ke Home Amartahonda
                                        </div>
                                    </Link>
                                </div>
                            </CreditSubmissionLayout>
                        </div>
                    </React.Fragment>
                }

                <Modal
                    open={this.state.finalLoadingModal}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title={"Login Dibutuhkan"}
                    open={this.state.showModalNeedLogin}
                    onCancel={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    onOk={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    footer={[
                        <Button key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            window.location.href = "/login?internal-page-source=" + window.location.href;
                        }}>Login / Daftar AmartaVIP</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalNeedLogin: false})
                        }}>Cancel</Button>
                    ]}
                >
                    Pengajuan pesanan untuk orang lain, Anda perlu login terlebih dahulu.
                </Modal>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    vehicleData: state.RvehicleData,

    ownerIdCardNumber: state.RownerIdCardNumber,
    ownerIdCardName: state.RownerIdCardName,
    ownerIdCardImage: state.RownerIdCardImage,
    spouseIdCardNumber: state.RspouseIdCardNumber,
    spouseIdCardName: state.RspouseIdCardName,
    spouseIdCardImage: state.RspouseIdCardImage,

    birthMother: state.RbirthMother,
    ownerBirthPlace: state.RownerBirthPlace,
    ownerBirthDate: state.RownerBirthDate,
    ownerFullAddress: state.RownerFullAddress,
    ownerMaritalStatus: state.RownerMaritalStatus,
    ownerMaritalStatusName: state.RownerMaritalStatusName,
    ownerProvinceCode: state.RownerProvinceCode,
    ownerProvinceName: state.RownerProvinceName,
    ownerCityCode: state.RownerCityCode,
    ownerCityName: state.RownerCityName,
    ownerDistrictCode: state.RownerDistrictCode,
    ownerDistrictName: state.RownerDistrictName,
    ownerSubdistrictCode: state.RownerSubdistrictCode,
    ownerSubdistrictName: state.RownerSubdistrictName,
    ownerPostalCode: state.RownerPostalCode,
    ownerHamlet: state.RownerHamlet,
    ownerNeighbourhood: state.RownerNeighbourhood,
    ownerOccupationCode: state.RownerOccupationCode,
    ownerOccupationName: state.RownerOccupationName,
    ownerPlaceToStayCode: state.RownerPlaceToStayCode,
    ownerPlaceToStayName: state.RownerPlaceToStayName,
    ownerLastEducationCode: state.RownerLastEducationCode,
    ownerLastEducationName: state.RownerLastEducationName,
    ownerDifferentAddress: state.RownerDifferentAddress,
    shippingFullAddress: state.RshippingFullAddress,
    shippingProvinceCode: state.RshippingProvinceCode,
    shippingProvinceName: state.RshippingProvinceName,
    shippingCityCode: state.RshippingCityCode,
    shippingCityName: state.RshippingCityName,
    shippingDistrictCode: state.RshippingDistrictCode,
    shippingDistrictName: state.RshippingDistrictName,
    shippingSubdistrictCode: state.RshippingSubdistrictCode,
    shippingSubdistrictName: state.RshippingSubdistrictName,
    shippingPostalCode: state.RshippingPostalCode,
    shippingHamlet: state.RshippingHamlet,
    shippingNeighbourhood: state.RshippingNeighbourhood,

    guarantorIdCardNumber: state.RguarantorIdCardNumber,
    guarantorIdCardName: state.RguarantorIdCardName,
    guarantorIdCardImage: state.RguarantorIdCardImage,
    guarantorBirthPlace: state.RguarantorBirthPlace,
    guarantorBirthDate: state.RguarantorBirthDate,
    guarantorFullAddress: state.RguarantorFullAddress,
    guarantorProvinceCode: state.RguarantorProvinceCode,
    guarantorProvinceName: state.RguarantorProvinceName,
    guarantorCityCode: state.RguarantorCityCode,
    guarantorCityName: state.RguarantorCityName,
    guarantorDistrictCode: state.RguarantorDistrictCode,
    guarantorDistrictName: state.RguarantorDistrictName,
    guarantorSubdistrictCode: state.RguarantorSubdistrictCode,
    guarantorSubdistrictName: state.RguarantorSubdistrictName,
    guarantorPostalCode: state.RguarantorPostalCode,
    guarantorHamlet: state.RguarantorHamlet,
    guarantorNeighbourhood: state.RguarantorNeighbourhood,
    guarantorMarriageAge: state.RguarantorMarriageAge,
    guarantorOwnerRelation: state.RguarantorOwnerRelation,

    familyCardNumber: state.RfamilyCardNumber,
    familyCardImage: state.RfamilyCardImage,
    incomeDocumentImage: state.RincomeDocumentImage,
    placeToStayDocumentImage: state.RplaceToStayDocumentImage,
    maritalDocumentImage: state.RmaritalDocumentImage,

    contactOwnerPhoneNumber: state.RcontactOwnerPhoneNumber,
    contactGuarantorPhoneNumber: state.RcontactGuarantorPhoneNumber,
    contactOwnerAlternativePhoneNumber: state.RcontactOwnerAlternativePhoneNumber,
    offerNote: state.RofferNote,

    downPayment: state.RdownPayment,
    installmentAmount: state.RinstallmentAmount,
    tenor: state.Rtenor,
    fincoId: state.RfincoId,
    fincoChosenCode: state.RfincoChosenCode,
    fincoChosenName: state.RfincoChosenName,
    creditStartDate: state.RcreditStartDate,
    surveyDate: state.RsurveyDate,
    surveyTime: state.RsurveyTime,
    promoChosenData: state.RpromoChosenData,
    creditRate: state.RcreditRate,
    creditInstallmentAmount: state.RcreditInstallmentAmount,

    guarantorAndOwnerDifferent: state.RguarantorAndOwnerDifferent,
    guarantorCurrentAddressDifferent: state.RguarantorCurrentAddressDifferent,
    guarantorCurrentAddressFullAddress: state.RguarantorCurrentAddressFullAddress,
    guarantorCurrentAddressProvinceCode: state.RguarantorCurrentAddressProvinceCode,
    guarantorCurrentAddressProvinceName: state.RguarantorCurrentAddressProvinceName,
    guarantorCurrentAddressCityCode: state.RguarantorCurrentAddressCityCode,
    guarantorCurrentAddressCityName: state.RguarantorCurrentAddressCityName,
    guarantorCurrentAddressDistrictCode: state.RguarantorCurrentAddressDistrictCode,
    guarantorCurrentAddressDistrictName: state.RguarantorCurrentAddressDistrictName,
    guarantorCurrentAddressSubdistrictCode: state.RguarantorCurrentAddressSubdistrictCode,
    guarantorCurrentAddressSubdistrictName: state.RguarantorCurrentAddressSubdistrictName,
    guarantorCurrentAddressPostalCode: state.RguarantorCurrentAddressPostalCode,
    guarantorCurrentAddressHamlet: state.RguarantorCurrentAddressHamlet,
    guarantorCurrentAddressNeighbourhood: state.RguarantorCurrentAddressNeighbourhood,
});

const reduxDispatch = (dispatch: any) => ({
    changeVehicleData: (data: string) => dispatch({type: 'CHANGE_VEHICLE_DATA', value: data}),
    changeDownPayment: (data: string) => dispatch({type: 'CHANGE_DOWNPAYMENT', value: data}),
    changeInstallmentAmount: (data: string) => dispatch({type: 'CHANGE_INSTALLMENTAMOUNT', value: data}),

    changeSpouseIdCardNumber: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_NUMBER', value: data}),
    changeSpouseIdCardName: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_NAME', value: data}),
    changeSpouseIdCardImage: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_IMAGE', value: data}),

    changeOwnerIdCardNumber: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_NUMBER', value: data}),
    changeOwnerIdCardName: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_NAME', value: data}),
    changeBirthMother: (data: string) => dispatch({type: 'CHANGE_BIRTH_MOTHER', value: data}),
    changeOwnerIdCardImage: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_IMAGE', value: data}),
    changeOwnerBirthPlace: (data: string) => dispatch({type: 'CHANGE_OWNER_BIRTHPLACE', value: data}),
    changeOwnerBirthDate: (data: string) => dispatch({type: 'CHANGE_OWNER_BIRTHDATE', value: data}),
    changeOwnerFullAddress: (data: string) => dispatch({type: 'CHANGE_OWNER_FULLADDRESS', value: data}),
    changeOwnerMaritalStatus: (data: string) => dispatch({type: 'CHANGE_OWNER_MARITAL_STATUS', value: data}),
    changeOwnerMaritalStatusName: (data: string) => dispatch({type: 'CHANGE_OWNER_MARITAL_STATUSNAME', value: data}),
    changeOwnerProvinceCode: (data: string) => dispatch({type: 'CHANGE_OWNER_PROVINCECODE', value: data}),
    changeOwnerProvinceName: (data: string) => dispatch({type: 'CHANGE_OWNER_PROVINCENAME', value: data}),
    changeOwnerCityCode: (data: string) => dispatch({type: 'CHANGE_OWNER_CITYCODE', value: data}),
    changeOwnerCityName: (data: string) => dispatch({type: 'CHANGE_OWNER_CITYNAME', value: data}),
    changeOwnerDistrictCode: (data: string) => dispatch({type: 'CHANGE_OWNER_DISTRICTCODE', value: data}),
    changeOwnerDistrictName: (data: string) => dispatch({type: 'CHANGE_OWNER_DISTRICTNAME', value: data}),
    changeOwnerSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_OWNER_SUBDISTRICTCODE', value: data}),
    changeOwnerSubdistrictName: (data: string) => dispatch({type: 'CHANGE_OWNER_SUBDISTRICTNAME', value: data}),
    changeOwnerPostalCode: (data: string) => dispatch({type: 'CHANGE_OWNER_POSTALCODE', value: data}),
    changeOwnerHamlet: (data: string) => dispatch({type: 'CHANGE_OWNER_HAMLET', value: data}),
    changeOwnerNeighbourhood: (data: string) => dispatch({type: 'CHANGE_OWNER_NEIGHBOURHOOD', value: data}),
    changeOwnerOccupationCode: (data: string) => dispatch({type: 'CHANGE_OWNER_OCCUPATIONCODE', value: data}),
    changeOwnerOccupationName: (data: string) => dispatch({type: 'CHANGE_OWNER_OCCUPATIONNAME', value: data}),
    changeOwnerPlaceToStayCode: (data: string) => dispatch({type: 'CHANGE_OWNER_PLACETOSTAYCODE', value: data}),
    changeOwnerPlaceToStayName: (data: string) => dispatch({type: 'CHANGE_OWNER_PLACETOSTAYNAME', value: data}),
    changeOwnerLastEducationCode: (data: string) => dispatch({type: 'CHANGE_OWNER_LASTEDUCATIONCODE', value: data}),
    changeOwnerLastEducationName: (data: string) => dispatch({type: 'CHANGE_OWNER_LASTEDUCATIONNAME', value: data}),
    changeOwnerDifferentAddress: (data: string) => dispatch({type: 'CHANGE_OWNER_DIFFERENTADDRESS', value: data}),
    changeShippingDifferentAddress: (data: string) => dispatch({type: 'CHANGE_SHIPPING_FULLADDRESS', value: data}),
    changeShippingProvinceCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_PROVINCECODE', value: data}),
    changeShippingProvinceName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_PROVINCENAME', value: data}),
    changeShippingCityCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_CITYCODE', value: data}),
    changeShippingCityName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_CITYNAME', value: data}),
    changeShippingDistrictCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_DISTRICTCODE', value: data}),
    changeShippingDistrictName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_DISTRICTNAME', value: data}),
    changeShippingSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_SUBDISTRICTCODE', value: data}),
    changeShippingSubdistrictName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_SUBDISTRICTNAME', value: data}),
    changeShippingPostalCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_POSTALCODE', value: data}),
    changeShippingHamlet: (data: string) => dispatch({type: 'CHANGE_SHIPPING_HAMLET', value: data}),
    changeShippingNeighbourhood: (data: string) => dispatch({type: 'CHANGE_SHIPPING_NEIGHBOURHOOD', value: data}),

    changeGuarantorIdCardNumber: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_NUMBER', value: data}),
    changeGuarantorIdCardName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_NAME', value: data}),
    changeGuarantorIdCardImage: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_IMAGE', value: data}),
    changeGuarantorBirthPlace: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_BIRTHPLACE', value: data}),
    changeGuarantorBirthDate: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_BIRTHDATE', value: data}),
    changeGuarantorFullAddress: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_FULLADDRESS', value: data}),
    changeGuarantorProvinceCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_PROVINCECODE', value: data}),
    changeGuarantorProvinceName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_PROVINCENAME', value: data}),
    changeGuarantorCityCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CITYCODE', value: data}),
    changeGuarantorCityName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CITYNAME', value: data}),
    changeGuarantorDistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_DISTRICTCODE', value: data}),
    changeGuarantorDistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_DISTRICTNAME', value: data}),
    changeGuarantorSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_SUBDISTRICTCODE', value: data}),
    changeGuarantorSubdistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_SUBDISTRICTNAME', value: data}),
    changeGuarantorPostalCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_POSTALCODE', value: data}),
    changeGuarantorHamlet: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_HAMLET', value: data}),
    changeGuarantorNeighbourhood: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_NEIGHBOURHOOD', value: data}),
    changeGuarantorMarriageAge: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_MARRIAGEAGE', value: data}),
    changeGuarantorOwnerRelation: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_OWNERRELATION', value: data}),

    changeFamilyCardNumber: (data: string) => dispatch({type: 'CHANGE_FAMILYCARD_NUMBER', value: data}),
    changeFamilyCardImage: (data: string) => dispatch({type: 'CHANGE_FAMILYCARD_IMAGE', value: data}),
    changeIncomeDocumentImage: (data: string) => dispatch({type: 'CHANGE_INCOMEDOCUMENT_IMAGE', value: data}),
    changePlaceToStayDocumentImage: (data: string) => dispatch({type: 'CHANGE_PLACETOSTAYDOCUMENT_IMAGE', value: data}),
    changeMaritalDocumentImage: (data: string) => dispatch({type: 'CHANGE_MARIRTALDOCUMENT_IMAGE', value: data}),

    changeContactOwnerPhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_OWNERPHONENUMBER', value: data}),
    changeContactGuarantorPhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_GUARANTORPHONENUMBER', value: data}),
    changeContactOwnerAlternativePhoneNumber: (data: string) => dispatch({type: 'CHANGE_CONTACT_OWNERALTERNATIVEPHONENUMBER', value: data}),
    changeOfferNote: (data: string) => dispatch({type: 'CHANGE_OFFER_NOTE', value: data}),

    changeParamData: (data: string) => dispatch({type: 'CHANGE_PARAM_DATA', value: data}),

    changeGuarantorAndOwnerDifferent: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_AND_OWNER_DIFFERENT', value: data}),
    changeGuarantorCurrentAddressDifferent: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DIFFERENT', value: data}),
    changeGuarantorCurrentAddressFullAddress: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_FULLADDRESS', value: data}),
    changeGuarantorCurrentAddressProvinceCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCECODE', value: data}),
    changeGuarantorCurrentAddressProvinceName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCENAME', value: data}),
    changeGuarantorCurrentAddressCityCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_CITYCODE', value: data}),
    changeGuarantorCurrentAddressCityName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_CITYNAME', value: data}),
    changeGuarantorCurrentAddressDistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTCODE', value: data}),
    changeGuarantorCurrentAddressDistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTNAME', value: data}),
    changeGuarantorCurrentAddressSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTCODE', value: data}),
    changeGuarantorCurrentAddressSubdistrictName: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTNAME', value: data}),
    changeGuarantorCurrentAddressPostalCode: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_POSTALCODE', value: data}),
    changeGuarantorCurrentAddressHamlet: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_HAMLET', value: data}),
    changeGuarantorCurrentAddressNeighbourhood: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_CURRENTADDRESS_NEIGHBOURHOOD', value: data}),
});

export default connect(reduxState, reduxDispatch)(withRouter(NewVehicleVariantDetailFormCredit));