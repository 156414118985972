import Cookies from "universal-cookie";
import {Buffer} from "buffer";
import configHomeArea from "../config/setupConfig/homeInitConditionConf";

export default function getHereLocation() {
    const cookies = new Cookies();
    let returnCityGroup = "";
    if (cookies.get('_here')) {
        const hereCookies = JSON.parse(Buffer.from(cookies.get('_here'), "base64").toString());
        if (hereCookies?.state?.toLowerCase()?.trim() === "bandung raya") {
            returnCityGroup = "BANDUNG";
        } else if (hereCookies?.state?.toLowerCase()?.trim() === "jabodetabek") {
            returnCityGroup = "BEKASI";
        } else if (hereCookies?.city?.toLowerCase()?.includes('cirebon')) {
            returnCityGroup = "CIREBON";
        } else if (hereCookies?.city?.toLowerCase()?.includes('kuningan')) {
            returnCityGroup = "KUNINGAN";
        } else if (hereCookies?.city?.toLowerCase()?.includes('manado')) {
            returnCityGroup = "MANADO";
        } else if (hereCookies?.city?.toLowerCase()?.includes('gorontalo')) {
            returnCityGroup = "GORONTALO";
        } else {
            returnCityGroup = configHomeArea.areaCode;
        }
    } else {
        returnCityGroup = configHomeArea.areaCode;
    }


    return returnCityGroup;
}
