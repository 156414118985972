import React, {Component} from "react";
import {momotorVehicleService} from "../../services/momotor/MomotorVehicleService";
import {Select} from "antd";

class MomotorBrandSelect extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            brandCode: "",
            category: "",
            dataVehicleBrand: [],
            selectVehicleBrandValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleBrandData = (brandCode: any) => {
        try {
            momotorVehicleService.getVehicleBrand().then((dataFetchBrand: any) => {
                const dataFetch = dataFetchBrand.data.data;
                dataFetch.sort((item1: { brand: string; }, item2: { brand: any; }) => item1.brand.localeCompare(item2.brand));

                const dataForState: any = dataFetchBrand.data.data.map((dataBrand: { brand: string}) => ({
                    value: dataBrand.brand,
                    name: dataBrand.brand
                }));

                this.setState({
                    brandCode: brandCode,
                    dataVehicleBrand: dataForState
                });
            });
        } catch (e) {
            this.setState({
                brandCode: brandCode,
                dataVehicleBrand: [],
                selectVehicleBrandValue: null,
            });
        }
    }

    onChange = async (value: any, option: any) => {
        const picked = this.state.dataVehicleBrand.find((o: { name: any; }) => o.name === option.key);
        option.uuid = picked.uuid;
        this.setState({
            selectVehicleBrandValue: value,
        });
        this.props.onVehicleBrandChangeProps(option);
    }

    async componentDidMount() {
        await this.promisedSetState({
            brandCode: this.props.brandCode,
            category: this.props.category
        });
        await this.fetchVehicleBrandData(this.props.brandCode);
        await this.onValueChange(this.props.value);
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleBrandValue: value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Select
                    showSearch
                    placeholder="Pilih Brand Kendaraan"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    style={{width: "100%"}}
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={this.state.selectVehicleBrandValue}
                >
                    {
                        this.state.dataVehicleBrand.map((item: any, i: number) =>
                            <Select.Option key={item?.value} value={item?.value?.toLowerCase()}>{item?.name}</Select.Option>
                        )
                    }
                </Select>
            </React.Fragment>
        );
    }
}

export default MomotorBrandSelect;