import moment from "moment";

const initialState = {
    RownerIdCardNumber: '',
    RownerIdCardName: '',
    RownerIdCardImage: '',
    RspouseIdCardNumber: '',
    RspouseIdCardName: '',
    RspouseIdCardImage: '',
    RbirthMother: '',
    RownerBirthPlace: '',
    RownerBirthDate: moment(new Date(), 'YYYY-MM-DD'),
    RownerMaritalStatus: null,
    RownerMaritalStatusName: null,
    RownerFullAddress: '',
    RownerProvinceCode: null,
    RownerProvinceName: null,
    RownerCityCode: null,
    RownerCityName: null,
    RownerDistrictCode: null,
    RownerDistrictName: null,
    RownerSubdistrictCode: null,
    RownerSubdistrictName: null,
    RownerPostalCode: "",
    RownerHamlet: "",
    RownerNeighbourhood: "",
    RownerOccupationCode: null,
    RownerOccupationName: "",
    RownerLastEducationCode: null,
    RownerLastEducationName: "",
    RownerPlaceToStayCode: null,
    RownerPlaceToStayName: "",
    RownerDifferentAddress: false,
    RownerCopyFromGuarantor: false,
    RtakeVehicleInDealer: false,
    RshippingFullAddress: '',
    RshippingProvinceCode: null,
    RshippingProvinceName: null,
    RshippingCityCode: null,
    RshippingCityName: null,
    RshippingDistrictCode: null,
    RshippingDistrictName: null,
    RshippingSubdistrictCode: null,
    RshippingSubdistrictName: null,
    RshippingPostalCode: "",
    RshippingHamlet: "",
    RshippingNeighbourhood: "",

    RguarantorIdCardNumber: '',
    RguarantorIdCardName: '',
    RguarantorIdCardImage: '',
    RguarantorBirthPlace: '',
    RguarantorBirthDate: moment(new Date(), 'YYYY-MM-DD'),
    RguarantorFullAddress: '',
    RguarantorProvinceCode: null,
    RguarantorProvinceName: null,
    RguarantorCityCode: null,
    RguarantorCityName: null,
    RguarantorDistrictCode: null,
    RguarantorDistrictName: null,
    RguarantorSubdistrictCode: null,
    RguarantorSubdistrictName: null,
    RguarantorPostalCode: "",
    RguarantorHamlet: "",
    RguarantorNeighbourhood: "",
    RguarantorMarriageAge: null,
    RguarantorOwnerRelation: null,

    RguarantorAndOwnerDifferent: false,

    RguarantorCurrentAddressDifferent: false,
    RguarantorCurrentAddressFullAddress: '',
    RguarantorCurrentAddressProvinceCode: null,
    RguarantorCurrentAddressProvinceName: null,
    RguarantorCurrentAddressCityCode: null,
    RguarantorCurrentAddressCityName: null,
    RguarantorCurrentAddressDistrictCode: null,
    RguarantorCurrentAddressDistrictName: null,
    RguarantorCurrentAddressSubdistrictCode: null,
    RguarantorCurrentAddressSubdistrictName: null,
    RguarantorCurrentAddressPostalCode: "",
    RguarantorCurrentAddressHamlet: "",
    RguarantorCurrentAddressNeighbourhood: "",

    RfamilyCardNumber: '',
    RfamilyCardImage: '',
    RincomeDocumentType: 'slip gaji',
    RincomeDocumentImage: '',
    RplaceToStayDocumentType: 'pbb',
    RplaceToStayDocumentImage: '',
    RmaritalDocumentType: 'buku nikah',
    RmaritalDocumentImage: '',

    RcontactOwnerPhoneNumber: '',
    RcontactGuarantorPhoneNumber: '',
    RcontactOwnerAlternativePhoneNumber: '',
    RofferNote: '',

    RdownPayment: 0,
    RinstallmentAmount: 0,
    Rtenor: null,
    RcreditStartDate: moment(new Date(), 'YYYY-MM-DD'),
    RfincoId: "",
    RfincoChosenCode: "GLOBAL",
    RfincoChosenName: "PENAWARAN TERBAIK",
    RpromoChosenData: {},
    RsimulationChosenData: {},
    RsurveyDate: moment(new Date(), 'YYYY-MM-DD'),
    RsurveyTime: "10:00",

    RvehicleData: {},

    RcreditRate: 0,
    RcreditInstallmentAmount: 0,

    RparamData: {},
}

const reducer = (state = initialState, action: any) => {
    let currentState = {...state}
    switch (action.type) {
        case "CHANGE_OWNER_IDCARD_NUMBER":
            currentState.RownerIdCardNumber = action.value;
            break;
        case "CHANGE_OWNER_IDCARD_NAME":
            currentState.RownerIdCardName = action.value
            break;
        case "CHANGE_OWNER_IDCARD_IMAGE":
            currentState.RownerIdCardImage = action.value
            break;
        case "CHANGE_SPOUSE_IDCARD_NUMBER":
            currentState.RspouseIdCardNumber = action.value;
            break;
        case "CHANGE_SPOUSE_IDCARD_NAME":
            currentState.RspouseIdCardName = action.value
            break;
        case "CHANGE_SPOUSE_IDCARD_IMAGE":
            currentState.RspouseIdCardImage = action.value
            break;
        case "CHANGE_BIRTH_MOTHER":
            currentState.RbirthMother = action.value
            break;
        case "CHANGE_OWNER_BIRTHPLACE":
            currentState.RownerBirthPlace = action.value
            break;
        case "CHANGE_OWNER_BIRTHDATE":
            currentState.RownerBirthDate = action.value
            break;
        case "CHANGE_OWNER_MARITAL_STATUS":
            currentState.RownerMaritalStatus = action.value
            break;
        case "CHANGE_OWNER_MARITAL_STATUSNAME":
            currentState.RownerMaritalStatusName = action.value
            break;
        case "CHANGE_OWNER_FULLADDRESS":
            currentState.RownerFullAddress = action.value
            break;
        case "CHANGE_OWNER_PROVINCECODE":
            currentState.RownerProvinceCode = action.value
            break;
        case "CHANGE_OWNER_PROVINCENAME":
            currentState.RownerProvinceName = action.value
            break;
        case "CHANGE_OWNER_CITYCODE":
            currentState.RownerCityCode = action.value
            break;
        case "CHANGE_OWNER_CITYNAME":
            currentState.RownerCityName = action.value
            break;
        case "CHANGE_OWNER_DISTRICTCODE":
            currentState.RownerDistrictCode = action.value
            break;
        case "CHANGE_OWNER_DISTRICTNAME":
            currentState.RownerDistrictName = action.value
            break;
        case "CHANGE_OWNER_SUBDISTRICTCODE":
            currentState.RownerSubdistrictCode = action.value
            break;
        case "CHANGE_OWNER_SUBDISTRICTNAME":
            currentState.RownerSubdistrictName = action.value
            break;
        case "CHANGE_OWNER_POSTALCODE":
            currentState.RownerPostalCode = action.value
            break;
        case "CHANGE_OWNER_HAMLET":
            currentState.RownerHamlet = action.value
            break;
        case "CHANGE_OWNER_NEIGHBOURHOOD":
            currentState.RownerNeighbourhood = action.value
            break;
        case "CHANGE_OWNER_OCCUPATIONCODE":
            currentState.RownerOccupationCode = action.value
            break;
        case "CHANGE_OWNER_OCCUPATIONNAME":
            currentState.RownerOccupationName = action.value
            break;
        case "CHANGE_OWNER_LASTEDUCATIONCODE":
            currentState.RownerLastEducationCode = action.value
            break;
        case "CHANGE_OWNER_LASTEDUCATIONNAME":
            currentState.RownerLastEducationName = action.value
            break;
        case "CHANGE_OWNER_PLACETOSTAYCODE":
            currentState.RownerPlaceToStayCode = action.value
            break;
        case "CHANGE_OWNER_PLACETOSTAYNAME":
            currentState.RownerPlaceToStayName = action.value
            break;
        case "CHANGE_OWNER_COPYFROMGUARANTOR":
            currentState.RownerCopyFromGuarantor = action.value
            break;
        case "CHANGE_OWNER_DIFFERENTADDRESS":
            currentState.RownerDifferentAddress = action.value
            break;
        case "CHANGE_TAKE_VEHICLE_IN_DELAER":
            currentState.RtakeVehicleInDealer = action.value
            break;
        case "CHANGE_SHIPPING_FULLADDRESS":
            currentState.RshippingFullAddress = action.value
            break;
        case "CHANGE_SHIPPING_PROVINCECODE":
            currentState.RshippingProvinceCode = action.value
            break;
        case "CHANGE_SHIPPING_PROVINCENAME":
            currentState.RshippingProvinceName = action.value
            break;
        case "CHANGE_SHIPPING_CITYCODE":
            currentState.RshippingCityCode = action.value
            break;
        case "CHANGE_SHIPPING_CITYNAME":
            currentState.RshippingCityName = action.value
            break;
        case "CHANGE_SHIPPING_DISTRICTCODE":
            currentState.RshippingDistrictCode = action.value
            break;
        case "CHANGE_SHIPPING_DISTRICTNAME":
            currentState.RshippingDistrictName = action.value
            break;
        case "CHANGE_SHIPPING_SUBDISTRICTCODE":
            currentState.RshippingSubdistrictCode = action.value
            break;
        case "CHANGE_SHIPPING_SUBDISTRICTNAME":
            currentState.RshippingSubdistrictName = action.value
            break;
        case "CHANGE_SHIPPING_POSTALCODE":
            currentState.RshippingPostalCode = action.value
            break;
        case "CHANGE_SHIPPING_HAMLET":
            currentState.RshippingHamlet = action.value
            break;
        case "CHANGE_SHIPPING_NEIGHBOURHOOD":
            currentState.RshippingNeighbourhood = action.value
            break;

        case "CHANGE_GUARANTOR_IDCARD_NUMBER":
            currentState.RguarantorIdCardNumber = action.value;
            break;
        case "CHANGE_GUARANTOR_IDCARD_NAME":
            currentState.RguarantorIdCardName = action.value
            break;
        case "CHANGE_GUARANTOR_IDCARD_IMAGE":
            currentState.RguarantorIdCardImage = action.value
            break;
        case "CHANGE_GUARANTOR_BIRTHPLACE":
            currentState.RguarantorBirthPlace = action.value
            break;
        case "CHANGE_GUARANTOR_BIRTHDATE":
            currentState.RguarantorBirthDate = action.value
            break;
        case "CHANGE_GUARANTOR_FULLADDRESS":
            currentState.RguarantorFullAddress = action.value
            break;
        case "CHANGE_GUARANTOR_PROVINCECODE":
            currentState.RguarantorProvinceCode = action.value
            break;
        case "CHANGE_GUARANTOR_PROVINCENAME":
            currentState.RguarantorProvinceName = action.value
            break;
        case "CHANGE_GUARANTOR_CITYCODE":
            currentState.RguarantorCityCode = action.value
            break;
        case "CHANGE_GUARANTOR_CITYNAME":
            currentState.RguarantorCityName = action.value
            break;
        case "CHANGE_GUARANTOR_DISTRICTCODE":
            currentState.RguarantorDistrictCode = action.value
            break;
        case "CHANGE_GUARANTOR_DISTRICTNAME":
            currentState.RguarantorDistrictName = action.value
            break;
        case "CHANGE_GUARANTOR_SUBDISTRICTCODE":
            currentState.RguarantorSubdistrictCode = action.value
            break;
        case "CHANGE_GUARANTOR_SUBDISTRICTNAME":
            currentState.RguarantorSubdistrictName = action.value
            break;
        case "CHANGE_GUARANTOR_POSTALCODE":
            currentState.RguarantorPostalCode = action.value
            break;
        case "CHANGE_GUARANTOR_HAMLET":
            currentState.RguarantorHamlet = action.value
            break;
        case "CHANGE_GUARANTOR_NEIGHBOURHOOD":
            currentState.RguarantorNeighbourhood = action.value
            break;
        case "CHANGE_GUARANTOR_MARRIAGEAGE":
            currentState.RguarantorMarriageAge = action.value
            break;
        case "CHANGE_GUARANTOR_OWNERRELATION":
            currentState.RguarantorOwnerRelation = action.value
            break;



        case "CHANGE_GUARANTOR_AND_OWNER_DIFFERENT":
            currentState.RguarantorAndOwnerDifferent = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_DIFFERENT":
            currentState.RguarantorCurrentAddressDifferent = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_FULLADDRESS":
            currentState.RguarantorCurrentAddressFullAddress = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCECODE":
            currentState.RguarantorCurrentAddressProvinceCode = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_PROVINCENAME":
            currentState.RguarantorCurrentAddressProvinceName = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_CITYCODE":
            currentState.RguarantorCurrentAddressCityCode = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_CITYNAME":
            currentState.RguarantorCurrentAddressCityName = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTCODE":
            currentState.RguarantorCurrentAddressDistrictCode = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_DISTRICTNAME":
            currentState.RguarantorCurrentAddressDistrictName = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTCODE":
            currentState.RguarantorCurrentAddressSubdistrictCode = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_SUBDISTRICTNAME":
            currentState.RguarantorCurrentAddressSubdistrictName = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_POSTALCODE":
            currentState.RguarantorCurrentAddressPostalCode = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_HAMLET":
            currentState.RguarantorCurrentAddressHamlet = action.value
            break;
        case "CHANGE_GUARANTOR_CURRENTADDRESS_NEIGHBOURHOOD":
            currentState.RguarantorCurrentAddressNeighbourhood = action.value
            break;

        case "CHANGE_FAMILYCARD_NUMBER":
            currentState.RfamilyCardNumber = action.value
            break;
        case "CHANGE_FAMILYCARD_IMAGE":
            currentState.RfamilyCardImage = action.value
            break;
        case "CHANGE_INCOMEDOCUMENT_TYPE":
            currentState.RincomeDocumentType = action.value
            break;
        case "CHANGE_INCOMEDOCUMENT_IMAGE":
            currentState.RincomeDocumentImage = action.value
            break;
        case "CHANGE_PLACETOSTAYDOCUMENT_TYPE":
            currentState.RplaceToStayDocumentType = action.value
            break;
        case "CHANGE_PLACETOSTAYDOCUMENT_IMAGE":
            currentState.RplaceToStayDocumentImage = action.value
            break;
        case "CHANGE_MARIRTALDOCUMENT_TYPE":
            currentState.RmaritalDocumentType = action.value
            break;
        case "CHANGE_MARIRTALDOCUMENT_IMAGE":
            currentState.RmaritalDocumentImage = action.value
            break;

        case "CHANGE_CONTACT_OWNERPHONENUMBER":
            currentState.RcontactOwnerPhoneNumber = action.value
            break;
        case "CHANGE_CONTACT_GUARANTORPHONENUMBER":
            currentState.RcontactGuarantorPhoneNumber = action.value
            break;
        case "CHANGE_CONTACT_OWNERALTERNATIVEPHONENUMBER":
            currentState.RcontactOwnerAlternativePhoneNumber = action.value
            break;
        case "CHANGE_OFFER_NOTE":
            currentState.RofferNote = action.value
            break;

        case "CHANGE_DOWNPAYMENT":
            currentState.RdownPayment = action.value
            break;
        case "CHANGE_INSTALLMENTAMOUNT":
            currentState.RinstallmentAmount = action.value
            break;
        case "CHANGE_TENOR":
            currentState.Rtenor = action.value
            break;
        case "CHANGE_CREDITSTARTDATE":
            currentState.RcreditStartDate = action.value
            break;
        case "CHANGE_PROMOCHOSENDATA":
            currentState.RpromoChosenData = action.value
            break;
        case "CHANGE_SIMULATIONDATA":
            currentState.RsimulationChosenData = action.value
            break;
        case "CHANGE_FINCOID":
            currentState.RfincoId = action.value
            break;
        case "CHANGE_FINCOCHOSENCODE":
            currentState.RfincoChosenCode = action.value
            break;
        case "CHANGE_FINCOCHOSENNAME":
            currentState.RfincoChosenName = action.value
            break;
        case "CHANGE_SURVEYDATE":
            currentState.RsurveyDate = action.value
            break;
        case "CHANGE_SURVEYTIME":
            currentState.RsurveyTime = action.value
            break;

        case "CHANGE_VEHICLE_DATA":
            currentState.RvehicleData = action.value
            break;

        case "CHANGE_CREDIT_RATE":
            currentState.RcreditRate = action.value
            break;
        case "CHANGE_CREDIT_INSTALLMENTAMOUNT":
            currentState.RcreditInstallmentAmount = action.value
            break;

        case "CHANGE_PARAM_DATA":
            currentState.RparamData = action.value
            break;
    }
    return currentState;
}

export default reducer;
