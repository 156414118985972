import axios from "axios";
import {baseUrlLogBq} from "../../config/apiConfig/apiConfig";

class TradeInApiService {
    private axios = axios.create({
        baseURL: baseUrlLogBq,
    });

    public async postTradeIn(params: any) {
        try {
            return await this.axios.post<any>('/log/tradein.amartahonda.com',
                params, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            throw new Error(e?.response);
        }
    }
}

export const tradeInApiService = new TradeInApiService();