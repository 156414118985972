import axios from "axios"

class Catalog3RdPartyService {
    private axios = axios.create({
        baseURL: "",
    })

    public setToken(token: string) {
        this.axios.defaults.headers.common["Authorization"] = "Bearer " + token
    }

    public async getMomotorCity() {
        const axioLogUrl = axios.create({
            baseURL: `https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com`,
        })

        try {
            return await axioLogUrl.get<any>(`/3rd-party/momotor/momotor-city.json`, {
                headers: {
                    // "Content-Type": "application/json",
                },
                params: {},
            })
        } catch (e: any) {
            throw new Error(e)
        }
    }
}

export const catalog3RdPartyService = new Catalog3RdPartyService()