const DealerConfig: any = [
    {
        code: "01400",
        dealer_code: "DLR#HND#01400",
        name: "PT ARIMBI AMARTAPURA",
        city_group: "BANDUNG",
    },
    {
        code: "01369",
        dealer_code: "DLR#HND#01369",
        name: "PT AMARTA SAYAP MERAH PADALARANG",
        city_group: "BANDUNG",
    },
    {
        code: "06834",
        dealer_code: "DLR#HND#06834",
        name: "PT AMARTA SAYAP MERAH BEKASI",
        city_group: "BEKASI",
    },
    {
        code: "12293",
        dealer_code: "DLR#HND#12293",
        name: "PT AMARTA SAYAP MERAH CIREBON",
        city_group: "CIREBON",
    },
    {
        code: "12294",
        dealer_code: "DLR#HND#12294",
        name: "PT AMARTA SAYAP MERAH KUNINGAN",
        city_group: "KUNINGAN",
    },
    {
        code: "12730",
        dealer_code: "DLR#HND#12730",
        name: "PT AMARTA SAYAP MERAH MANADO",
        city_group: "MANADO",
    },
    {
        code: "15925",
        dealer_code: "DLR#HND#15925",
        name: "PT AMARTA SAYAP MERAH GORONTALO",
        city_group: "GORONTALO",
    }

];

export default DealerConfig;


// booking service
