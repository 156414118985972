import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {devMomotorService} from "../../services/dev/DevMomotorService";

class MomotorDev extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            company: "AMARTA"
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await devMomotorService.setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NfaWQiOiIxNzZlMTg2Ni1jMDQzLTQxZmQtODMwNC00YTEwY2QyOGE0MjEiLCJpYXQiOjE2MzM1NzE4MjF9.o0dKImIYoa4W2jQ3xgcRR7Zu-WcykKwYTsnYnyhwUX4");
        try {
            const dataBrand = await devMomotorService.getBrand();
            console.log(dataBrand)
        } catch (e) {
            console.log(e);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                Test Dev
            </React.Fragment>
        );
    }
}

export default withRouter(MomotorDev);