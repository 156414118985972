import React, {Component} from "react";
import {PricelistStates} from "./types/PricelistTypes";
import {withRouter} from "../../hoc/withRouter";
import Image from "antd/lib/image";
import navigatorShare from "../../helper/navigatorShare";
import Empty from "antd/lib/empty";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import {Link} from "react-router-dom";
import Skeleton from "antd/lib/skeleton";
import {idealApiService} from "../../services/ideal/IdealApiService";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {pricelistBqLogService} from "../../services/track/PricelistBqLogService";
import Row from "antd/lib/row";
import currencyMillionFormat from "../../helper/currencyMillionFormat";
import currencyThousandFormat from "../../helper/currencyThousandFormat";
import './Pricelist.css';
import WhatsAppOutlined from "@ant-design/icons/WhatsAppOutlined";
import Button from "antd/lib/button";
import whatsappLink from "../../helper/whatsappLink";
import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";
import moment from "moment";


class Pricelist extends Component<any, PricelistStates> {
    private readonly initState!: PricelistStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const pricelistCode = match.params.code;
        document.title = `Amartahonda | Pricelist`;

        this.initState = {
            pricelistCode: pricelistCode,
            detailPricelist: {},
            fetchPricelist: true,
            dataVariant: [],
            tenorDetailGrouping: [],
            dpDetailGrouping: [],
            normalTable: [],
            finalPricelistData: {},
            expiredAt: "",
        }
        this.state = {...this.initState}
    }

    async fetchData() {
        try {
            const dataPriceList = await idealApiService.getPricelistJson(this.state.pricelistCode);

            const normalTable = dataPriceList?.data?.pricelist_data?.normal ?? [];
            normalTable.sort((a: { dp_nominal: number; }, b: { dp_nominal: number; }) => a.dp_nominal - b.dp_nominal);
            const tenorGrouping: any = [];
            const tenorDetailGrouping: any = [];
            const dpGrouping: any = [];
            const dpDetailGrouping: any = [];
            for (const item of normalTable) {
                item['dp_x'] = item.dp_nominal;
                if (!dpGrouping.includes(item['dp_x'])) {
                    dpGrouping.push(item['dp_x']);
                    dpDetailGrouping.push({
                        key: item['dp_x'],
                        dp_nominal: item.dp_nominal,
                        promo_dp_nominal: item.promo_dp_nominal,
                    });
                }
                item['tenor_x'] = (item.promo_tenor) ? `${item.tenor}_${item.promo_tenor}` : `${item.tenor}_0`;
                if (!tenorGrouping.includes(item['tenor_x'])) {
                    tenorGrouping.push(item['tenor_x']);
                    tenorDetailGrouping.push({
                        key: item['tenor_x'],
                        tenor: item.tenor,
                        promo_tenor: item.promo_tenor
                    });
                }

                item['dp_x_tenor_x'] = `${item['dp_x']}_${item['tenor_x']}`;
            }

            // const masterX: any = {};
            // for (const itemDp of dpDetailGrouping) {
            //     for (const itemTenor of tenorDetailGrouping) {
            //         for (const x of normalTable) {
            //             if ((itemDp.key === x.dp_nominal) && (itemTenor.key === x.tenor_x)) {
            //                 masterX[`${itemDp.key}_${itemTenor.key}`] = x;
            //             }
            //         }
            //     }
            // }
            //
            // console.log(masterX);

            const finalPricelistData: any = [];
            for (const itemDp of dpDetailGrouping) {
                for (const itemTenor of tenorDetailGrouping) {
                    const picked = normalTable.find((o: { dp_x_tenor_x: any; }) => o.dp_x_tenor_x === `${itemDp.key}_${itemTenor.key}`);
                    if (picked) {
                        finalPricelistData[`${itemDp.key}_${itemTenor.key}`] = picked;
                    } else {
                        finalPricelistData[`${itemDp.key}_${itemTenor.key}`] = {};
                    }
                }
            }

            let expiredAt = "";
            const today = moment(); // Mendapatkan tanggal hari ini dengan moment
            const targetDate = moment(dataPriceList?.data?.expired_at);
            if (today.isSameOrBefore(targetDate)) {
                if (today.isSame(targetDate, 'day')) {
                    expiredAt = "*Pricelist ini berlaku hingga hari ini";
                } else {
                    const daysDiff = targetDate.diff(today, 'days');
                    expiredAt = `*Pricelist berlaku hingga ${daysDiff} hari kedepan`;
                }

            } else {
                expiredAt = "Pricelist ini sudah melewati batas tanggal berlaku";
            }

            console.log(dataPriceList?.data?.expired_at);

            this.setState({
                detailPricelist: dataPriceList.data,
                tenorDetailGrouping: tenorDetailGrouping,
                dpDetailGrouping: dpDetailGrouping,
                normalTable: normalTable,
                finalPricelistData: finalPricelistData,
                expiredAt: expiredAt
            });

            await this.fetchVariant(dataPriceList);
            this.setState({
                fetchPricelist: false,
            });
        } catch (e) {
            this.setState({
                fetchPricelist: false,
                detailPricelist: null,
            });
        }
    }

    async fetchVariant(dataPriceList: any) {
        await trimitraCatalogServices.getCatalogVariant({areaCode: dataPriceList.data?.city_group, variantCode: dataPriceList.data?.variant_code}).then(
            dataVariantHome => {
                if (dataVariantHome.data?.data[0]) {
                    this.setState({
                        dataVariant: dataVariantHome.data,
                    });
                }
            });
    }


    onClickWa() {
        pricelistBqLogService.sendTrackLog({
            sourceUrl: window.location.href,
            dealCode: this.state.pricelistCode,
            offerCode: "",
            category: "click_button",
            name: "whatsapp",
            parameter: null,
        });
        whatsappLink('628112341361', `Saya membutuhkan info mengenai pricelist amartahonda %0a${window.location.href}`);
    }

    onClickShare() {
        pricelistBqLogService.sendTrackLog({
            sourceUrl: window.location.href,
            dealCode: this.state.pricelistCode,
            offerCode: "",
            category: "click_button",
            name: "share",
            parameter: null,
        });
        navigatorShare("", "Dapatkan penawaran terbaik di amartahonda.com ", window.location.href);
    }

    async componentDidMount() {
        await this.fetchData();

        await pricelistBqLogService.initTrackLog({
            sourceUrl: window.location.href,
            dealCode: this.state.pricelistCode,
            offerCode: "",
            category: "landing",
            name: "landing",
            parameter: null,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                        <div className={`variant-header`}>
                            <Link to={`/${this.state.detailPricelist?.city_group?.toLowerCase()}`}>
                                <img style={{height: 22, paddingLeft: 15, marginTop: "-10px", cursor: "pointer"}} className={`header-logo`} src={'https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/header-text.png'} alt={`amartahonda-logo`}/>
                            </Link>
                        </div>
                        <div style={{minHeight: '92vh', backgroundColor: "#fff"}}>
                            <div style={(this.state.fetchPricelist) ? {padding: 30} : {display: 'none'}}>
                                <Skeleton active={true} avatar paragraph={{rows: 12}}/>
                            </div>
                            <div style={(!this.state.fetchPricelist && this.state.detailPricelist === null) ? {paddingTop: 20} : {display: 'none'}}>
                                <Empty description={`Pricelist tidak ditemukan`}/>
                            </div>

                            <div style={(!this.state.fetchPricelist && this.state.detailPricelist !== null) ? {paddingTop: 20} : {display: 'none'}}>
                                {/*<h2 style={{textAlign: "center", fontSize: "120%"}}>{this.state.dataVariant?.meta?.VariantName}</h2>*/}
                                <h2 style={{textAlign: "center", fontSize: "120%"}}>{this.state.detailPricelist?.title}</h2>
                                <h2 style={{textAlign: "center", fontSize: "110%"}}><b>{this.state.detailPricelist?.caption}</b></h2>
                                <div style={{textAlign: "right", paddingRight: 10, color: "#f84747"}}>{this.state.expiredAt}</div>
                                <Row style={{marginTop: -15}}>
                                    {this.state.dataVariant?.data?.map((item: any, i: number) => {
                                        return (
                                            <Col key={i} xxl={{span: 12}} xl={{span: 12}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} style={{padding: 15}}>
                                                <div className={`card-product boxHover`}>
                                                    <div className={`product-img-center`}>
                                                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={item?.url_image} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                                    </div>
                                                    <div className={`p-chosen-name`} style={{fontSize: "110%", color: "#525252", paddingTop: 1, textAlign: "center"}}>
                                                        <b>{item?.color_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</b>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>

                                <Row>
                                    {/*<Col span={24} style={{marginBottom: -10}}>*/}
                                    {/*    <div style={{textAlign: "right"}}>*/}
                                    {/*        <div style={{paddingRight: "28.5%", marginBottom: -8, fontSize: "80%"}}><b>DISKON</b></div>*/}
                                    {/*        <div style={{paddingRight: "31%", marginBottom: -8, fontSize: "125%", color: "red"}}><b>{(normal?.[0]?.tenor - normal?.[0]?.promo_tenor)}x</b></div>*/}
                                    {/*        <DownOutlined style={{paddingRight: "31%"}}/>*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                    <Col span={24} style={{paddingLeft: 7, paddingRight: 7}}>
                                        <div style={{overflowX: 'auto'}}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <th rowSpan={2}>DP</th>
                                                    <th colSpan={this.state.tenorDetailGrouping?.length}>Cicilan (000)</th>
                                                </tr>
                                                <tr>
                                                    {this.state.tenorDetailGrouping?.map((item: any, i: number) =>
                                                        <td key={i}>
                                                            <span style={item?.promo_tenor ? {display: 'none'} : {}}>{item.tenor}</span>
                                                            <span style={item?.promo_tenor ? {} : {display: 'none'}}><s style={{fontWeight: "bold", color: "red"}}>{item?.tenor}</s> {/*{item?.promo_tenor}*/} {Number(item?.tenor - item?.promo_tenor)}  </span>
                                                        </td>
                                                    )}
                                                </tr>
                                                {this.state.dpDetailGrouping?.map((a: any, i: number) =>
                                                    <tr key={i}>
                                                        <td>{currencyMillionFormat(a?.dp_nominal)}</td>
                                                        {this.state.tenorDetailGrouping?.map((b: any, j: number) => {
                                                            const c = `${a.key}_${b.key}`;
                                                            let installment: any = "-";
                                                            let promoInstallment: any = "";
                                                            if (this.state?.finalPricelistData?.[c]?.installment) {
                                                                installment = (this.state?.finalPricelistData?.[c]?.installment) ? this.state?.finalPricelistData?.[c]?.installment : "-"
                                                            }
                                                            if (this.state?.finalPricelistData?.[c]?.promo_installment) {
                                                                promoInstallment = (this.state?.finalPricelistData?.[c]?.promo_installment) ? this.state?.finalPricelistData?.[c]?.promo_installment : 0
                                                            }

                                                            installment = (installment !== "-") ? currencyThousandFormat(parseFloat(installment))?.replace("jt", "") : "-";
                                                            promoInstallment = (promoInstallment !== "") ? currencyThousandFormat(parseFloat(this.state?.finalPricelistData?.[c]?.installment) - parseFloat(promoInstallment))?.replace("jt", "") : "";

                                                            return (
                                                                <td key={c}>
                                                                    <span style={promoInstallment ? {display: 'none'} : {}}>{installment}</span>
                                                                    <span style={promoInstallment ? {} : {display: 'none'}}><s style={{fontWeight: "bold", color: "red"}}>{installment}</s> {promoInstallment}</span>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>


                                        {/*<br/><br/><br/>
                                            {this.state.normalTable?.map((item: any, i: number) =>
                                                <tr key={i}>
                                                    <td>{currencyMillionFormat(item?.dp_nominal)}</td>
                                                    <td><span style={{fontWeight: "bold", color: "red"}}>{item?.tenor}x</span> {item?.promo_tenor}x {currencyMillionFormat(item?.installment)?.replace("jt", "")}</td>
                                                </tr>
                                            )}*/}

                                        <br/><br/><br/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>

                    <Row>
                        <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex: 500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                            <Row>
                                <Col span={19}>
                                    <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} block={true} onClick={() => this.onClickWa()} type="primary"><WhatsAppOutlined/> Chat Dengan Kami</Button>
                                </Col>
                                <Col span={5} style={{paddingLeft: 5}}>
                                    <Button block={true} onClick={() => this.onClickShare()}><ShareAltOutlined/></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withRouter(Pricelist);