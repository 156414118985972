import axios from "axios";
import {baseUrlLogAmartaHonda, xApiKeyLogAmartaHonda} from "../../config/apiConfig/apiConfig";

class PromoLogServices {
    private axios = axios.create({
        baseURL: baseUrlLogAmartaHonda,
    });

    public async createB2bCreditOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/promo-log',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyLogAmartaHonda
                    }
                });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

}

export const promoLogServices = new PromoLogServices();
