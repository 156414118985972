import axios from "axios";
import {baseUrlOpenSearch} from "../../config/apiConfig/apiConfig";

class OpenSearchService {
    private axios = axios.create({
        baseURL: baseUrlOpenSearch,
    });

    public async getOpenSearchFreeText(params?: { searchText?: string }) {
        const queries = {
            ...(params?.searchText && {
                q: params.searchText,
            }),
        }
        try {
            return await this.axios.get<any>(`search-amartahonda`, {
                headers: {
                    "X-Api-Key": "",
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const openSearchService = new OpenSearchService();
