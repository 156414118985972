import React, {Component} from 'react';
import {Link} from "react-router-dom";
import "./css/VariantDetailLayout.css";
import Button from "antd/lib/button";
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';
import WhatsAppOutlined from '@ant-design/icons/WhatsAppOutlined';
import navigatorShare from "../../helper/navigatorShare";
import whatsappLink from "../../helper/whatsappLink";
import Cookies from 'universal-cookie';

const {Content} = Layout;

class VariantDetailLayout extends Component<any> {
    onClickShare(productTitle: any) {
        navigatorShare(productTitle, "Dapatkan penawaran terbaik di amartahonda.com ", window.location.href);
    }

    onClickInfo(productTitle: any) {
        const cookies = new Cookies();
        const token = cookies.get('_ga')?.split('.');
        let gaId = '';
        if (token !== undefined) {
            gaId = (token.length > 2) ? (token[2] + '.' + token[3]) : "";
        }
        whatsappLink('628112341361', `Halo, Saya tertarik dengan ${productTitle} %0a${window.location.href}?clid=${gaId}`);
    }

    render() {
        const windowWidth = window.innerWidth;

        return (
            <Layout className="layout">
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <div className={`variant-header`} style={this.props.hideTop ? {display: 'none'} : {}}>
                        <Link to={this.props.linkBack}><LeftOutlined className={'hedaer2-back'}/></Link>
                        {windowWidth > 1300 && windowWidth < 1501 &&
                            <b className={`header2-title`}>
                                {this.props?.productTitle?.split('', 19).reduce((o: any, c: any) => o.length === 18 ? `${o}${c}...` : `${o}${c}`, '')}
                            </b>
                        }
                        {windowWidth > 300 && windowWidth < 1301 &&
                            <b className={`header2-title`}>
                                {this.props?.productTitle?.split('', 15).reduce((o: any, c: any) => o.length === 14 ? `${o}${c}...` : `${o}${c}`, '')}
                            </b>
                        }
                        {windowWidth > 1500 &&
                            <b className={`header2-title`}>
                                {this.props?.productTitle?.split('', 30).reduce((o: any, c: any) => o.length === 29 ? `${o}${c}...` : `${o}${c}`, '')}
                            </b>
                        }
                        <ShareAltOutlined className={`header2-seacrh`} onClick={() => this.onClickShare(this.props.productTitle)}/>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        {this.props.children}
                    </Content>
                    <br/><br/>
                </Col>
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex:500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                    <Button onClick={() => this.onClickInfo(this.props.productTitle)} icon={<WhatsAppOutlined/>} size={'large'} style={{background: "#64b48f", color: "#fff", width: '10%'}}/>
                    <Link to={`/baru/${this.props.areaCode}/${this.props.productCode.replace('/', '')}/kredit${(this.props.parameterCredit) ? this.props.parameterCredit : ''}`}>
                        <Button danger size={'large'} style={{marginLeft: '2%', width: '42%'}}>Ajukan Kredit</Button>
                    </Link>
                    <Link to={`/baru/${this.props.areaCode}/${this.props.productCode.replace('/', '')}/tunai${(this.props.parameterCash) ? this.props.parameterCash : ''}`}>
                        <Button type="primary" danger size={'large'} style={{marginLeft: '2%', width: '44%'}}> Beli Tunai</Button>
                    </Link>
                </Col>
            </Layout>
        );
    }
}

export default VariantDetailLayout;
