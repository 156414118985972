import React, {Component} from "react";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import {subApiService} from "../../services/subsAndNotifyService/SubApiService";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";

class LiveSubscription extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            showModalBookmarkLogin: false,
            showModalSuccessSubscribe: false,
            processSubsAdd: false,
            amartaVipToken: "",
            bookmarkNotes: "",
        }
        this.state = {...this.initState}
    }

    async onClickNotify() {
        const isExistAmartaVipToken = await authApiService.isExistAmartaVipToken();
        if (!isExistAmartaVipToken) {
            this.setState({
                showModalBookmarkLogin: true,
                bookmarkNotes: "",
            });
        } else {
            await this.promisedSetState({
                processSubsAdd: true,
            });
            await subApiService.createSubscription(isExistAmartaVipToken);
            await this.promisedSetState({
                showModalSuccessSubscribe: true,
                amartaVipToken: isExistAmartaVipToken,
                bookmarkNotes: "",
                processSubsAdd: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>

                <div style={{textAlign: "center"}}>
                    <Button loading={this.state.processSubsAdd} onClick={() => this.onClickNotify()} type={"primary"} style={{textAlign: "center", backgroundColor: "#64b48f", borderColor: "#64b48f"}} size={`large`}>Notify When Live</Button>
                </div>

                <Modal title={"Informasikan Live"}
                       open={this.state.showModalSuccessSubscribe}
                       onCancel={() => {
                           this.setState({showModalSuccessSubscribe: false})
                       }}
                       onOk={() => {
                           this.setState({showModalSuccessSubscribe: false})
                       }}
                       footer={[
                           <Button key="close" onClick={() => {
                               this.setState({showModalSuccessSubscribe: false})
                           }}>Close</Button>
                       ]}
                >
                    Terimakasih telah menekan tombol notify, kami akan memberikan informasi seputar live selanjutnya.<br/><br/>
                </Modal>

                <Modal
                    title={"Informasikan Live"}
                    open={this.state.showModalBookmarkLogin}
                    onCancel={() => {
                        this.setState({showModalBookmarkLogin: false})
                    }}
                    onOk={() => {
                        this.setState({showModalBookmarkLogin: false})
                    }}
                    footer={[
                        <Button key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            window.location.href = "/login?action=notify_live";
                        }}>Login / Daftar AmartaVIP</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalBookmarkLogin: false})
                        }}>Cancel</Button>
                    ]}
                >
                    Anda perlu login terlebih dahulu
                </Modal>
            </React.Fragment>
        );
    }
}

export default LiveSubscription;