import React from "react"
import "./SmallIconMenu.css"

export interface ISmallIconMenu {
    iconSrc: string
    iconText: string
    iconLink: string
}

const SmallIconMenu = (props: ISmallIconMenu) => {
    return (
        <a href={props.iconLink}>
            <div className={`small-logo-image`}>
                <img alt={`imageamarta`} width={'50'} src={props.iconSrc}/>
            </div>
            <p className={`p-home-menu`}>{props.iconText}</p>
        </a>
    )
}

SmallIconMenu.defaultProps = {
    iconSrc: "",
    iconText: "",
    iconLink: "#"
}

export default SmallIconMenu