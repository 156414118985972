import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {detailMokasStates} from "./types/DetailMokasTypes";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import NormalHeader from "../../components/layouts/NormalHeader";
import FooterSection from "../../components/footer/FooterSection";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import navigatorShare from "../../helper/navigatorShare";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import Form from "antd/lib/form";
import Carousel from "antd/lib/carousel";
import Image from "antd/lib/image";
import ShareAltOutlined from "@ant-design/icons/ShareAltOutlined";
import currencyFormat from "../../helper/currencyFormat";
import {Card, Divider} from "antd";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import TextArea from "antd/es/input/TextArea";
import Input from "antd/lib/input";

class DetailMokas extends Component<any, detailMokasStates> {
    private readonly initState!: detailMokasStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        document.title = "Amartahonda | Mokas";

        this.initState = {
            company: "AMARTA",
            areaCode: "",
            uidMokas: match.params.productcode,
            isDesktop: false,
            processingFetchData: false,
            dataUsedVehicle: {},

            showModal: false,
            processSendData: false,
            formNotes: "",
            formName: "",
            formPhoneNumber: "",
        }
        this.state = {...this.initState}
    }

    onClickShare(productTitle: any) {
        navigatorShare(productTitle, "Dapatkan penawaran terbaik di amartahonda.com ", window.location.href);
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
        await this.fetchRequestUsedVehicleData();
    }

    fetchRequestUsedVehicleData = async () => {
        this.setState({
            processingFetchData: true,
        })
        try {
            const detailData = await trimitraCatalogServices.getPublicUsedVehicleMaster({
                company: "amarta",
                state: "active",
                area: this.state.areaCode,
                uid: this.state.uidMokas
            });
            await this.promisedSetState({
                dataUsedVehicle: detailData.data.data[0],
                processingFetchData: false,
                areaCode: detailData.data.data[0].vehicle_city_group
            });
        } catch (e: any) {
            await this.promisedSetState({
                dataUsedVehicle: {},
                processingFetchData: false,
            });
        }
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        document.title = "Amartahonda | Motor Bekas | " + data?.city_group?.toUpperCase();
    }

    onFieldChange = <T extends keyof any>(target: T, value: string) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "formNotes":
                currentState.formNotes = value;
                break;
            case "formName":
                currentState.formName = value;
                break;
            case "formPhoneNumber":
                currentState.formPhoneNumber = value;
                break;
        }

        if (target === 'formPhoneNumber') {
            if (currentState.formPhoneNumber.length > 1) {
                if (currentState.formPhoneNumber.substring(0, 2) !== '08') {
                    currentState.formPhoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    async onClickSubmit() {
        this.setState({
            processSendData: true,
        });

        const dataSend = {
            company: "AMARTA",
            uid: this.state.uidMokas,
            name: this.state.formName,
            phone_number: this.state.formPhoneNumber,
            notes: this.state.formNotes
        }

        try {
            await trimitraCatalogServices.sendMessageUsedVehicle(
                dataSend
            ).then(successData => {
                Modal.success({
                    title: 'Proses Sukses',
                    content: 'data telah kami terima dan akan kami proses. terimakasih telah melakukan input form.'
                });
                setTimeout(function () {
                    window.location.reload();
                }, 3000);
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                processSendData: false,
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        let mileageString: string = "";
        switch (this.state?.dataUsedVehicle?.vehicle_mileage) {
            case 15000:
                mileageString = "0 - 15.000 KM";
                break;
            case 50000:
                mileageString = "15.001 - 50.000 KM";
                break;
            case 100000:
                mileageString = "50.001 - 100.000 KM";
                break;
            case 200000:
                mileageString = "lebih dari 100.000 KM";
                break;
        }

        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>

                <div style={this.state.processingFetchData ? {textAlign: "center", padding: 20, minHeight: 600} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                </div>

                <div style={(!this.state.processingFetchData && !this.state.dataUsedVehicle.vehicle_city_group) ? {minHeight: 600, padding: 50} : {display: 'none'}}>
                    <Empty/>
                </div>

                <div style={!this.state.processingFetchData && this.state.dataUsedVehicle.vehicle_city_group ? {} : {display: 'none'}}>
                    <Row>
                        <Col style={{minHeight: 600}} className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Row>
                                <Col className={`p-20`} xxl={{span: 15}} xl={{span: 15}} lg={{span: 15}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <Carousel autoplay={true} draggable={true} style={{ textAlign: 'center' }}>
                                        {this.state?.dataUsedVehicle?.url_images?.map((item: any, i: number) =>
                                            <div key={i}>
                                                {/*<Image style={{maxHeight: "400px"}} preview={false} alt={`Loading Image...`} width={"100%"} src={item.image_url} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>*/}
                                                <Image style={{maxHeight: "400px"}} preview={false} alt={`Loading Image...`} src={item.image_url} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                                            </div>
                                        )}
                                    </Carousel>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 9}} xl={{span: 9}} lg={{span: 9}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card type="inner" style={{width: "100%"}}>
                                        <div onClick={() => this.onClickShare(this.state?.dataUsedVehicle?.vehicle_caption)} style={{float: "right", marginBottom: 30, marginRight: 20, cursor: "pointer", fontSize: "150%"}}><ShareAltOutlined/> <span style={{fontSize: "80%"}}>Share</span></div>
                                        <br/>
                                        <div className={`pt-20`}/>
                                        <div className={`pt-10 card-promo-caption-100`}>{this.state?.dataUsedVehicle?.vehicle_caption?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                                        <div className={`pt-10 detail-product-title`}>
                                            {this.state?.dataUsedVehicle?.brand_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
                                            &nbsp;
                                            {(this.state?.dataUsedVehicle?.model_name?.toLowerCase() !== "lain-lain") ? this.state?.dataUsedVehicle?.model_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase()) : this.state?.dataUsedVehicle?.model_other_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}
                                        </div>
                                        <div className={`pt-10 card-promo-caption-100`}>{this.state?.dataUsedVehicle?.vehicle_city_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                                        <div className={`pt-10`}>
                                            <span className={`pt-10 detail-product-price-2`}>{currencyFormat(this.state?.dataUsedVehicle?.price)} </span>
                                        </div>
                                        <div className={`pt-20`}>
                                            <Button onClick={() => this.setState({showModal: true})} danger size={'large'} style={{fontSize: "90%", width: '100%'}}>Saya Tertarik Dengan Kendaraan Ini</Button>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={`p-20`} xxl={{span: 24}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <Card type="inner" style={{width: "100%"}}>
                                        <Divider orientation="left">Detail Kendaraan</Divider>
                                        <Row>
                                            <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <div className={`pt-10`}>
                                                    <span style={{paddingRight: 65}}>Merk: </span>
                                                    <span>{this.state?.dataUsedVehicle?.brand_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())} </span>
                                                </div>
                                                <div className={`pt-10`}>
                                                    <span style={{paddingRight: 58}}>Model: </span>
                                                    <span>{(this.state?.dataUsedVehicle?.model_name?.toLowerCase() !== "lain-lain") ? this.state?.dataUsedVehicle?.model_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase()) : this.state?.dataUsedVehicle?.model_other_name?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span>
                                                </div>
                                                <div className={`pt-10`}>
                                                    <span style={{paddingRight: 60}}>Tahun: </span>
                                                    <span>{this.state?.dataUsedVehicle?.vehicle_year}</span>
                                                </div>
                                                <div className={`pt-10`}>
                                                    <span style={{paddingRight: 12}}>Jarak Tempuh: </span>
                                                    <span>{mileageString}</span>
                                                </div>
                                            </Col>
                                            <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <div className={`pt-10`}>
                                                    <span style={{paddingRight: 12}}>BPKB & STNK kendaraan lengkap : </span>
                                                    <span>{(this.state?.dataUsedVehicle?.vehicle_registration_certificate) ? "Ya" : "Tidak"}</span>
                                                </div>
                                                <div className={`pt-10`} style={this.state?.dataUsedVehicle?.active_registration_certificate ? {} : {display: 'none'}}>
                                                    <span style={{paddingRight: 48}}>BPKB dan STNK masih aktif : </span>
                                                    <span>{(this.state?.dataUsedVehicle?.active_registration_certificate) ? "Ya" : "Tidak"}</span>
                                                </div>
                                                <div className={`pt-10`} style={this.state?.dataUsedVehicle?.vehicle_ownership_certificate ? {} : {display: 'none'}}>
                                                    <span style={{paddingRight: 50}}>Nama Pemilik Sesuai STNK : </span>
                                                    <span>{(this.state?.dataUsedVehicle?.vehicle_ownership_certificate) ? "Ya" : "Tidak"}</span>
                                                </div>
                                            </Col>
                                        </Row><br/>
                                        <Divider orientation="left">Deskripsi Kendaraan</Divider>
                                        <Row>
                                            <Col xxl={{span: 24}} xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <div className={`pt-10`}>
                                                    {this.state?.dataUsedVehicle?.vehicle_caption}
                                                </div>
                                                <div className={`pt-10`}>
                                                    {this.state?.dataUsedVehicle?.vehicle_description}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <FooterSection areaCode={this.state.areaCode}/>

                <Modal
                    title={"Kirim Pesan ke penjual"}
                    open={this.state.showModal}
                    onCancel={() => {
                        this.setState({showModal: false})
                    }}
                    onOk={() => {
                        this.setState({showModal: false})
                    }}
                    footer={[
                        <Button loading={this.state.processSendData} key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            this.onClickSubmit()
                        }}>Kirim Pesan</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModal: false})
                        }}>Cancel</Button>
                    ]}
                >

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nama Anda <b style={{color: "red"}}>*</b></i>
                        <Input
                            value={this.state.formName}
                            placeholder="Nama Anda"
                            onChange={event => this.onFieldChange("formName", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor Whatsapp <b style={{color: "red"}}>*</b></i>
                        <Input
                            value={this.state.formPhoneNumber}
                            maxLength={17}
                            placeholder="Format Telpon: 08xxxxxxxx"
                            onChange={event => this.onFieldChange("formPhoneNumber", event.target.value)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Catatan</i>
                        <TextArea showCount maxLength={200} value={this.state.formNotes} onChange={event => this.onFieldChange("formNotes", event.target.value)} placeholder="Catatan jika dibutuhkan"/><br/>
                    </Form.Item>
                </Modal>
            </React.Fragment>
        );
    }
}

export default withRouter(DetailMokas);