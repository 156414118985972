import React, {Component} from "react";
import {NewVehicleVariantStates} from "./types/NewVehicleVariantTypes";
import {Link} from "react-router-dom";
import {withRouter} from "../../hoc/withRouter";
import './NewVehicleVariant.css'
import {Content} from "antd/es/layout/layout";
import {DivClassProduct} from "../../components/product/DivClassProduct";
import {CardProduct} from "../../components/product/CardProduct";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {openSearchService} from "../../services/openSearch/OpenSearchService";
import CurrencyInput from "react-currency-input-field";
import dynamicSort from "../../helper/dynamicSort";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Drawer from "antd/lib/drawer";
import Layout from "antd/lib/layout";
import Select from "antd/lib/select";
import Slider from "antd/lib/slider";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import ControlOutlined from '@ant-design/icons/ControlOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {trackLogServices} from "../../services/track/TrackLogService";
import Search from "antd/es/input/Search";
import message from "antd/lib/message";
import FooterSection from "../../components/footer/FooterSection";

const marks = {20000000: '20jt', 50000000: '50jt', 75000000: '75jt'}

class NewVehicleVariant extends Component<any, NewVehicleVariantStates> {
    private readonly initState!: NewVehicleVariantStates;

    constructor(props: any) {
        super(props);

        const {match} = this.props;
        const areaCode = match.params.area;
        let catalogCode: any = match.params.param ?? "Semua";

        if (catalogCode === 'model') {
            catalogCode = 'Semua';
        }

        document.title = `Amartahonda | Variant Kendaraan Baru | ${areaCode} | ${catalogCode}`;

        this.initState = {
            listCityGroup: [],
            areaCode: areaCode,
            newProductClass: "ALL",
            listNewProduct: [],
            listNewProductFiltered: [],
            showOfferCard: false,
            complateFetchVariant: false,
            visibleFilter: false,
            filterMinPrice: 0,
            filterMaxPrice: 150000000,
            filterSorting: "relevan",
            listModel: [],
            modelSelected: "Semua",
            listCatalog: [],
            dataCatalog: {},
            catalogSelected: catalogCode,
            fetchStock: true,
            stockData: {},
            searchText: "",
        }
        this.state = {...this.initState}
    }

    onClassChange = async (data: string) => {
        await this.promisedSetState({
            newProductClass: data
        });
        await this.setupListNewProductFiltered();
    }

    onChangeStockSource = async (data: string) => {
        await this.fetchJsonStock(data?.toLowerCase());
    }

    isInsideCatalog = async (catCode: string, listCatVariant: any, listCatModel: any, model: string, variant: string) => {
        if (catCode === 'Semua') {
            return true;
        } else {
            if (listCatVariant.includes(variant)) {
                return true
            } else {
                return !!listCatModel.includes(model);
            }
        }
    }

    setupListNewProductFiltered = async () => {
        let filteredData: any[] = [];
        const filterMinPrice = (this.state.filterMinPrice === 0) ? 0 : this.state.filterMinPrice;
        const filterMaxPrice = (this.state.filterMaxPrice === 0) ? 99999999999 : this.state.filterMaxPrice;
        const filterSorting = this.state.filterSorting;

        // catalog check
        const f_catalog = this.state.dataCatalog[this.state.catalogSelected] ?? [];
        const listCatVariant: any = [];
        const listCatModel: any = [];
        for (const x of f_catalog) {
            if (listCatVariant.indexOf(x.variant) === -1) {
                listCatVariant.push(x.variant);
            }
            if (listCatModel.indexOf(x.model) === -1) {
                if (x.variant === null) {
                    listCatModel.push(x.model);
                }
            }
        }
        //end catalog check

        for (const el of this.state.listNewProduct) {
            if (this.state.newProductClass === 'ALL') {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    if (this.state.modelSelected === 'Semua') {
                        const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                        if (respCheck) {
                            filteredData.push(el);
                        }
                    } else {
                        if (this.state.modelSelected === el.model_name) {
                            const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                            if (respCheck) {
                                filteredData.push(el);
                            }
                        }
                    }
                }
            } else if ((el.class === "scooter" || el.class === "scooter-high") && (this.state.newProductClass === 'MATIC')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    if (this.state.modelSelected === 'Semua') {
                        const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                        if (respCheck) {
                            filteredData.push(el);
                        }
                    } else {
                        if (this.state.modelSelected === el.model_name) {
                            const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                            if (respCheck) {
                                filteredData.push(el);
                            }
                        }
                    }
                }
            } else if ((el.class === "cub") && (this.state.newProductClass === 'CUB')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    if (this.state.modelSelected === 'Semua') {
                        const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                        if (respCheck) {
                            filteredData.push(el);
                        }
                    } else {
                        if (this.state.modelSelected === el.model_name) {
                            const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                            if (respCheck) {
                                filteredData.push(el);
                            }
                        }
                    }
                }
            } else if ((el.class === "sport" || el.class === "sport-low") && (this.state.newProductClass === 'SPORT')) {
                if (el.price > filterMinPrice && el.price < filterMaxPrice) {
                    if (this.state.modelSelected === 'Semua') {
                        const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                        if (respCheck) {
                            filteredData.push(el);
                        }
                    } else {
                        if (this.state.modelSelected === el.model_name) {
                            const respCheck = await this.isInsideCatalog(this.state.catalogSelected, listCatVariant, listCatModel, el.model_name, el.variant_code)
                            if (respCheck) {
                                filteredData.push(el);
                            }
                        }
                    }
                }
            }
        }

        if (filterSorting === 'minprice') {
            filteredData.sort(dynamicSort("price"));
        }
        if (filterSorting === 'maxprice') {
            filteredData.sort(dynamicSort("-price"));
        }

        await this.promisedSetState({
            listNewProductFiltered: filteredData,
            // visibleFilter: false,
            searchText: "",
        });
    }

    showFilterDrawer = () => {
        this.setState({
            visibleFilter: true,
        })
    }

    searchFreeText = async () => {
        await this.promisedSetState({
            complateFetchVariant: false,
            listNewProductFiltered: []
        });

        let filteredData: any[] = [];
        await openSearchService.getOpenSearchFreeText({
            searchText: this.state.searchText,
        }).then(async dataFetchStock => {
            for (const x of dataFetchStock?.data?.data?.catalogue) {
                if (x?.active) {
                    filteredData.push({
                        entity_type: "catalog",
                        url_image: x?.url_image,
                        variant_code: x?.code,
                        variant_name: x?.name,
                        price_min: 1111,
                        price_strikethrough: 0,
                    });
                }
            }

            for (const x of dataFetchStock?.data?.data?.variant) {
                const variantData = this.state.listNewProduct.find((o: { variant_code: string; }) => o.variant_code === x?.variant_code);
                filteredData.push(variantData);
            }

            await this.promisedSetState({
                listNewProductFiltered: filteredData,
                // visibleFilter: false,
                complateFetchVariant: true,
            });
        }).catch(async e => {
            message.error('error, mohon gunakan filter pada pojok kanan atas');
            await this.promisedSetState({
                listNewProductFiltered: filteredData,
                // visibleFilter: false,
                complateFetchVariant: true,
            });
        });
    }

    closeFilterDrawer = () => {
        this.setState({
            visibleFilter: false,
        })
    }

    onChangeSlider = (value: any) => {
        this.setState({
            filterMinPrice: value[0],
            filterMaxPrice: value[1]
        });
    }

    onFieldChange = <T extends keyof Pick<any, "areaCode" | "filterMinPrice" | "filterMaxPrice" | "searchText" | "catalogSelected" | "modelSelected" | "filterSorting">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "areaCode":
                currentState.areaCode = value;
                break;
            case "searchText":
                currentState.searchText = value;
                break;
            case "filterMinPrice":
                currentState.filterMinPrice = value;
                break;
            case "filterMaxPrice":
                currentState.filterMaxPrice = value;
                break;
            case "catalogSelected":
                currentState.catalogSelected = value;
                break;
            case "modelSelected":
                currentState.modelSelected = value;
                break;
            case "filterSorting":
                currentState.filterSorting = value;
                break
        }

        this.setState({
            ...currentState,
        });

        if (target === "areaCode") {
            this.setState({
                fetchStock: true,
                complateFetchVariant: false,
                listNewProductFiltered: [],
            });
            this.props.history.push(`/baru/${value?.toLowerCase()}/variant`);
            this.fetchData();
        }

    }

    fetchJsonStock = async (area: string) => {
        trimitraCatalogServices.getStock({
            areaCode: area,
        }).then(async dataFetchStock => {
            await this.promisedSetState({
                stockData: dataFetchStock?.data,
                fetchStock: false,
            });
        }).catch(e => {
            this.promisedSetState({
                stockData: {},
                fetchStock: false,
            });
            console.log(e);
        });
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        try {
            const listCityGroup = await trimitraCatalogServices.getCityGroup({});
            await this.promisedSetState({
                listCityGroup: listCityGroup.data.data
            });
        } catch (e) {
            console.log(e);
        }

        await this.fetchData();
    }

    async fetchData() {
        const queryParams = new URLSearchParams(window.location.search);
        const searchParam = queryParams.get('search');

        trackLogServices.sendTrackLog({
            eventName: `vehicle variant page`,
            eventSourceUrl: window.location.href,
            customData: null
        });

        await trimitraCatalogServices.getCatalogMaster({
            cityGroup: this.state.areaCode,
            active: true,
            destinationLink: 'variant'
        }).then(async fetchCatalog => {
            const listCatalog = [];
            const dataCatalog: any = {};
            for (const item of fetchCatalog.data.data) {
                listCatalog.push({
                    code: item.code,
                    name: item.name,
                });
                dataCatalog[item.code] = item.list_product;
            }
            await this.promisedSetState({
                listCatalog: listCatalog,
                dataCatalog: dataCatalog
            });
        });

        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.state.areaCode
        }).then(async dataVariantHome => {
            const listModel = [];
            for (const item of dataVariantHome.data.data) {
                if (listModel.indexOf(item.model_name) === -1) {
                    listModel.push(item.model_name);
                }
            }

            await this.promisedSetState({
                listNewProduct: dataVariantHome.data.data,
                listModel: listModel,
                complateFetchVariant: true,
            });

            if (!searchParam) {
                await this.setupListNewProductFiltered();
            }
        });

        if (searchParam) {
            await this.promisedSetState({
                searchText: searchParam,
            });
            await this.searchFreeText();
        }

        await this.fetchJsonStock("all");
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Layout className="layout-new-vehicle-variant" style={{backgroundColor: "#fff"}}>
                <Col xl={{span: 16, offset: 4}} lg={{span: 16, offset: 4}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{minHeight: "70vh"}}>
                    <div className={`variant-header-new-vehicle-variant`}>
                        <Row>
                            <Col xl={{span: 2}} lg={{span: 2}} md={{span: 2}} sm={{span: 4}} xs={{span: 4}}><Link to="/"><LeftOutlined className={'hedaer2-back-new-vehicle-variant'}/></Link></Col>
                            <Col xl={{span: 20}} lg={{span: 20}} md={{span: 20}} sm={{span: 16}} xs={{span: 16}}>
                                <Search style={{marginTop: 15}} value={this.state.searchText} onChange={event => this.onFieldChange("searchText", event.target.value)} placeholder="Temukan Motor, Katalog dan lainya" onSearch={this.searchFreeText}/>
                            </Col>
                            <Col xl={{span: 2}} lg={{span: 2}} md={{span: 2}} sm={{span: 4}} xs={{span: 4}}><ControlOutlined onClick={this.showFilterDrawer} className={`header2-control-new-vehicle-variant`}/></Col>
                        </Row>
                    </div>
                    <div className={`variant-header-new-vehicle-variant-border-bottom`}>
                        <DivClassProduct areaCode={this.state.areaCode} newProductClass={this.state.newProductClass} onClickClass={this.onClassChange} onChangeStockSource={this.onChangeStockSource}/>
                    </div>
                    <Content style={{backgroundColor: "white"}}>
                        <div style={this.state.complateFetchVariant ? {display: 'none'} : {textAlign: "center", padding: 20, minHeight: "70vh"}}>
                            <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                        </div>

                        <div style={(!this.state.complateFetchVariant) ? {display: 'none'} : {}}>
                            <div style={(this.state.listNewProductFiltered.length === 0) ? {minHeight: "70vh"} : {display: 'none'}}>
                                <br/>
                                <Empty style={{padding: 25}} description={"Data Tidak Ditemukan. \nsilakan gunakan filter pada pojok kanan atas, untuk mencari data"}/>
                            </div>
                        </div>

                        <Row className={`home-row-chosen-product`}>
                            {this.state.listNewProductFiltered.map((item: any, i: number) =>
                                <Col xl={{span: 6}} lg={{span: 6}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i} style={(!item?.variant_code) ? {display: 'none'} : {}}>
                                    <CardProduct
                                        option={(item?.entity_type === "catalog") ? 'CATALOG' : 'NEW_VARIANT'}
                                        fetchStock={this.state.fetchStock}
                                        stock={this.state.stockData?.data?.[item?.variant_code] ?? {stock_qty: 0}}
                                        stockLimit={this.state.stockData?.meta?.availability_limit ?? 0}
                                        productImageUrl={item?.url_image}
                                        product={item?.variant_code}
                                        productArea={this.state.areaCode}
                                        productName={item?.variant_name}
                                        productPrice={item?.price_min}
                                        productStrikethroughtPrice={item?.price_strikethrough}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Content>
                </Col>

                <FooterSection areaCode={this.state.areaCode}/>

                <Drawer
                    title="Filter"
                    placement="right"
                    open={this.state.visibleFilter}
                    onClose={this.closeFilterDrawer}
                >
                    <span className={`text-small-grey`}>Area:</span>
                    <Select onChange={value => this.onFieldChange("areaCode", value)} showSearch={true} style={{width: '100%'}} value={this.state.areaCode}>
                        {this.state.listCityGroup.map((item: any, i: number) =>
                            <Select.Option key={i} value={item.city_group?.toLowerCase()}>{item?.city_group.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</Select.Option>
                        )}
                    </Select>
                    <br/><br/>

                    <span className={`text-small-grey`}>Katalog Kendaraan:</span>
                    <Select onChange={value => this.onFieldChange("catalogSelected", value)} defaultValue={this.state.catalogSelected} style={{width: '100%'}}>
                        <Select.Option value="Semua">Semua</Select.Option>
                        {this.state.listCatalog.map((item: any, i: number) =>
                            <Select.Option key={i} value={item.code}>{item.name}</Select.Option>
                        )}
                    </Select>
                    <br/><br/>

                    <span className={`text-small-grey`}>Model Kendaraan:</span>
                    <Select onChange={value => this.onFieldChange("modelSelected", value)} defaultValue={this.state.modelSelected} style={{width: '100%'}}>
                        <Select.Option value="Semua">Semua</Select.Option>
                        {this.state.listModel.map((item: any, i: number) =>
                            <Select.Option key={i} value={item}>{item}</Select.Option>
                        )}
                    </Select>
                    <br/><br/>

                    <span className={`text-small-grey`}>Harga Minimum</span>
                    <CurrencyInput
                        className={'ant-input'}
                        placeholder={'Harga Minimum'}
                        allowDecimals={false}
                        groupSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp. '}
                        value={this.state.filterMinPrice}
                        onValueChange={value => this.onFieldChange("filterMinPrice", value ? parseFloat(value) : 0)}
                    />
                    <br/><br/>

                    <span className={`text-small-grey`}>Harga Maksimum</span>
                    <CurrencyInput
                        className={'ant-input'}
                        placeholder={'Harga Maksimum'}
                        allowDecimals={false}
                        groupSeparator={'.'}
                        decimalSeparator={','}
                        prefix={'Rp. '}
                        value={this.state.filterMaxPrice}
                        onValueChange={value => this.onFieldChange("filterMaxPrice", value ? parseFloat(value) : 0)}
                    />
                    <br/><br/>

                    <span className={`text-small-grey`}>Range Harga</span>
                    <Slider
                        min={0}
                        max={150000000}
                        // step={200000}
                        marks={marks}
                        range defaultValue={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                        value={[this.state.filterMinPrice, this.state.filterMaxPrice]}
                        onChange={this.onChangeSlider}
                    />
                    <br/>

                    <span className={`text-small-grey`}>Urutkan Berdasarkan:</span>
                    <Select onChange={value => this.onFieldChange("filterSorting", value)} defaultValue={this.state.filterSorting} style={{width: '100%'}}>
                        <Select.Option value="relevan">Relevan</Select.Option>
                        <Select.Option value="minprice">Harga Terendah</Select.Option>
                        <Select.Option value="maxprice">Harga Tertinggi</Select.Option>
                    </Select>

                    <div className="custom-footer">
                        <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={this.setupListNewProductFiltered} type="primary" icon={<SearchOutlined/>}>Terapkan Filter</Button>
                    </div>
                </Drawer>
            </Layout>
        );
    }
}

export default withRouter(NewVehicleVariant);
