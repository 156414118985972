import axios from "axios";
import {baseUrlBooking} from "../../config/apiConfig/apiConfig";

class BookingApiServices {
    private axios = axios.create({
        baseURL: baseUrlBooking,
    });

    public async createBooking(inputData?: any) {
        try {
            return await this.axios.post<any>('/appointment',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": "-"
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const bookingApiServices = new BookingApiServices();
