import React, {Component} from 'react';
import currencyFormat from "../../helper/currencyFormat";
import Col from "antd/lib/col";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Tooltip from "antd/lib/tooltip";
import Typography from "antd/lib/typography";
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import Alert from "antd/lib/alert";
import Divider from "antd/lib/divider";
import withAuth from "../../hoc/withAuth";
import Cookies from 'universal-cookie';
import Button from "antd/lib/button";
import Card from "antd/lib/card";

class SpkInformation2 extends Component<any & any, any> {
    private cookies = new Cookies();

    constructor(props: any & any) {
        super(props);

        this.state = {
            loading: false,
        }
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor SPK berhasil dicopy');
    }

    render() {
        const ownerAddress = this.props.dataSpk?.address_owner?.full_address ?? "";
        const shippingAddress = this.props.dataSpk?.address_shipping?.full_address ?? "";

        return (
            <React.Fragment>
                <div style={!this.props.spkInOffer ? {} : {display: 'none'}}>
                    <Alert style={this.props.b2bStatus === "ON_PROCESS" ? {} : {display: 'none'}} message="SPK dalam proses pengajuan pihak leasing" type="warning" showIcon/>
                    <Alert style={this.props.b2bStatus === "REJECTED_BY_ADMIN_LEASING" ? {} : {display: 'none'}} message="SPK ditolak oleh pihak leasing" type="warning" showIcon/>
                    <Alert style={this.props.b2bStatus === "REJECETED_BY_ADMIN_DEALER" ? {} : {display: 'none'}} message="SPK ditolak oleh pihak dealer" type="warning" showIcon/> <br/>
                </div>
                <div style={this.props.dataSpk?.cancel_order_time ? {} : {display: 'none'}}>
                    <Alert message="SPK Telah Dibatalkan, silakan hubungi admin untuk info lebih lanjut" type="warning" showIcon/> <br/>
                </div>
                <div style={this.props.dataSpk?.order_code ? {} : {display: 'none'}}>
                    <Alert message="SPK Berhasil masuk fase order" type="info" showIcon/> <br/>
                </div>
                <div style={this.props.dataSpk?.offer_status === "OPEN" ? {} : {display: 'none'}}>
                    <Alert message="SPK sedang diproses" type="info" showIcon/> <br/>
                </div>

                <Row>
                    <Col xxl={{span: 16}} xl={{span: 16}} lg={{span: 16}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <i className={`detail-product-caption`}>Nomor SPK:</i><br/>
                        <span style={{fontSize: "120%", fontWeight: 500}}>{this.props.codeSpk}</span>
                        <Tooltip title="Click untuk copy nomor SPK" color={'cyan'}><CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.props.codeSpk)}/></Tooltip>
                        <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Kendaraan</div>
                        <Typography.Text type="secondary" style={{paddingRight: 86}}> Merk: </Typography.Text> HONDA <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 79}}> Model: </Typography.Text> {this.props.dataSpk?.vehicle?.model_name?.toUpperCase()} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 76}}> Variant: </Typography.Text> {this.props.dataSpk?.vehicle?.variant_name?.toUpperCase()} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 40}}> Kode Variant: </Typography.Text> {this.props.dataSpk?.vehicle?.variant_code} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 78}}> Warna: </Typography.Text> {this.props.dataSpk?.vehicle?.color_code} ({this.props.dataSpk?.vehicle?.color_name}) <br/>

                        <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Kepemilikan</div>
                        <Typography.Text type="secondary" style={{paddingRight: 82}}> Nama: </Typography.Text> {this.props.dataSpk?.id_card_owner?.full_name} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 47}}> Nomor KTP: </Typography.Text> {this.props.dataSpk?.id_card_owner?.id_card_number} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 54}}> Nomor KK: </Typography.Text> {this.props.dataSpk?.family_register_owner?.family_register_number} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 30}}> Nomor Telpon: </Typography.Text> {this.props.dataSpk?.contact?.phone_number_owner} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 76}}> Alamat: </Typography.Text> {ownerAddress} <br/>
                        <Typography.Text type="secondary" style={{paddingRight: 118}}> &nbsp; </Typography.Text> {this.props.dataSpk?.address_owner?.city_name?.toUpperCase()} - {this.props.dataSpk?.address_owner?.district_name?.toUpperCase()}, {this.props.dataSpk?.address_owner?.province_name?.toUpperCase()} {this.props.dataSpk?.address_owner?.zip_code} <br/>

                        <div style={{fontSize: "120%", fontWeight: 480, paddingTop: 40, paddingBottom: 10}}>Alamat Pengiriman</div>

                        <Row style={(!this.props.dataSpk?.take_vehicle_in_dealer) ? {} : {display: 'none'}}>
                            <Col span={24}>
                                {(shippingAddress) ? shippingAddress : ownerAddress}<br/>
                                {(this.props.dataSpk?.address_shipping?.city_name?.toUpperCase()) ? this.props.dataSpk?.address_shipping?.city_name?.toUpperCase() : this.props.dataSpk?.address_owner?.city_name?.toUpperCase()} -
                                {(this.props.dataSpk?.address_shipping?.district_name?.toUpperCase()) ? this.props.dataSpk?.address_shipping?.district_name?.toUpperCase() : this.props.dataSpk?.address_owner?.district_name?.toUpperCase()}, &nbsp;
                                {(this.props.dataSpk?.address_shipping?.province_name?.toUpperCase()) ? this.props.dataSpk?.address_shipping?.province_name?.toUpperCase() : this.props.dataSpk?.address_owner?.province_name?.toUpperCase()} &nbsp;
                                {(this.props.dataSpk?.address_shipping?.zip_code) ? this.props.dataSpk?.address_shipping?.zip_code : this.props.dataSpk?.address_owner?.zip_code}
                            </Col>
                        </Row>
                        <Row style={this.props.dataSpk?.take_vehicle_in_dealer ? {} : {display: 'none'}}>
                            <Col span={24}>
                                Diambil di delaer Amartahonda area {this.props.dataSpk?.area?.toUpperCase()}
                            </Col>
                        </Row>
                    </Col>
                    <Col xxl={{span: 8}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{padding: 10, backgroundColor: "#f5f5f5", marginTop: 40}}>
                            <div style={{fontSize: "120%", fontWeight: 480, paddingBottom: 10}}>Resume Tagihan</div>
                            <Typography.Text type="secondary" style={{paddingRight: 57}}> Metode: </Typography.Text> {(this.props.dataSpk?.purchase_method === "cash") ? "Tunai" : "Kredit"} <br/>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 58}}> Leasing: </Typography.Text> {this.props.dataSpk?.credit?.finco_name?.toUpperCase()} <br/></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 57}}> Finco Id: </Typography.Text> {this.props.dataSpk?.credit?.finco_id?.toUpperCase()} <br/></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 33}}> Nama CMO: </Typography.Text> {this.props.dataSpk?.survey_data?.admin_leasing_target?.full_name ?? "-"} <br/></span>

                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 46}}> DP Kredit: </Typography.Text> {(this.props.dataSpk?.survey_data?.credit_scheme?.down_payment) ? currencyFormat(this.props.dataSpk?.survey_data?.credit_scheme?.down_payment) : currencyFormat(this.props.dataSpk?.credit?.dp_amount)} <br/></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 70}}> Tenor: </Typography.Text> {(this.props.dataSpk?.survey_data?.credit_scheme?.tenor) ? this.props.dataSpk?.survey_data?.credit_scheme?.tenor : this.props.dataSpk?.credit?.tenor} <br/></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Typography.Text type="secondary" style={{paddingRight: 65}}> Cicilan: </Typography.Text> {(this.props.dataSpk?.survey_data?.credit_scheme?.installment) ? currencyFormat(this.props.dataSpk?.survey_data?.credit_scheme?.installment) : currencyFormat(this.props.dataSpk?.credit?.installment_amount)} <br/></span>
                            <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}><Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider></span>

                            <Typography.Text type="secondary" style={{paddingRight: 79}}> OTR: </Typography.Text> {currencyFormat(this.props.dataSpk?.price_and_bill?.otr)} <br/>

                            <div style={this.props.spkInOffer ? {} : {display: 'none'}}>
                                <Typography.Text type="secondary" style={{paddingRight: 63}}> Diskon: </Typography.Text> {currencyFormat(this.props.dataSpk?.price_and_bill?.promo_discount)} <br/>
                                <Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider>
                                <Typography.Text type="secondary" style={{paddingRight: 33}}> Biaya Order: </Typography.Text> {currencyFormat(this.props.dataSpk?.price_and_bill?.order_fee)} <br/>
                                <Divider style={{marginTop: 7, marginBottom: 7}} plain></Divider>
                                <Typography.Text type="secondary" style={{paddingRight: 14}}> <b>Total Tagihan <span style={this.props.dataSpk?.purchase_method === 'credit' ? {} : {display: 'none'}}> DP </span>:</b> </Typography.Text> {currencyFormat(this.props.dataSpk?.price_and_bill?.total_bill)} <br/>
                            </div>
                        </div>

                        <Card size={'small'} style={this.props.spkInOffer ? {marginTop: 20} : {display: 'none'}}>
                            <div style={this.props.dataSpk?.price_and_bill?.bill_code ? {} : {display: 'none'}}>
                                <Typography.Text type="secondary">Lihat detail pembayaran SPK</Typography.Text>
                                <div style={{padding: 5}}/>
                                <a rel="noreferrer" target={'_blank'} href={`/spk/${this.props.dataSpk?.transaction_code}`}>
                                    <Button type="primary" style={{borderRadius: 12}}>Detail Pembayaran</Button>
                                </a>
                            </div>
                        </Card>

                        <Card size={'small'} style={this.props.spkInOffer ? {marginTop: 20} : {display: 'none'}}>
                            <div style={this.props.dataSpk?.price_and_bill?.bill_code ? {} : {display: 'none'}}>
                                <Typography.Text type="secondary">Download PDF SPK</Typography.Text>
                                <div style={{padding: 5}}/>
                                <a href={`https://asia-southeast1-autotrimitra.cloudfunctions.net/api-offer-otodis--create-pdf/spk/generate-pdf?company=amarta&spk_code=${this.props.dataSpk?.transaction_code}&token=asc8asdasaskjdhas87asdasd&force_download=true`} target={'_blank'} rel="noreferrer">
                                    <Button type="primary" style={{borderRadius: 12}}>Download PDF</Button>
                                </a>
                            </div>
                        </Card>

                        <div style={this.props.dataSpk?.offer_status === "OPEN" ? {} : {display: 'none'}}>
                            <Card size={'small'} style={this.props.spkInOffer ? {marginTop: 20} : {display: 'none'}}>
                                <Typography.Text type="secondary">Update SPK (Data Kepemilikan) </Typography.Text>
                                <div style={{padding: 5}}/>
                                <a rel="noreferrer" target={'_blank'} href={`/update-spk/${this.props.dataSpk?.transaction_code}`}>
                                    <Button type="primary" style={{borderRadius: 12}}>Update SPK</Button>
                                </a>
                            </Card>
                        </div>

                        <div style={!this.props.spkInOffer ? {marginTop: 20} : {display: 'none'}}>
                            <div style={this.props.userType === "internal" ? {} : {display: 'none'}}>
                                <div style={this.props.b2bStatus === "ON_PROCESS" ? {} : {display: 'none'}}>
                                    <Card size={'small'}>
                                        <Typography.Text type="secondary">Fitur ini dapat digunakan oleh pihak internal (agen marketing) untuk melakukan push data credit agar dapat dilakukan proses invoice</Typography.Text>
                                        <div style={{padding: 5}}/>
                                        <a href={`/push-offer/${this.props.dataSpk?.transaction_code}`}>
                                            <Button type="primary" style={{borderRadius: 12}}>Push Offer</Button>
                                        </a>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

}

export default withAuth(SpkInformation2);
