import axios from "axios";
import {AutotrimitraBaseResponse} from "./types/autotrimitrayServiceTypes";
import {baseUrlAutotrimitra} from "../../config/apiConfig/apiConfig";

class VehicleServices {
    private axios = axios.create({
        baseURL: baseUrlAutotrimitra,
    });
    private key = "AIzaSyAFTemJSnp4h16lYQfITqLD8Ryp9fGNsVg";

    public async getVehicleBrand(params?: { brandName?: string }) {
        const queries = {
            ...(params?.brandName && {
                brand_name: params.brandName,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/vehicle/brand', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getVehicleModel(params?: { brandUuid?: string, class?: string, category?: string, modelUuid?: string }) {
        const queries = {
            ...(params?.brandUuid && {
                brand_uuid: params.brandUuid,
            }),
            ...(params?.class && {
                class: params.class,
            }),
            ...(params?.category && {
                category: params.category,
            }),
            ...(params?.modelUuid && {
                model_uuid: params.modelUuid,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/vehicle/model', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getVehicleVariant(params?: { brandUuid?: string, modelUuid?: string, variantUuid?: string, code?: string }) {
        const queries = {
            ...(params?.brandUuid && {
                brand_uuid: params.brandUuid,
            }),
            ...(params?.modelUuid && {
                model_uuid: params.modelUuid,
            }),
            ...(params?.variantUuid && {
                variant_uuid: params.variantUuid,
            }),
            ...(params?.code && {
                code: params.code,
            }),
        }
        try {
            return await this.axios.get<AutotrimitraBaseResponse>('/vehicle/variant', {
                params: {
                    key: this.key,
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const vehicleServices = new VehicleServices();
