import React, {Component} from "react";
import SmallIconMenu from "../iconMenu/SmallIconMenu";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import {Divider} from "antd";
import {HistoryOutlined} from "@ant-design/icons";

class HomeMenu extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            showModalMenu: false,
        }
        this.state = {...this.initState}
    }

    showMenu = () => {
        this.setState({
            showModalMenu: true,
        })
    }

    searchFreeText = async (searchText: string) => {
        window.location.href = `/baru/${this.props.areaCode.toLowerCase()}/variant?search=${searchText?.trim()}`;
    }

    render() {
        return (
            <>
                <Row>
                    {this.props.homeMenu.map((item: any, i: number) =>
                        (
                            item?.display_on_homepage === true ? (
                                <Col xxl={{span: 3}} xl={{span: 3}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} key={i}>
                                    <SmallIconMenu iconSrc={item.url} iconText={item.caption} iconLink={item.target_link.replace('{area}', this.props.areaCode).toLowerCase()}/>
                                </Col>
                            ) : null
                        )
                    )}
                    <Col xxl={{span: 3}} xl={{span: 3}} md={{span: 6}} sm={{span: 6}} xs={{span: 6}} key={'i'}>
                        <div className={`small-logo-image`} style={{cursor: "pointer"}} onClick={this.showMenu}>
                            <img alt={`imageamarta`} width={'50'} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/amarta/home/menu/show_all_menu.svg`}/>
                        </div>
                        <p className={`p-home-menu`}>Lihat Semua</p>
                    </Col>
                </Row>
                <Modal
                    title={"Semua Menu"}
                    open={this.state.showModalMenu}
                    onCancel={() => {
                        this.setState({showModalMenu: false})
                    }}
                    onOk={() => {
                        this.setState({showModalMenu: false})
                    }}
                    footer={[
                        <Button key="close" onClick={() => {
                            this.setState({showModalMenu: false})
                        }}>Close</Button>
                    ]}
                >
                    <Row>
                        {this.props.homeMenu.map((item: any, i: number) =>
                            <Col span={6} key={i}>
                                <SmallIconMenu iconSrc={item.url} iconText={item.caption} iconLink={item.target_link.replace('{area}', this.props.areaCode).toLowerCase()}/>
                            </Col>
                        )}
                    </Row>
                    <Divider/>
                    <span style={{color: "#b0b0b0", marginTop: 10}}>PENCARIAN POPULER:</span>
                    <div style={{lineHeight: 0}}>
                        <div onClick={() => this.searchFreeText("Honda Beat")} className={`search-result`}><HistoryOutlined/> Honda Beat</div>
                        <div onClick={() => this.searchFreeText("Stylo")} className={`search-result`}><HistoryOutlined/> Stylo</div>
                        <div onClick={() => this.searchFreeText("Em1")} className={`search-result`}><HistoryOutlined/> Em1</div>
                        <div onClick={() => this.searchFreeText("Honda 20 jutaan")} className={`search-result`}><HistoryOutlined/> Honda 20 jutaan</div>
                        <div onClick={() => this.searchFreeText("PCX")} className={`search-result`}><HistoryOutlined/> PCX</div>
                        <div onClick={() => this.searchFreeText("Scoopy")} className={`search-result`}><HistoryOutlined/> Scoopy</div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default HomeMenu;
