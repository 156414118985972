import axios from "axios";
import {baseUrlCreditSimulator, xApiKeyCreditSimulator} from "../../config/apiConfig/apiConfig";

class SimulationCreditService {
    private axios = axios.create({
        baseURL: baseUrlCreditSimulator,
    });

    public async getEffectiveRate(params?: { price?: number, downPayment?: number, insurance?: number, payment?: number, rate?: number, tenor?: number }) {
        const queries = {
            ...(params?.price && {
                price: params.price,
            }),
            ...(params?.downPayment && {
                down_payment: params.downPayment,
            }),
            ...(params?.insurance && {
                insurance: params.insurance,
            }),
            ...(params?.payment && {
                payment: params.payment,
            }),
            ...(params?.rate && {
                rate: params.rate,
            }),
            ...(params?.tenor && {
                tenor: params.tenor,
            }),
        }
        try {
            return await this.axios.get<any>(`credit-simulation/effective-rate`, {
                headers: {
                    "X-Api-Key": xApiKeyCreditSimulator,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const simulationCreditService = new SimulationCreditService();
