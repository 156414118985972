import React, {Component} from "react";
import './FooterSection.css'
import {Row} from "antd";
import Col from "antd/lib/col";
import {FacebookOutlined, YoutubeOutlined, InstagramOutlined, EnvironmentOutlined, PhoneOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import TwitterOutlined from "@ant-design/icons/TwitterOutlined";
import whatsappLink from "../../helper/whatsappLink";
import {appVersion} from "../../config/dataConfig/appConfig";

class FooterSection extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = {...this.initState}
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section" style={{marginTop: "auto"}}>
                    <div className="container" style={this.props.lite ? {display: 'none'} : {}}>
                        <div className="footer-content pt-5 pb-5">
                            <Row>
                                <Col className={`p-20`} xxl={{span: 6, offset: 4}} xl={{span: 6, offset: 4}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <Link to={`/`}>
                                                <img style={{height: 25, paddingLeft: 15, marginTop: "-10px", cursor: "pointer"}} className={`header-logo`} src={'/assets/img/header-text.png'} alt={`amartahonda-logo`}/>
                                            </Link>
                                        </div>
                                        <div className="footer-text">
                                            <p><b>Amartahonda.</b> Kami adalah dealer sepeda motor resmi Merk Otomotif Honda di Indonesia. <br/> Dapatkan pengajuan kredit Honda online dengan bunga termurah dan proses cepat</p>
                                        </div>
                                        <div className="footer-social-icon">
                                            <div className="footer-widget-heading">
                                                <h3>Follow Us</h3>
                                            </div>
                                            <a href={`https://www.youtube.com/@amartahonda5061`} target={`_blank`}><YoutubeOutlined/></a>
                                            <a href={`https://www.instagram.com/amartahonda`} target={`_blank`}><InstagramOutlined/></a>
                                            <a href={`https://www.facebook.com/amartahondacom`} target={`_blank`}><FacebookOutlined/></a>
                                            <a href={`https://twitter.com/amartahonda`} target={`_blank`}><TwitterOutlined/></a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 6}} xl={{span: 6}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href={"https://www.amartamotor.com/"} target={"_blank"} rel={'noreferrer'}>About Us</a></li>
                                            <li><Link to={`/baru/${this.props.areaCode.toLowerCase()}/variant`}>Motor Baru</Link></li>
                                            <li><Link to={`/privasi`}>Kebijakan & Privasi</Link></li>
                                            <li><a href={'https://autotrimitra.web.app?utm_source=amartahonda'} target={'_blank'} rel={'noreferrer'}> Jual Motor </a></li>
                                            <li><a href={"/"} onClick={() => whatsappLink('628112341361', `Saya membutuhkan info mengenai amartahonda %0a${window.location.href}`)}>Chat With Us</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 6}} xl={{span: 6}} md={{span: 8}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div>
                                            <EnvironmentOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Find us</h4>
                                                <span>Jl Moh Toha no 136 Kota Bandung</span>
                                            </div>
                                        </div>
                                        <div className="single-cta">
                                            <PhoneOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div style={{cursor: "pointer"}} className="cta-text" onClick={() => whatsappLink('628112341361', `Saya membutuhkan info mengenai amartahonda %0a${window.location.href}`)}>
                                                <h4>Chat With Us</h4>
                                                <span>+6281-1234-1361</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="copyright-area p-20">
                        <div className="container">
                            <div className="row">
                                <Row>
                                    <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <div className="copyright-text">
                                            <p><b>Amartahonda</b> v-{appVersion} &copy; {new Date().getFullYear()}, <a href="https://trimitra.biz" rel={"noreferrer"} target={"_blank"}>PT Trimitra Sukses Makmur</a></p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterSection;
