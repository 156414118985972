import React, {Component} from 'react';
import {Layout, Image, Button, Card, Divider, Form, Input} from "antd";
import {LockOutlined, UserOutlined, PhoneOutlined, MailOutlined} from "@ant-design/icons";
import Cookies from 'universal-cookie';
import {withRouter} from "../../hoc/withRouter";
import Col from "antd/lib/col";
import Modal from "antd/lib/modal";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import {Link} from "react-router-dom";
import Row from "antd/lib/row";
import {userServices} from "../../services/amartaVip/UserServices";

const {Footer} = Layout;
const currentQueryParams = window.location.search;

export interface RegisterProps {
}

export interface RegisterStates extends RegisterFields {
    processing: boolean;
}

export interface RegisterFields {
    fullName: string;
    email: string;
    phoneNumber: string;
    password: string;
    reTypePassword: string;
}

class Register extends Component<RegisterProps & any, RegisterStates> {
    private cookies = new Cookies();
    private readonly initState!: RegisterStates;

    constructor(props: RegisterProps & any) {
        super(props);

        this.initState = {
            processing: false,
            password: "",
            reTypePassword: "",
            fullName: "",
            phoneNumber: "",
            email: "",
        }

        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof RegisterFields>(target: T, value: string) => {
        const currentState: RegisterStates = {...this.state};
        switch (target) {
            case "fullName":
                currentState.fullName = value;
                break;
            case "email":
                currentState.email = value;
                break;
            case "password":
                currentState.password = value;
                break;
            case "reTypePassword":
                currentState.reTypePassword = value;
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    checkBlacklistContact = async () => {
        let aaa = false;
        await userServices.checkBlacklistPhoneNumber(
            {
                phoneNumber: this.state.phoneNumber
            }
        ).then(dataCheck => {
            aaa = true;
        }).catch(e => {
            aaa = false;
        });

        return aaa;
    }

    submit = async () => {
        const fullName = this.state.fullName;
        const password = this.state.password;
        const email = this.state.email;
        const phoneNumber = this.state.phoneNumber;
        const reTypePassword = this.state.reTypePassword;

        if (!fullName || !password || !email || !phoneNumber || !reTypePassword) {
            Modal.error({
                title: 'Error',
                content: "Mohon lengkapi seluruh data"
            });
            return false;
        }

        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
            Modal.error({
                title: 'Error',
                content: "Email tidak valid"
            });
            return false;
        }

        if (password !== reTypePassword) {
            Modal.error({
                title: 'Error',
                content: "Pasword yang anda ketik tidak sama"
            });
            return false;
        }

        this.setState({
            processing: true,
        });

        const checkBlacklist = await this.checkBlacklistContact();
        if (checkBlacklist) {
            Modal.error({
                title: 'Proses Gagal',
                content: "Nomor telpon pemilik tidak dapat digunakan, hubungi admin untuk info lebih lanjut"
            });
            this.setState({
                processing: false
            });
            return;
        }

        let dataRegister = {
            source: "amartahonda",
            media: "email",
            phone: phoneNumber,
            email: email,
            full_name: fullName,
            password: password
        };

        try {
            await authApiService.register(dataRegister);
            this.setState({
                processing: false,
            });

            Modal.success({
                title: 'Proses Sukses',
                content: 'Pendaftaran sukses, dalam 3 detik kedepan anda akan diarahkan ke halaman login'
            });

            await new Promise(resolve => setTimeout(resolve, 3000));
            this.props.history.push(`/login${currentQueryParams}`);
        } catch (e) {
            Modal.error({
                title: 'Register Failed',
                content: 'error: ' + e,
            });

            this.setState({
                processing: false,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={{minHeight: '85vh', backgroundColor: "#fff", padding: "5vh"}}>
                            <Card type="inner" style={{width: "100%"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 50}}>
                                    <Image preview={false} src={`/assets/img/favicon.png`}/>
                                </div>
                                <div style={{textAlign: "center", paddingTop: 10, fontSize: "120%", fontWeight: 600}}>Register Amartahonda</div>
                                <Divider/>
                                <Row>
                                    <Col xl={{span: 12, offset: 6}} lg={{span: 10, offset: 7}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                        <div className="content-main-form">
                                            <Form>
                                                <Form.Item>
                                                    <Input prefix={<UserOutlined/>} placeholder={'Nama Lengkap'} value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Input
                                                        prefix={<PhoneOutlined/>}
                                                        value={this.state.phoneNumber}
                                                        maxLength={17}
                                                        placeholder="Format Telpon: 08xxxxxxxx"
                                                        onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                                    />
                                                </Form.Item>
                                                <Form.Item>
                                                    <Input prefix={<MailOutlined/>} placeholder={'Email'} value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Password'} value={this.state.password} onChange={event => this.onFieldChange("password", event.target.value)}/>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Ketik Ulang Password'} value={this.state.reTypePassword} onChange={event => this.onFieldChange("reTypePassword", event.target.value)}/>
                                                </Form.Item>
                                                <div style={{margin: '25px 0'}}>
                                                    <Button block={true} onClick={this.submit} loading={this.state.processing} type={'primary'} htmlType={'submit'} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}}> Register AmartaVIP</Button>
                                                </div>
                                                <div style={{textAlign: "center", fontSize: "80%", paddingTop: 20}}>
                                                    Sudah punya akun?
                                                    <Link to={`/login${currentQueryParams}`}>
                                                        <b style={{cursor: "pointer", paddingLeft: 5, color: "#000"}}>Login disini</b>
                                                    </Link>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <Link to={`/bandung`}>
                                    <div style={{textAlign: "center", fontSize: "80%", paddingTop: 30}}>
                                        Kembali ke Home Amartahonda
                                    </div>
                                </Link>
                            </Card>
                        </div>


                        <Footer style={{textAlign: 'center', backgroundColor: "#fff"}}>
                            <Divider/>
                            Amartahonda ©{new Date().getFullYear()} <br/>v-1.0.5
                        </Footer>
                    </Col>
                </Layout>

            </React.Fragment>
        );
    }
}

export default withRouter(Register);
