import React from "react";
import {Link} from "react-router-dom";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Tag from "antd/lib/tag";
import currencyFormat from "../../helper/currencyFormat";

const ResumeMiniProduct = (props: any) => {
    return (
        <React.Fragment>
            <div style={props.image ? {display: "flex", alignItems: "center", justifyContent: "center"} : {display: 'none'}}>
                <img alt={`variant color`} src={props.image} style={{width: "50%"}}/>
            </div>
            <Row style={{paddingTop: 5}}>
                <Col span={8} className={`text-small-green-nopadding`}>Model:</Col>
                <Col span={14} className={`text-small-grey-nopadding`}>{props.modelName}</Col>
            </Row>
            <Row style={{paddingTop: 5}}>
                <Col span={8} className={`text-small-green-nopadding`}>Variant:</Col>
                <Col span={14} className={`text-small-grey-nopadding`}>{props.variantName}</Col>
            </Row>
            <Row style={{paddingTop: 5}}>
                <Col span={8} className={`text-small-green-nopadding`}>Kode Variant:</Col>
                <Col span={14} className={`text-small-grey-nopadding`}>{props.variantCode}</Col>
            </Row>
            <Row style={{paddingTop: 5}}>
                <Col span={8} className={`text-small-green-nopadding`}>Warna:</Col>
                <Col span={14} className={`text-small-grey-nopadding`}>
                    {props.variantColorName} ({props.variantColorCode}) &nbsp;
                    <Tag color="#64b48f"><Link to={props.linkTarget}>Ganti Warna</Link></Tag>
                </Col>
            </Row>
            <Row style={props.price ? {paddingTop: 5} : {display: 'none'}}>
                <Col span={8} className={`text-small-green-nopadding`}>OTR:</Col>
                <Col span={14} className={`text-small-grey-nopadding`}>{currencyFormat(props.price ?? 0)}</Col>
            </Row>
        </React.Fragment>
    );
}
export default ResumeMiniProduct;
