import axios from "axios";
import {baseUrlMomotor} from "../../config/apiConfig/apiConfig";

class MomotorVehicleService {
    private axios = axios.create({
        baseURL: baseUrlMomotor,
    });

    public async getVehicleBrand() {
        try {
            return await this.axios.get<any>('/momotor/brand');
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async getVehicleModel(params?: { brand?: string }) {
        try {
            return await this.axios.post<any>('/momotor/model',
                params, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getPrice(params?: { model_code?: string, year?: number }) {
        try {
            return await this.axios.post<any>('/momotor/price',
                params, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async postSubmitLead(params: any) {
        try {
            return await this.axios.post<any>('/momotor/submit-leads',
                params, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
        } catch (e: any) {
            throw new Error(e?.response);
        }
    }
}

export const momotorVehicleService = new MomotorVehicleService();
