import React, {Component} from "react";
import {connect} from "react-redux";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import '../../screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit.css';
import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Image from "antd/lib/image";
import Modal from "antd/lib/modal";
import Collapse from "antd/lib/collapse";
import CropIdCardImage from "../image/CropIdCardImage";
import Spin from "antd/lib/spin";
import {Upload} from "antd";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import UploadIdCardImage from "../image/UploadIdCardImage";
import Input from "antd/lib/input";

const {Panel} = Collapse;

const randomString: any = Array.from({length: 16}, () => Math.floor(Math.random() * 10)).join('');

class CreditSubmissionPhoneAndDocuments extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            freezeStatus: false,
            showModalUploadIdCardGuarantor: false,
            base64IdCardGuarantor: "",
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",
            showModalUploadIncomeDocumentImage: false,
            base64IncomeDocument: "",
            showModalUploadPlaceToStayDocumentImage: false,
            base64PlaceToStayDocument: "",
            showModalUploadMaritalDocumentImage: false,
            base64maritalDocument: "",
            showOldUploadRawImage: false,
            showOldUploadRaw2Image: false,
        }
        this.state = {...this.initState}
    }

    // UPLOAD-ID_CARD_GUARANTOR
    clickUpdateGuarantorIdCardImage = () => {
        this.setState({
            showModalUploadIdCardGuarantor: true,
            showOldUploadRaw2Image: false,
        });
    }
    onCropIdCardGuarantor = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardGuarantor: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }
    saveGuarantorIdCardData = async () => {
        if (this.state.base64IdCardGuarantor?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRaw2Image: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.props.guarantorIdCardNumber,
            image_data: this.state.base64IdCardGuarantor,
            source: "amartahonda.com",
            notes: "credit forms - guarantor"
        }

        await this.uploadImageAndUpdateRedux("IDCARDGUARANTOR_IMAGE", dataUpload);
    }

    // UPLOAD-ID_CARD_OWNER
    clickUpdateOwnerIdCardImage = () => {
        this.setState({showModalUploadIdCardOwner: true});
    }
    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOwner: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }
    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.props.ownerIdCardNumber,
            image_data: this.state.base64IdCardOwner,
            source: "amartahonda.com",
            notes: "credit forms - owner"
        }

        await this.uploadImageAndUpdateRedux("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageChange = async (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            await this.props.changeOwnerIdCardImage(newImgLocation);
            this.setState({
                freezeStatus: false
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
            });
        }
    }

    uploadImage2Change = async (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            await this.props.changeGuarantorIdCardImage(newImgLocation);
            this.setState({
                freezeStatus: false
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
            });
        }
    }

    // UPLOAD-INCOME-DOCUMENT
    clickUpdateIncomeDocumentImage = () => {
        this.setState({showModalUploadIncomeDocumentImage: true});
    }
    onCropIncomeDocument = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IncomeDocument: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }
    saveOwnerIncomeDocument = async () => {
        const dataUpload = {
            id_image: this.props.ownerIdCardNumber,
            image_data: this.state.base64IncomeDocument,
            document_type: "income",
            source: "amartahonda.com",
            notes: "credit forms"
        }

        await this.uploadImageAndUpdateRedux("INCOMEDOCUMENT_IMAGE", dataUpload);
    }

    // UPLOAD-PLACETOSTAY-DOCUMENT
    clickUpdatePlaceToStayImage = () => {
        this.setState({showModalUploadPlaceToStayDocumentImage: true});
    }
    onCropPlaceToStayDocument = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64PlaceToStayDocument: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }
    saveOwnerPlaceToStayDocument = async () => {
        const dataUpload = {
            id_image: this.props.ownerIdCardNumber,
            image_data: this.state.base64PlaceToStayDocument,
            document_type: "document",
            source: "amartahonda.com",
            notes: "credit forms"
        }
        await this.uploadImageAndUpdateRedux("PLACETOSTAYDOCUMENT_IMAGE", dataUpload);
    }

    // UPLOAD-MARITAL-DOCUMENT
    clickUpdateMaritalImage = () => {
        this.setState({showModalUploadMaritalDocumentImage: true});
    }
    onCropMaritalDocument = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64MaritalDocument: base64DataImagePng,
        });
        window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }
    saveOwnerMaritalDocument = async () => {
        const dataUpload = {
            id_image: this.props.ownerIdCardNumber,
            image_data: this.state.base64MaritalDocument,
            document_type: "document",
            source: "amartahonda.com",
            notes: "credit forms"
        }

        await this.uploadImageAndUpdateRedux("MARITALDOCUMENT_IMAGE", dataUpload);
    }

    uploadImageAndUpdateRedux = async (type: string, dataUpload: any) => {
        if (type === "IDCARDGUARANTOR_IMAGE") {
            if (!this.state.base64IdCardGuarantor) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemohon"});
                return;
            }
        }
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }
        if (type === "INCOMEDOCUMENT_IMAGE") {
            if (!this.state.base64IncomeDocument) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image dokumen penghasilan"});
                return;
            }
        }
        if (type === "PLACETOSTAYDOCUMENT_IMAGE") {
            if (!this.state.base64PlaceToStayDocument) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image pembayaran PBB terakhir"});
                return;
            }
        }
        if (type === "MARITALDOCUMENT_IMAGE") {
            if (!this.state.base64MaritalDocument) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image buku pernikahan"});
                return;
            }
        }
        await this.promisedSetState({freezeStatus: true});
        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDGUARANTOR_IMAGE") {
                    await this.props.changeGuarantorIdCardImage(successData?.data?.data?.url_document);
                }
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.props.changeOwnerIdCardImage(successData?.data?.data?.url_document);
                }
                if (type === "INCOMEDOCUMENT_IMAGE") {
                    await this.props.changeIncomeDocumentImage(successData?.data?.data?.url_document);
                }
                if (type === "PLACETOSTAYDOCUMENT_IMAGE") {
                    await this.props.changePlaceToStayDocumentImage(successData?.data?.data?.url_document);
                }
                if (type === "MARITALDOCUMENT_IMAGE") {
                    await this.props.changeMaritalDocumentImage(successData?.data?.data?.url_document);
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadIdCardGuarantor: false,
                    showModalUploadIdCardOwner: false,
                    showModalUploadIncomeDocumentImage: false,
                    showModalUploadPlaceToStayDocumentImage: false,
                    showModalUploadMaritalDocumentImage: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({freezeStatus: false});
        }
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "family_card") {
            await this.props.changeFamilyCardImage(data.image_url);
        }
        if (data.id === "spouse") {
            await this.props.changeSpouseIdCardImage(data.image_url);
        }
    }

    onFieldChange = <T extends keyof Pick<any, "spouseIdCardNumber" | "spouseIdCardName">>(target: T, value: any) => {
        switch (target) {
            case "spouseIdCardNumber":
                this.props.changeSpouseIdCardNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "spouseIdCardName":
                this.props.changeSpouseIdCardName(value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, ''));
                break;
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Form layout="vertical">
                    <Divider orientation="left" plain>
                        <i className={`text-small-grey-nopadding`}>Dokumen yang Wajib Diupload</i>
                    </Divider>
                    <div className={`mt-5`}>
                        <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'family_card'} title={`Kartu Keluarga`} name={``} number={this.props.familyCardNumber} imageSrc={this.props.familyCardImage}/>
                    </div>
                    <div className={`card-box-shadow`}>
                        <Row>
                            <Col span={24}>
                                <IdcardOutlined className={`icon-title-info`}/>
                                <b className={`text-title-info`}>{(this.props.guarantorIdCardNumber === this.props.ownerIdCardNumber) ? "KTP Pemohon & Pemilik" : "KTP Pemohon"}</b>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={23} offset={1}>
                                <Row>
                                    <Col span={12}>
                                        <div className="font-old-grey-100">
                                            <i className={`text-extra-small-grey-nopadding`}>{(this.props.guarantorIdCardNumber === this.props.ownerIdCardNumber) ? "Nomor KTP Pemohon&Pemilik" : "Nomor KTP Pemohon"}</i>
                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.props.guarantorIdCardNumber} </div>
                                        </div>
                                        <div style={{marginTop: 7}} className="font-old-grey-100">
                                            <i className={`text-extra-small-grey-nopadding`}>{(this.props.guarantorIdCardNumber === this.props.ownerIdCardNumber) ? "Nama KTP Pemohon&Pemilik" : "Nama KTP Pemohon"}</i>
                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}>
                                                {`${(this.props.guarantorIdCardName?.length < 23) ? this.props.guarantorIdCardName : this.props.guarantorIdCardName?.substring(0, 22) + "..."}`}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        {this.props.guarantorIdCardImage
                                            ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.props.guarantorIdCardImage}/></div>
                                            : <div onClick={() => this.clickUpdateGuarantorIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                <div onClick={() => this.clickUpdateGuarantorIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                    {this.props.guarantorIdCardImage
                                        ? <span>Update Gambar KTP Pemohon</span>
                                        : <span>Lengkapi Gambar KTP Pemohon</span>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className={`card-box-shadow`} style={this.props?.ownerIdCardNumber !== this.props?.guarantorIdCardNumber ? {} : {display: 'none'}}>
                        <Row>
                            <Col span={24}>
                                <IdcardOutlined className={`icon-title-info`}/>
                                <b className={`text-title-info`}>KTP Pemilik</b>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Col span={23} offset={1}>
                                <Row>
                                    <Col span={12}>
                                        <div className="font-old-grey-100">
                                            <i className={`text-extra-small-grey-nopadding`}>Nomor KTP Pemilik:</i>
                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.props.ownerIdCardNumber} </div>
                                        </div>
                                        <div style={{marginTop: 7}} className="font-old-grey-100">
                                            <i className={`text-extra-small-grey-nopadding`}>Nama KTP Pemilik:</i>
                                            <div style={{marginTop: -2}} className={`text-small-black-nopadding`}>
                                                {`${(this.props.ownerIdCardName?.length < 23) ? this.props.ownerIdCardName : this.props.ownerIdCardName?.substring(0, 22) + "..."}`}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        {this.props.ownerIdCardImage
                                            ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.props.ownerIdCardImage}/></div>
                                            : <div onClick={() => this.clickUpdateOwnerIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                <div onClick={() => this.clickUpdateOwnerIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                    {this.props.ownerIdCardImage
                                        ? <span>Update Gambar KTP Pemilik</span>
                                        : <span>Lengkapi Gambar KTP Pemilik</span>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br/>

                    <Divider orientation="left" plain>
                        <i className={`text-small-grey-nopadding`}>KTP Pasangan</i>
                    </Divider>
                    <div style={this.props?.ownerMaritalStatus?.toLowerCase() === "married" ? {} : {display: 'none'}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor KTP Pasangan *</i>
                            <Input
                                maxLength={16}
                                value={this.props.spouseIdCardNumber}
                                placeholder="Nomor Sesuai KTP"
                                onChange={event => this.onFieldChange("spouseIdCardNumber", event.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nama KTP Pasangan *</i>
                            <Input
                                value={this.props.spouseIdCardName}
                                placeholder="Nama Sesuai KTP"
                                onChange={event => this.onFieldChange("spouseIdCardName", event.target.value)}
                            />
                        </Form.Item>
                        <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'spouse'} title={`KTP Pasangan`} number={(this.props.spouseIdCardNumber) ? this.props.spouseIdCardNumber : randomString} hideInfo={(!this.props.spouseIdCardNumber)} name={this.props.spouseIdCardName} imageSrc={this.props.spouseIdCardImage}/>
                        <br/><br/>
                    </div>

                    <Collapse defaultActiveKey={['1']} ghost>
                        <Panel header={`Upload Opsional Dokumen`} key="2">
                            <div className={`card-box-shadow`}>
                                <Row>
                                    <Col span={24}>
                                        <IdcardOutlined className={`icon-title-info`}/>
                                        <b className={`text-title-info`}>Dokumen Penghasilan</b>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col span={23} offset={1}>
                                        <Row>
                                            <Col span={12}>
                                                <div className="font-old-grey-100">
                                                    <i className={`text-extra-small-grey-nopadding`}>Opsional Dokumen</i>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                {this.props.incomeDocumentImage
                                                    ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.props.incomeDocumentImage}/></div>
                                                    : <div onClick={() => this.clickUpdateIncomeDocumentImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                        <div onClick={() => this.clickUpdateIncomeDocumentImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                            {this.props.incomeDocumentImage
                                                ? <span>Update Gambar Dokumen Penghasilan</span>
                                                : <span>Lengkapi Gambar Dokumen Penghasilan</span>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className={`card-box-shadow`}>
                                <Row>
                                    <Col span={24}>
                                        <IdcardOutlined className={`icon-title-info`}/>
                                        <b className={`text-title-info`}>Pembayaran PBB Terakhir</b>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col span={23} offset={1}>
                                        <Row>
                                            <Col span={12}>
                                                <div className="font-old-grey-100">
                                                    <i className={`text-extra-small-grey-nopadding`}>Opsional Dokumen</i>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                {this.props.placeToStayDocumentImage
                                                    ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.props.placeToStayDocumentImage}/></div>
                                                    : <div onClick={() => this.clickUpdatePlaceToStayImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                        <div onClick={() => this.clickUpdatePlaceToStayImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                            {this.props.placeToStayDocumentImage
                                                ? <span>Update Gambar Pembayaran PBB Terakhir</span>
                                                : <span>Lengkapi Gambar Pembayaran PBB Terakhir</span>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className={`card-box-shadow`}>
                                <Row>
                                    <Col span={24}>
                                        <IdcardOutlined className={`icon-title-info`}/>
                                        <b className={`text-title-info`}>Dokumen Pernikahan</b>
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: 10}}>
                                    <Col span={23} offset={1}>
                                        <Row>
                                            <Col span={12}>
                                                <div className="font-old-grey-100">
                                                    <i className={`text-extra-small-grey-nopadding`}>Opsional Dokumen</i>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                {this.props.maritalDocumentImage
                                                    ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.props.maritalDocumentImage}/></div>
                                                    : <div onClick={() => this.clickUpdateMaritalImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                        <div onClick={() => this.clickUpdateMaritalImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                            {this.props.maritalDocumentImage
                                                ? <span>Update Gambar Buku Nikah</span>
                                                : <span>Lengkapi Gambar Buku Nikah</span>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                    <Divider/>
                </Form>

                <Modal
                    title="Lengkapi Data KTP Pemohon"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardGuarantor}
                    onCancel={() => this.setState({showModalUploadIdCardGuarantor: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRaw2Image ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveGuarantorIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardGuarantor: false})}> Close </Button>,
                    ]}
                >
                    <Form style={!this.state.showOldUploadRaw2Image ? {} : {display: 'none'}} layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardImage) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemohon</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardGuarantor}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRaw2Image ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.props.guarantorIdCardNumber,
                                source: "amartahonda.com",
                                notes: "credit forms"
                            }}
                            name="image"
                            onChange={this.uploadImage2Change}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Data KTP Pemilik"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardImage) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.props.ownerIdCardNumber,
                                source: "amartahonda.com",
                                notes: "credit forms"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Data Penghasilan"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIncomeDocumentImage}
                    onCancel={() => this.setState({showModalUploadIncomeDocumentImage: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveOwnerIncomeDocument}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIncomeDocumentImage: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardImage) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIncomeDocument}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Dokumen Tempat Tinggal"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadPlaceToStayDocumentImage}
                    onCancel={() => this.setState({showModalUploadPlaceToStayDocumentImage: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveOwnerPlaceToStayDocument}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadPlaceToStayDocumentImage: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64PlaceToStayDocument) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropPlaceToStayDocument}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="Lengkapi Dokumen Pernikahan"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadMaritalDocumentImage}
                    onCancel={() => this.setState({showModalUploadMaritalDocumentImage: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveOwnerMaritalDocument}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadMaritalDocumentImage: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64maritalDocument) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropMaritalDocument}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    familyCardImage: state.RfamilyCardImage,
    familyCardNumber: state.RfamilyCardNumber,
    guarantorIdCardNumber: state.RguarantorIdCardNumber,
    guarantorIdCardName: state.RguarantorIdCardName,
    guarantorIdCardImage: state.RguarantorIdCardImage,

    spouseIdCardNumber: state.RspouseIdCardNumber,
    spouseIdCardName: state.RspouseIdCardName,
    spouseIdCardImage: state.RspouseIdCardImage,

    ownerIdCardNumber: state.RownerIdCardNumber,
    ownerIdCardName: state.RownerIdCardName,
    ownerIdCardImage: state.RownerIdCardImage,
    ownerMaritalStatus: state.RownerMaritalStatus,

    incomeDocumentImage: state.RincomeDocumentImage,
    placeToStayDocumentImage: state.RplaceToStayDocumentImage,
    maritalDocumentImage: state.RmaritalDocumentImage,
});

const reduxDispatch = (dispatch: any) => ({
    changeFamilyCardImage: (data: string) => dispatch({type: 'CHANGE_FAMILYCARD_IMAGE', value: data}),
    changeOwnerIdCardImage: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_IMAGE', value: data}),
    changeGuarantorIdCardImage: (data: string) => dispatch({type: 'CHANGE_GUARANTOR_IDCARD_IMAGE', value: data}),

    changeSpouseIdCardNumber: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_NUMBER', value: data}),
    changeSpouseIdCardName: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_NAME', value: data}),
    changeSpouseIdCardImage: (data: string) => dispatch({type: 'CHANGE_SPOUSE_IDCARD_IMAGE', value: data}),

    changeIncomeDocumentImage: (data: string) => dispatch({type: 'CHANGE_INCOMEDOCUMENT_IMAGE', value: data}),
    changePlaceToStayDocumentImage: (data: string) => dispatch({type: 'CHANGE_PLACETOSTAYDOCUMENT_IMAGE', value: data}),
    changeMaritalDocumentImage: (data: string) => dispatch({type: 'CHANGE_MARIRTALDOCUMENT_IMAGE', value: data}),
});

export default connect(reduxState, reduxDispatch)(CreditSubmissionPhoneAndDocuments);