import React, {Component} from "react";
// import {Buffer} from "buffer";
import Cookies from "universal-cookie";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import {Button, Col, Image, DatePicker, Form, Input, Modal, Row, Spin, Upload, Collapse} from "antd";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {IdcardOutlined, UploadOutlined} from "@ant-design/icons";
import CustomerMaritalStatus from "../customer/CustomerMaritalStatus";
import CustomerOccupation from "../customer/CustomerOccupation";
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import AreaDistrictSelect from "../area/AreaDistrictSelect";
import CropIdCardImage from "../../components/image/CropIdCardImage";
import AreaSubdistrictSelect from "../area/AreaSubdistrictSelect";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import {authApiService} from "../../services/amartaVip/AuthApiService";

const {Panel} = Collapse;

class UpdateOwnerOffer extends Component<any, any> {
    private readonly initState!: any;
    private cookies = new Cookies();
    private user = "user amartahonda";

    constructor(props: any) {
        super(props);
        this.initState = {
            offerCode: "",
            dataDetailStock: {},
            fetchingDetailOffer: false,
            ownerIdCardNumber: "",
            ownerIdCardName: "",
            ownerIdCardImage: "",
            ownerBirthPlace: "",
            ownerBirthDate: moment(new Date(), 'YYYY-MM-DD'),
            ownerMaritalStatus: null,
            ownerMaritalStatusName: "",
            ownerOccupationCode: null,
            ownerOccupationName: "",
            ownerPlaceToStayCode: null,
            ownerPlaceToStayName: "",
            ownerFullAddress: "",
            ownerPostalCode: "",
            ownerProvinceCode: "",
            ownerProvinceName: "",
            ownerCityCode: "",
            ownerCityName: "",
            ownerDistrictCode: "",
            ownerDistrictName: "",
            ownerSubdistrictCode: "",
            ownerSubdistrictName: "",
            updateProcess: false,
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",
            freezeStatus: false,
            token: this.cookies.get('_amh-t'),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            offerCode: this.props.offerCode,
            fetchingDetailOffer: true,
        });

        this.showOfferDetail();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.offerCode !== prevProps.offerCode) {
            await this.promisedSetState({
                offerCode: this.props.offerCode,
                fetchingDetailOffer: true,
            });
        }

        if (this.props.time !== prevProps.time) {
            this.showOfferDetail();
        }
    }

    showOfferDetail = async () => {

        await this.promisedSetState({
            offerCode: "",
            dataDetailStock: {},
            fetchingDetailOffer: false,
            ownerIdCardNumber: "",
            ownerIdCardName: "",
            ownerIdCardImage: "",
            ownerBirthPlace: "",
            ownerBirthDate: moment(new Date(), 'YYYY-MM-DD'),
            ownerMaritalStatus: null,
            ownerMaritalStatusName: "",
            ownerOccupationCode: null,
            ownerOccupationName: "",
            ownerPlaceToStayCode: null,
            ownerPlaceToStayName: "",
            ownerFullAddress: "",
            ownerPostalCode: "",
            ownerProvinceCode: "",
            ownerProvinceName: "",
            ownerCityCode: "",
            ownerCityName: "",
            ownerDistrictCode: "",
            ownerDistrictName: "",
            ownerSubdistrictCode: "",
            ownerSubdistrictName: "",
            updateProcess: false,
        });

        await offerApiServices.getDetailOffer({
            offerCode: this.props.offerCode,
        }).then(async value => {
            await this.promisedSetState({
                showModalUploadIdCardOwner: false,
                base64IdCardOwner: "",
                freezeStatus: false,
                dataDetailStock: value.data.data,
                fetchingDetailOffer: false,
                ownerIdCardNumber: value.data.data?.id_card_owner?.id_card_number,
                ownerIdCardName: value.data.data?.id_card_owner?.full_name,
                ownerIdCardImage: value.data.data?.id_card_owner?.id_card_image,
                ownerBirthPlace: value.data.data?.id_card_owner?.birth_place,
                ownerBirthDate: (value.data.data?.id_card_owner?.birth_date) ? value.data.data?.id_card_owner?.birth_date : moment(new Date(), 'YYYY-MM-DD'),
                dataStatusUpdateImage: true,
                ownerPostalCode: value.data.data.address_owner.zip_code,
                ownerProvinceCode: value.data.data.address_owner.province_code,
                ownerProvinceName: value.data.data.address_owner.province_name,
                ownerCityCode: value.data.data.address_owner.city_code,
                ownerCityName: value.data.data.address_owner.city_name,
                ownerDistrictCode: value.data.data.address_owner.district_code,
                ownerDistrictName: value.data.data.address_owner.district_name,
                ownerSubdistrictCode: value.data.data.address_owner.sub_district_code,
                ownerSubdistrictName: value.data.data.address_owner.sub_district_name,
                ownerFullAddress: value.data.data.address_owner.full_address,
                ownerMaritalStatus: value.data.data.address_owner.marital_status,
                ownerMaritalStatusName: value.data.data.address_owner.marital_status,
                ownerOccupationCode: value.data.data.address_owner.occupation,
                ownerOccupationName: value.data.data.address_owner.occupation_code,
                ownerPlaceToStayCode: null,
                ownerPlaceToStayName: "",
            });
        }).catch(async reason => {
            await this.promisedSetState({
                dataDetailStock: {},
                fetchingDetailOffer: false,
            });
        });
    }

    onFieldChange = <T extends keyof Pick<any, "ownerIdCardNumber" | "ownerIdCardName" | "ownerFullAddress" | "ownerMaritalStatus" | "ownerPostalCode" | "ownerDifferentAddress" | "shippingFullAddress" | "shippingPostalCode" | "ownerBirthPlace" | "ownerBirthDate">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "ownerIdCardNumber":
                currentState.ownerIdCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "ownerIdCardName":
                currentState.ownerIdCardName = value?.toUpperCase();
                break;
            case "ownerBirthPlace":
                currentState.ownerBirthPlace = value;
                break;
            case "ownerBirthDate":
                currentState.ownerBirthDate = value;
                break;
            case "ownerFullAddress":
                currentState.ownerFullAddress = value;
                break;
            case "ownerPostalCode":
                currentState.ownerPostalCode = value;
                break;
        }

        if (target === 'ownerIdCardNumber') {
            currentState.dataStatusUpdateImage = target === 'ownerIdCardNumber' && value.length === 16;
        }

        this.setState({
            ...currentState,
        });
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                submitting: false,
                ownerIdCardImage: newImgLocation,
                updateProcess: false,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                submitting: true,
                updateProcess: false,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                submitting: false,
                ownerIdCardImage: "",
                updateProcess: false,
            });
        }
    }

    onCustomerMaritalStatusChangeChange = (data: any) => {
        this.setState({
            ownerMaritalStatus: data.value,
            ownerMaritalStatusName: data.children
        });
    }

    onCustomerOccupationChangeChange = (data: any) => {
        this.setState({
            ownerOccupationCode: data.value,
            ownerOccupationName: data.children
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            ownerProvinceCode: data.value,
            ownerProvinceName: data.children,
            ownerCityCode: null,
            ownerCityName: null,
            ownerDistrictCode: null,
            ownerDistrictName: null,
            ownerSubdistrictCode: null,
            ownerSubdistrictName: null,
            ownerPostalCode: "",
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            ownerCityCode: data.value,
            ownerCityName: data.children,
            ownerDistrictCode: null,
            ownerDistrictName: null,
            ownerSubdistrictCode: null,
            ownerSubdistrictName: null,
            ownerPostalCode: "",
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            ownerDistrictCode: data.value,
            ownerDistrictName: data.children,
            ownerSubdistrictCode: null,
            ownerSubdistrictName: null,
            ownerPostalCode: "",
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            ownerSubdistrictCode: data.value,
            ownerSubdistrictName: data.children,
            ownerPostalCode: data?.postalCode,
        });
    }

    onUpdateData = async () => {
        const dataUpdate = {
            type: "update-spk-id-card-order-maker",
            company: this.props.companyCode,
            admin_id: this.user,
            admin_name: this.user,
            offer_code: this.props.offerCode,
            id_card_owner: {
                id_card_number: this.state.ownerIdCardNumber,
                id_card_image: this.state.ownerIdCardImage,
                full_name: this.state.ownerIdCardName,
                full_address: this.state.ownerFullAddress,
                birth_place: this.state.ownerBirthPlace,
                birth_date: moment(this.state?.ownerBirthDate).format("YYYY-MM-DD"),
                marital_status_code: this.state.ownerMaritalStatus,
                marital_status: this.state.ownerMaritalStatusName,
                occupation_code: this.state.ownerOccupationCode,
                occupation: this.state.ownerOccupationName,
            },
            address_owner: {
                full_address: this.state.ownerFullAddress,
                province_code: this.state.ownerProvinceCode,
                province_name: this.state.ownerProvinceName,
                city_code: this.state.ownerCityCode,
                city_name: this.state.ownerCityName,
                district_code: this.state.ownerDistrictCode,
                district_name: this.state.ownerDistrictName,
                sub_district_code: this.state.ownerSubdistrictCode,
                sub_district_name: this.state.ownerSubdistrictName,
                zip_code: this.state.ownerPostalCode,
            }
        };

        if (!this.state.ownerIdCardNumber) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Nomor KTP"});
            return false;
        }
        if (!this.state.ownerIdCardImage) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Gambar KTP"});
            return false;
        }
        if (!this.state.ownerIdCardName) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Nama KTP"});
            return false;
        }
        if (!this.state.ownerBirthPlace) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Tempat Lahir Pemilik"});
            return false;
        }
        if (!this.state.ownerBirthDate) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Tanggal Lahir Pemilik"});
            return false;
        }
        if (!this.state.ownerMaritalStatus) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Status Perkawinan"});
            return false;
        }
        if (!this.state.ownerOccupationCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Pekerjaan"});
            return false;
        }
        if (!this.state.ownerFullAddress) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Alamat Lengkap"});
            return false;
        }
        if (!this.state.ownerPostalCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi kode pos"});
            return false;
        }
        if (!this.state.ownerProvinceCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Provinsi"});
            return false;
        }
        if (!this.state.ownerCityCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kota"});
            return false;
        }
        if (!this.state.ownerDistrictCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kecamatan"});
            return false;
        }
        if (!this.state.ownerSubdistrictCode) {
            Modal.error({title: 'Proses Gagal', content: "Mohon Lengkapi Kelurahan"});
            return false;
        }

        await this.promisedSetState({
            updateProcess: true,
        });

        await authApiService.setToken(this.state.token);
        try {
            await authApiService.updateBiodata(dataUpdate)
                .then(successData => {
                    Modal.success({
                        title: 'Proses Sukses',
                        content: 'SPK berhasil di update '
                    });
                    this.setState({
                        updateProcess: false,
                    });
                    this.props.onCloseUpdateOwnerIdCardProps("close");
                });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({
                updateProcess: false,
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.ownerIdCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.promisedSetState({
            base64IdCardOwner: base64DataImagePng,
        });
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        let randomString = (Math.random() + 1).toString(36).substring(7);
        const dataUpload = {
            id_image: "amartavip-idcard-" + randomString + "-" + this.state.ownerIdCardNumber,
            image_data: this.state.base64IdCardOwner,
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "FAMILYCARD_IMAGE") {
                    await this.promisedSetState({
                        familyCardImageUrl: successData?.data?.data?.url_document,
                    });
                }
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        ownerIdCardImage: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.fetchingDetailOffer ? {} : {display: 'none'}} className={`spinCenter`}>
                    <Spin size="large"/>
                </div>
                <div style={!this.state.fetchingDetailOffer ? {} : {display: 'none'}}>
                    <Form layout="vertical">
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                            <Input
                                value={this.state.ownerIdCardName}
                                placeholder="Nama Lengkap"
                                onChange={event => this.onFieldChange("ownerIdCardName", event.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Nomor KTP Pemilik *</i>
                            <Input
                                maxLength={16}
                                value={this.state.ownerIdCardNumber}
                                placeholder="Nomor Sesuai KTP Pemilik"
                                onChange={event => this.onFieldChange("ownerIdCardNumber", event.target.value)}
                            />
                        </Form.Item>

                        <div className={`card-box-shadow`}>
                            <Row>
                                <Col span={24}>
                                    <IdcardOutlined className={`icon-title-info`}/>
                                    <b className={`text-title-info`}>KTP</b>
                                </Col>
                            </Row>
                            <Row style={{paddingTop: 10}}>
                                <Col span={23} offset={1}>
                                    <Row>
                                        <Col span={12}>
                                            <div className="font-old-grey-100">
                                                <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.ownerIdCardNumber} </div>
                                                <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.ownerIdCardName} </div>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            {this.state.ownerIdCardImage
                                                ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.ownerIdCardImage}/></div>
                                                : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'../assets/img/uploadImage.png'}/></div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                    <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                        {this.state.ownerIdCardImage
                                            ? <span>Update Gambar KTP</span>
                                            : <span>Lengkapi Gambar KTP</span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br/>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemilik *</i>
                            <Input
                                value={this.state.ownerBirthPlace}
                                placeholder="Tempat Lahir Sesuai KTP Pemilik"
                                onChange={event => this.onFieldChange("ownerBirthPlace", event.target.value)}
                            />
                        </Form.Item>
                        <Form.Item className={'padTop-7'}>
                            <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemilik *</i>
                            <DatePicker value={moment(this.state.ownerBirthDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("ownerBirthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Status Perkawinan *</i>
                            <CustomerMaritalStatus onCustomerMaritalStatusChangeProps={this.onCustomerMaritalStatusChangeChange} value={this.state.ownerMaritalStatus}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Pekerjaan *</i>
                            <CustomerOccupation onCustomerOccupationChangeProps={this.onCustomerOccupationChangeChange} value={this.state.ownerOccupationCode}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Alamat Sesuai KTP *</i>
                            <TextArea showCount maxLength={200} value={this.state.ownerFullAddress} onChange={event => this.onFieldChange("ownerFullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi *</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.state.ownerProvinceCode} provinceName={this.state.ownerProvinceName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten *</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.ownerProvinceCode} cityName={this.state.ownerCityName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kecamatan *</i>
                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.ownerCityCode} districtName={this.state.ownerDistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kelurahan *</i>
                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.ownerDistrictCode} subdistrictName={this.state.ownerSubdistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Pos Sesuai KTP *</i>
                            <Input
                                value={this.state.ownerPostalCode}
                                placeholder="Kodepos"
                                onChange={event => this.onFieldChange("ownerPostalCode", event.target.value)}
                            />
                        </Form.Item>
                        <br/>

                        <Button type={"primary"} loading={this.state.updateProcess} onClick={() => this.onUpdateData()}> Update Data</Button>
                    </Form>
                </div>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%"}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.ownerIdCardNumber,
                                source: "amartahonda.com",
                                notes: "my-biodata"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default UpdateOwnerOffer;