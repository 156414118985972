import React, {Component} from 'react';
import {Buffer} from "buffer";
import Cookies from 'universal-cookie';
import withAuth from "../../hoc/withAuth";
import {ocrApiService} from "../../services/trimitraCdn/OcrApiService";
import "../../screen/home/Home.css";
import {OrderMemoStates} from "./types/OrderMemoTypes";
import 'reactjs-popup/dist/index.css';
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import NormalHeader from "../../components/layouts/NormalHeader";
import Select from "antd/lib/select";
import {Checkbox, Divider, Dropdown, Popover, Space} from "antd";
import type {MenuProps} from 'antd';
import Input from "antd/lib/input";
import UploadIdCardImage from "../../components/image/UploadIdCardImage";
import TextArea from "antd/es/input/TextArea";
import FooterSection from "../../components/footer/FooterSection";
import {appVersion} from "../../config/dataConfig/appConfig";
import FincoSelect from "../../components/submission/FincoSelect";
import CurrencyInput from "react-currency-input-field";
import AreaProvinceSelect from "../../components/area/AreaProvinceSelect";
import AreaCitySelect from "../../components/area/AreaCitySelect";
import AreaDistrictSelect from "../../components/area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../../components/area/AreaSubdistrictSelect";
import VehicleModelSelect from "../../components/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../components/vehicle-copan/VehicleVariantSelect";
import VehicleVariantColorSelect from "../../components/vehicle-copan/VehicleVariantColorSelect";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Spin from "antd/lib/spin";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import {DownOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {offerApiServices} from "../../services/offer/OfferApiService";

const randomString: any = Array.from({length: 16}, () => Math.floor(Math.random() * 10)).join('');

class OrderMemo extends Component<any, OrderMemoStates> {
    private readonly initState!: OrderMemoStates;
    private cookies = new Cookies();

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda | Order Memo";

        this.initState = {
            company: "AMARTA",
            areaCode: "BANDUNG",
            amartaVipUid: Buffer.from(this.cookies.get('_amh-ui'), 'base64').toString() ?? "",
            amartaVipType: Buffer.from(this.cookies.get('_amh-ut'), 'base64').toString() ?? "",
            userCode: Buffer.from(this.cookies.get('_amh-uc'), 'base64').toString() ?? "",
            userName: Buffer.from(this.cookies.get('_amh-n'), 'base64').toString() ?? "",
            tenorList: [11, 17, 23, 29, 35],
            tenor: null,
            isDesktop: false,
            idCardNumber: "",
            fullName: "",
            imageIdCardOwner: "",
            familyCardNumber: "",
            customerProvinceCode: null,
            customerProvinceName: null,
            customerCityCode: null,
            customerCityName: null,
            customerDistrictCode: null,
            customerDistrictName: null,
            customerSubdistrictCode: null,
            customerSubdistrictName: null,
            customerPostalCode: "",
            customerDisablePostalCode: false,
            customerAddress: "",
            customerHamlet: "",
            customerNeighbourhood: "",
            birthPlace: "",
            birthDate: moment(new Date(), 'YYYY-MM-DD'),

            vehicleBrand: "HONDA",
            vehicleModel: null,
            vehicleModelUuid: null,
            vehicleVariantCode: null,
            vehicleVariantUuid: null,
            vehicleVariantName: null,
            vehicleVariantColorCode: null,
            vehicleVariantColorName: null,
            alternativeColor: {
                code: "SAME_AS_ORDER",
                name: "warna sesuai pesanan"
            },
            vehicleYear: new Date().getFullYear().toString(),
            takeInDealer: false,
            phoneNumber: "",
            fincoChosenCode: null,
            fincoChosenName: "",
            fincoId: "",
            downPayment: 0,
            installmentAmount: 0,
            promoCode: "",
            poCode: "",
            dealerCode: "",
            dealerName: "",

            imagePo: "",
            imageCancelDocument: "",
            freezeStatus: false,
            otr: 0,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    blurDownPayment = () => {
        let dpChosen = Math.round(this.state.downPayment / 100000) * 100000;
        this.setState({
            downPayment: dpChosen
        })
    }

    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0');
    }

    onFieldChange = <T extends keyof Pick<any, "otr" | "birthPlace" | "birthDate" | "phoneNumber" | "customerPostalCode" | "dealerCode" | "dealerName" | "poCode" | "promoCode" | "installmentAmount" | "tenor" | "downPayment" | "fincoId" | "customerHamlet" | "customerNeighbourhood" | "fullName" | "idCardNumber" | "vehicleBrand" | "vehicleYear" | "familyCardNumber" | "customerAddress">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "otr":
                currentState.otr = value;
                break;
            case "birthPlace":
                currentState.birthPlace = value;
                break;
            case "birthDate":
                currentState.birthDate = value;
                break;
            case "dealerCode":
                currentState.dealerCode = value;
                break;
            case "dealerName":
                currentState.dealerName = value;
                break;
            case "poCode":
                currentState.poCode = value;
                break;
            case "promoCode":
                currentState.promoCode = value;
                break;
            case "installmentAmount":
                currentState.installmentAmount = value;
                break;
            case "customerPostalCode":
                currentState.customerPostalCode = value.replace(/[^0-9.]/g, '');
                break;
            case "tenor":
                currentState.tenor = value;
                break;
            case "downPayment":
                currentState.downPayment = value;
                break;
            case "fincoId":
                currentState.fincoId = value;
                break;
            case "fullName":
                currentState.fullName = value?.toUpperCase();
                break;
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "familyCardNumber":
                currentState.familyCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "vehicleBrand":
                currentState.vehicleBrand = value;
                break;
            case "vehicleYear":
                currentState.vehicleYear = value;
                break;
            case "customerAddress":
                currentState.customerAddress = value;
                break;
            case "customerHamlet":
                currentState.customerHamlet = value?.replace(/[^0-9.]/g, '');
                break;
            case "customerNeighbourhood":
                currentState.customerNeighbourhood = value?.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onChangeAlternativeColor = async (code: string, text: string) => {
        this.setState({
            alternativeColor: {
                code: code,
                name: text,
            }
        });
    }

    onBooleanFieldChange = <T extends keyof Pick<any, "takeInDealer">>(target: T, value: boolean) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "takeInDealer":
                currentState.takeInDealer = value;
                break;
        }
        this.setState({
            ...currentState,
        })
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onImageUpdate = async (data: any) => {
        if (data.id === "owner") {
            await this.promisedSetState({
                imageIdCardOwner: data.image_url,
            });
        }
        if (data.id === "imagePo") {
            await this.promisedSetState({
                imagePo: data.image_url,
            });
        }
        if (data.id === "imageCancelDocument") {
            await this.promisedSetState({
                imageCancelDocument: data.image_url,
            });
        }
    }

    onFincoSelectChange = async (data: any) => {
        this.setState({
            fincoChosenCode: data.value,
            fincoChosenName: data.children,
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            customerProvinceCode: data.value,
            customerProvinceName: data.children,
            customerCityCode: null,
            customerCityName: null,
            customerDistrictCode: null,
            customerDistrictName: null,
            customerSubdistrictCode: null,
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            customerCityCode: data.value,
            customerCityName: data.children,
            customerDistrictCode: null,
            customerDistrictName: null,
            customerSubdistrictCode: null,
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            customerDistrictCode: data.value,
            customerDistrictName: data.children,
            customerSubdistrictCode: null,
            customerSubdistrictName: null,
            customerPostalCode: ""
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            customerSubdistrictCode: data.value,
            customerSubdistrictName: data.children,
            customerPostalCode: data.postalCode,
            customerDisablePostalCode: !!(data.postalCode)
        });
    }

    onVehicleModelChange = (data: any) => {
        this.setState({
            vehicleModel: data?.value,
            vehicleModelUuid: data?.uuid,
            vehicleVariantCode: null,
            vehicleVariantUuid: null,
            vehicleVariantName: null,
            vehicleVariantColorCode: null,
            vehicleVariantColorName: null
        });
    }

    onVehicleVariantChange = (data: any) => {
        if (data) {
            this.setState({
                vehicleVariantCode: data.value,
                vehicleVariantName: data?.variant_name,
                vehicleVariantUuid: data?.uuid,
                vehicleVariantColorCode: null,
                vehicleVariantColorName: null,
                otr: data?.price ?? 0
            });
        }
    }

    onVehicleVariantColorChange = (data: any) => {
        if (data) {
            this.setState({
                vehicleVariantColorCode: data.value,
                vehicleVariantColorName: data.children
            });
        }
    }

    onClickSubmit = async () => {
        let warnNotice = "";
        if (!this.state.idCardNumber) {
            warnNotice += "Nomor KTP,";
        }
        if (!this.state.fullName) {
            warnNotice += "Nama Lengkap,";
        }
        if (!this.state.familyCardNumber) {
            warnNotice += "Nomor Kartu Keluarga,";
        }
        if (!this.state.phoneNumber) {
            warnNotice += "Nomor Telpon,";
        }
        if (!this.state.imageIdCardOwner) {
            warnNotice += "Upload Gambar KTP,";
        }
        if (!this.state.customerProvinceCode) {
            warnNotice += "Provinsi, \n";
        }
        if (!this.state.customerCityCode) {
            warnNotice += "Kota,";
        }
        if (!this.state.customerDistrictCode) {
            warnNotice += "Kecamatan,";
        }
        if (!this.state.customerSubdistrictCode) {
            warnNotice += "Kelurahan,";
        }
        if (this.state.customerPostalCode?.trim().length !== 5) {
            warnNotice += "Kode Pos tidak valid,";
        }
        if (!this.state.customerAddress) {
            warnNotice += "Alamat Identitas,";
        }
        if (!this.state.customerNeighbourhood) {
            warnNotice += "RW,";
        }
        if (!this.state.customerHamlet) {
            warnNotice += "RT,";
        }


        if (!this.state.otr) {
            warnNotice += "OTR Credit, ";
        }
        if (!this.state.downPayment) {
            warnNotice += "DP Credit, ";
        }
        if (!this.state.tenor) {
            warnNotice += "Tenor Credit, ";
        }
        if (!this.state.installmentAmount) {
            warnNotice += "Cicilan Credit, ";
        }
        if (!this.state.fincoChosenCode) {
            warnNotice += "Finco Credit, ";
        }
        if (!this.state.poCode) {
            warnNotice += "Kode PO Credit, ";
        }
        if (!this.state.imagePo) {
            warnNotice += "Image PO Credit, ";
        }
        if (!this.state.imageCancelDocument) {
            warnNotice += "Image Cancel Document Credit, ";
        }

        if (this.state.idCardNumber.length !== 16) {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }
        if (warnNotice !== "") {
            const objError = warnNotice.split(",");
            Modal.error({
                title: 'Proses Gagal',
                content: (<div>
                    Mohon Lengkapi data-data berikut: <br/><br/>
                    {objError.map(function (itemError, i) {
                        return <div key={i}>{itemError}</div>;
                    })}
                </div>)
            });
            return false;
        }

        await this.promisedSetState({
            freezeStatus: true,
        });

        try {
            await ocrApiService.getImageIdCardNumber({image_url: this.state.imageIdCardOwner});
        } catch (e) {
            Modal.error({title: 'Proses Gagal', content: "KTP tidak terdeteksi, mohon upload image ktp secara utuh dan jelas"});
            this.setState({freezeStatus: false});
            return false;
        }

        const dataOffer = {
            company: this.state.company,
            source: "memo-amartahonda.com",
            purchase_method: "credit",
            offer_code: "11346" + (Math.floor(Date.now() / 1000)).toString().substring(1) + Math.floor(100 + Math.random() * 900),
            area: this.state.areaCode.toUpperCase(),
            amarta_vip_uid: this.state.amartaVipUid,
            amarta_vip_type: this.state.amartaVipType,
            agent_code: this.state.userCode,
            agent_name: this.state.userName,
            document_ownership_full_name: this.state.fullName,
            take_vehicle_in_dealer: this.state.takeInDealer,
            phone_number_owner: this.state.phoneNumber,
            phone_number_wa_available_owner: false,
            phone_number_alternative_owner: "",
            indent_code: "memo",
            phone_number_order_maker: this.state.phoneNumber,
            phone_number_wa_available_order_maker: false,
            phone_number_alternative_order_maker: "",
            phone_number_guarantor: this.state.phoneNumber,
            phone_number_wa_available_guarantor: false,
            promo_codes: (this.state.promoCode) ? [this.state.promoCode] : [],
            notes: this.state.dealerCode + " | " + this.state.dealerName,
            offer_broker: true,
            id_card_owner: {
                birth_date: moment(this.state?.birthDate).format("YYYY-MM-DD"),
                birth_place: this.state.birthPlace,
                full_address: this.state.customerAddress,
                full_name: this.state.fullName,
                id_card_image: this.state.imageIdCardOwner,
                id_card_number: this.state.idCardNumber,
                marital_status: "",
                occupation_code: "",
                occupation: "",
                sex: ""
            },
            id_card_order_maker: {
                birth_date: moment(this.state?.birthDate).format("YYYY-MM-DD"),
                birth_place: this.state.birthPlace,
                full_address: this.state.customerAddress,
                full_name: this.state.fullName,
                id_card_image: this.state.imageIdCardOwner,
                id_card_number: this.state.idCardNumber,
                marital_status: "",
                occupation_code: "",
                occupation: "",
                sex: ""
            },
            id_card_guarantor: {
                birth_date: moment(this.state?.birthDate).format("YYYY-MM-DD"),
                birth_place: this.state.birthPlace,
                full_address: this.state.customerAddress,
                full_name: this.state.fullName,
                id_card_image: this.state.imageIdCardOwner,
                id_card_number: this.state.idCardNumber,
                marital_status: "",
                occupation_code: "",
                occupation: "",
                sex: ""
            },
            family_register_owner: {
                family_register_number: this.state.familyCardNumber
            },
            address_owner: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_code: this.state.customerDistrictCode,
                district_name: this.state.customerDistrictName,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode,
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            address_order_maker: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_code: this.state.customerDistrictCode,
                district_name: this.state.customerDistrictName,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode,
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            address_guarantor: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_code: this.state.customerDistrictCode,
                district_name: this.state.customerDistrictName,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode,
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            address_shipping: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_code: this.state.customerDistrictCode,
                district_name: this.state.customerDistrictName,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode,
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            vehicle: {
                alternative_color: this.state.alternativeColor,
                brand_name: "honda",
                brand_uuid: "afce6884-fc4d-4f7a-b784-09d2a2f7b903",
                model_name: this.state.vehicleModel,
                model_uuid: this.state.vehicleModelUuid,
                variant_name: this.state.vehicleVariantName,
                variant_uuid: this.state.vehicleVariantUuid,
                variant_code: this.state.vehicleVariantCode,
                color: this.state.vehicleVariantColorName,
                color_code: this.state.vehicleVariantColorCode,
            },
            credit: {
                otr: this.state.otr,
                dp_amount: this.state.downPayment,
                tenor: this.state.tenor,
                installment_amount: this.state.installmentAmount,
                finco_code: this.state.fincoChosenCode,
                finco_branch: "",
                finco_name: this.state.fincoChosenName,
                po_code: this.state.poCode,
                finco_comission: 1,
                po_track_number: this.state.poCode,
                po_time: "",
                po_image: this.state.imagePo,
                cancel_po_image: this.state.imageCancelDocument
            },
        }

        try {
            await offerApiServices.createOffer(
                dataOffer
            ).then(async successData => {
                window.location.href = `/spk/${successData.data.data.offer_code}`;
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                freezeStatus: true
            });
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        const contentInfoColor = (
            <div style={{fontSize: "85%", color: "gray"}}>
                <b>warna sesuai pesanan:</b> <span>warna kendaraan sesuai warna yang dipilih.</span><br/>
                <b>tanpa preferensi warna:</b> <span>warna kendaraan sesuai dengan stock yang tersedia di dealer. (memungkinkan warna random)</span>
            </div>
        );
        const items: MenuProps['items'] = [
            {
                key: '1',
                label: (
                    <span onClick={() => this.onChangeAlternativeColor('SAME_AS_ORDER', 'warna sesuai pesanan')}>
                        warna sesuai pesanan
                    </span>
                ),
            },
            {
                key: '2',
                label: (
                    <span onClick={() => this.onChangeAlternativeColor('NO_PREFERENCE', 'tanpa prferensi warna')}>
                        tanpa prferensi warna
                    </span>
                ),
            },
        ];

        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>

                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-10`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Order Memo AmartaHonda {this.state.areaCode}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className={`mt-20`}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>

                                <Divider plain={true} orientation="left">Kepemilikan Kendaraan</Divider>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Nomor KTP *</i>
                                    <Input
                                        maxLength={16}
                                        value={this.state.idCardNumber}
                                        placeholder="Nomor KTP"
                                        onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Nomor Telpon *</i>
                                    <Input
                                        value={this.state.phoneNumber}
                                        maxLength={17}
                                        placeholder="Format: 08xxxxxxxx"
                                        onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Nama Lengkap *</i>
                                    <Input value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemilik"/>
                                </div>


                                <div className={`mt-5`}>
                                    <i className={`text-small-grey-nopadding`}>Tempat Lahir *</i>
                                    <Input
                                        value={this.state.birthPlace}
                                        placeholder="Tempat Lahir Sesuai KTP"
                                        onChange={event => this.onFieldChange("birthPlace", event.target.value)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`text-small-grey-nopadding`}>Tanggal Lahir *</i>
                                    <DatePicker value={moment(this.state.birthDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("birthDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                </div>


                                <div className={`mt-5`}>
                                    <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'owner'} title={`KTP`} number={this.state.idCardNumber} name={this.state.fullName}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`text-small-grey-nopadding`}>Nomor Kartu Keluarga *</i>
                                    <Input
                                        maxLength={16}
                                        value={this.state.familyCardNumber}
                                        placeholder="Masukan Nomor KK Anda"
                                        onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`text-small-grey-nopadding`}>Alamat Pemilik *</i>
                                    <TextArea showCount maxLength={200} value={this.state.customerAddress} onChange={event => this.onFieldChange("customerAddress", event.target.value)} placeholder="Alamat"/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Provinsi*</i><br/>
                                    <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceName={this.state.customerProvinceName}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kota*</i><br/>
                                    <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.customerProvinceCode} cityName={this.state.customerCityName}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kecamatan*</i><br/>
                                    <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.customerCityCode} districtName={this.state.customerDistrictName}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kelurahan*</i><br/>
                                    <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.customerDistrictCode} subdistrictName={this.state.customerSubdistrictName}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kode Pos*</i><br/>
                                    <Input disabled={this.state.customerDisablePostalCode} value={this.state.customerPostalCode} onChange={event => this.onFieldChange("customerPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                </div>
                                <Row>
                                    <Col span={12} style={{paddingRight: '2px'}}>
                                        <div className={`mt-5`}>
                                            <i className={`text-small-grey-nopadding`}>RT</i>
                                            <Input maxLength={3} value={this.state.customerHamlet} onChange={event => this.onFieldChange("customerHamlet", event.target.value)} placeholder="RT"/>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{paddingLeft: '2px'}}>
                                        <div className={`mt-5`}>
                                            <i className={`text-small-grey-nopadding`}>RW</i>
                                            <Input maxLength={3} value={this.state.customerNeighbourhood} onChange={event => this.onFieldChange("customerNeighbourhood", event.target.value)} placeholder="RW"/>
                                        </div>
                                    </Col>
                                </Row><br/>


                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                                <Divider plain={true} orientation="left">Kendaraan</Divider>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Brand</i><br/>
                                    <Select
                                        onChange={value => this.onFieldChange("vehicleBrand", value)}
                                        value={this.state.vehicleBrand}
                                        style={{width: "100%"}}
                                        placeholder={`Pilih Merk Kendaraan`}
                                    >
                                        <Select.Option key={`HONDA`} value={`HONDA`}>{`Honda`}</Select.Option>
                                    </Select>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Model</i><br/>
                                    <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} companyCode={this.state.company} cityGroup={this.state.areaCode} value={this.state.vehicleModel} brandCode={this.state.vehicleBrand} category={"motorcycle"} time={Math.floor(+new Date() / 1000)}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Variant</i><br/>
                                    <VehicleVariantSelect onVehicleVariantChangeProps={this.onVehicleVariantChange} companyCode={this.state.company} cityGroup={this.state.areaCode} brandCode={this.state.vehicleBrand} modelCode={this.state.vehicleModel} value={this.state.vehicleVariantCode} time={Math.floor(+new Date() / 1000)}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Warna Variant</i><br/>
                                    <VehicleVariantColorSelect onVehicleVariantColorChangeProps={this.onVehicleVariantColorChange} companyCode={this.state.company} cityGroup={this.state.areaCode} brandCode={this.state.vehicleBrand} modelCode={this.state.vehicleModel} variantCode={this.state.vehicleVariantCode} value={this.state.vehicleVariantColorCode} time={Math.floor(+new Date() / 1000)}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Preferensi Warna</i><br/>
                                    <Row style={{marginTop: 5}}>
                                        <Col span={9} className={`text-small-green-nopadding`} style={{cursor: "pointer"}}>
                                            <Popover content={contentInfoColor} trigger="hover">
                                                Warna Alternatif
                                                <InfoCircleOutlined style={{fontSize: "100%", paddingLeft: 5}}/>
                                            </Popover>
                                        </Col>
                                        <Col span={13}>
                                            <Dropdown menu={{items}}>
                                                <Space style={{cursor: "pointer"}} className={`text-small-grey-nopadding`}>
                                                    {this.state.alternativeColor?.name}<DownOutlined/>
                                                </Space>
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Tahun Kendaraan</i><br/>
                                    <Select
                                        showSearch
                                        style={{width: "100%"}}
                                        placeholder="Pilih Tahun"
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("vehicleYear", value)}
                                        value={this.state.vehicleYear}
                                    >
                                        {Array.from({length: 7}, (_, index) => {
                                            const year = new Date().getFullYear() - 5 + index;
                                            return year;
                                        })
                                            .reverse()
                                            .map((year) => (
                                                <Select.Option key={year} value={year}>
                                                    {year}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </div>

                                <div className={`mt-5`}>
                                    <Checkbox onChange={e => this.onBooleanFieldChange("takeInDealer", e.target.checked)} checked={this.state.takeInDealer}>
                                        Saya ingin mengambil unit di dealer.
                                    </Checkbox>
                                </div>
                                <Divider plain={true} orientation="left">Credit</Divider>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Leasing</i><br/>
                                    <FincoSelect onFincoSelectChangeProps={this.onFincoSelectChange} value={this.state.fincoChosenCode}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Finco ID</i>
                                    <Input value={this.state.fincoId} onChange={event => this.onFieldChange("fincoId", event.target.value)} placeholder="Dapat di Kosongkan"/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>OTR</i>
                                    <CurrencyInput
                                        className={'ant-input'}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        style={{marginTop: 5}}
                                        placeholder={`DP`}
                                        value={this.state.otr}
                                        onValueChange={value => this.onFieldChange("otr", value ? parseFloat(value) : 0)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Uang Muka</i>
                                    <CurrencyInput
                                        className={'ant-input'}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        style={{marginTop: 5}}
                                        placeholder={`DP`}
                                        value={this.state.downPayment}
                                        onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                                        onBlur={this.blurDownPayment}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Tenor</i>
                                    <Select
                                        placeholder="Pilih Tenor"
                                        showSearch
                                        style={{width: "100%"}}
                                        optionFilterProp="children"
                                        onChange={value => this.onFieldChange("tenor", value)}
                                        value={this.state.tenor}
                                    >
                                        {this.state.tenorList.map((item: any, i: number) =>
                                            <Select.Option key={i} value={item}>{item}</Select.Option>
                                        )}
                                    </Select>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Angsuran</i>
                                    <CurrencyInput
                                        className={'ant-input'}
                                        groupSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'Rp. '}
                                        style={{marginTop: 5}}
                                        value={this.state.installmentAmount}
                                        onValueChange={value => this.onFieldChange("installmentAmount", value ? parseFloat(value) : 0)}
                                    />
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kode Promo</i><br/>
                                    <Input value={this.state.promoCode} placeholder="Promo" onChange={event => this.onFieldChange("promoCode", event.target.value)}/>
                                </div>
                                <br/>

                                <Divider plain={true} orientation="left">PO dan Dealer</Divider>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kode PO</i><br/>
                                    <Input value={this.state.poCode} placeholder="Kode PO" onChange={event => this.onFieldChange("poCode", event.target.value)}/>
                                </div>
                                <div className={`mt-5`}>
                                    <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'imagePo'} title={`Image PO`} number={randomString} name={``} hideInfo={true}/>
                                </div>
                                <div className={`mt-5`}>
                                    <UploadIdCardImage onImageUpdateProps={this.onImageUpdate} id={'imageCancelDocument'} title={`Image Cancel Document`} number={randomString} name={``} hideInfo={true}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Kode Dealer</i><br/>
                                    <Input value={this.state.dealerCode} placeholder="Kode Dealer" onChange={event => this.onFieldChange("dealerCode", event.target.value)}/>
                                </div>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Nama Dealer</i><br/>
                                    <Input value={this.state.dealerName} placeholder="Nama Dealer" onChange={event => this.onFieldChange("dealerName", event.target.value)}/>
                                </div>
                            </Col>
                            <Col xl={{span: 24}} lg={{span: 24}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{padding: 20}}>
                                <Button loading={this.state.freezeStatus} block={true} onClick={() => this.onClickSubmit()} type="primary" style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}}> Ajukan Order Memo</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <FooterSection areaCode={'bandung'} version={appVersion} lite={true}/>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}

export default withAuth(OrderMemo);