import Cookies from "universal-cookie";
import moment from "moment";
import axios from "axios";
import {baseUrlLogBq} from "../../config/apiConfig/apiConfig";
import {getUA, osName, osVersion, browserName, browserVersion, deviceType, mobileVendor, mobileModel} from 'react-device-detect';

const randomUUID = moment().format("YYYYMMDDHHmmss") + "-" + (new Date()).getTime().toString(36) + Math.random().toString(36).slice(2);

class PricelistBqLogService {
    private axios = axios.create({
        baseURL: baseUrlLogBq,
    });

    public async initTrackLog(params?: any) {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const cookies = new Cookies();
                let latCookies = cookies.get('_lat') ?? null;
                let lngCookies = cookies.get('_lng') ?? null;
                let ipCookies = cookies.get('_ip') ?? null;
                let uniqueDeal = cookies.get('_ud') ?? null;
                if (!latCookies || !lngCookies) {
                    cookies.set('_lat', position.coords.latitude, {maxAge: 216000});
                    cookies.set('_lng', position.coords.longitude, {maxAge: 216000});
                }
                if (!ipCookies) {
                    cookies.set('_ip', null, {maxAge: 216000});
                }
                if (!uniqueDeal) {
                    cookies.set('_ud', randomUUID, {maxAge: 716000});
                }
                await this.sendTrackLog(params);
            },
            async (err) => {
                await this.sendTrackLog(params);
            }
        );
    }

    sendTrackLog = async (params: any) => {
        const cookies = new Cookies();
        let latCookies = cookies.get('_lat') ?? null;
        let lngCookies = cookies.get('_lng') ?? null;
        let ipCookies = cookies.get('_ip') ?? null;
        const ipAddr = (ipCookies === "null") ? null : ipCookies;
        let uniqueDeal = cookies.get('_ud') ?? null;

        const dataBody = {
            event: {
                time: moment().format("YYYY-MM-DD HH:mm:ss"),
                source_url: params?.sourceUrl,
                category: params?.category,
                name: params?.name,
                parameter: params?.parameter,
                score: (params?.score) ? Number(params?.score) : null,
            },
            user_data: {
                client_ip_address: ipAddr,
                client_user_agent: getUA?.toString(),
                client_os: osName?.toString()?.toLowerCase() ?? null,
                client_os_version: osVersion?.toString()?.toLowerCase() ?? null,
                client_browser_name: browserName?.toString()?.toLowerCase() ?? null,
                client_browser_type: browserVersion?.toString()?.toLowerCase() ?? null,
                client_device_type: deviceType?.toString()?.toLowerCase() ?? null,
                client_device_vendor: mobileVendor?.toString()?.toLowerCase() ?? null,
                client_device_model: mobileModel?.toString()?.toLowerCase() ?? null,
                cookies: {
                    deal: uniqueDeal,
                    deal_season: params?.offerCode
                },
                location_latitude: parseFloat(latCookies),
                location_longitude: parseFloat(lngCookies)
            },
            custom_data: {
                search_target: null,
                search_info: null,
                campaign_code: params?.dealCode,
                campaign_data: params?.dealData ?? null,
            }
        }

        try {
            await this.axios.post<any>('/log/pricelsit.amartahonda.com',
                dataBody, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": ""
                    }
                });
        } catch (e: any) {
            console.log(e);
        }
    }
}

export const pricelistBqLogService = new PricelistBqLogService();