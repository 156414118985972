import React, {Component} from "react";
import {MicroPagesStates} from "./types/MicroPageTypes";
import {withRouter} from "../../hoc/withRouter";
import parse from 'html-react-parser';
import Empty from "antd/lib/empty";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Layout from "antd/lib/layout";
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Skeleton from "antd/lib/skeleton";
import Tag from "antd/lib/tag";
import Card from "antd/lib/card";
import Meta from "antd/lib/card/Meta";
import {trackLogServices} from "../../services/track/TrackLogService";

import moment from "moment";

class MicroPage extends Component<any, MicroPagesStates> {
    private readonly initState!: MicroPagesStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const microPageCode = match.params.micropagecode;
        document.title = `Amartahonda | Page | ${microPageCode}`;

        this.initState = {
            microPageCode: microPageCode,
            dataMicroPage: {},
            dataAnotherPage: [],
            fetchMicroPage: true,
        }
        this.state = {...this.initState}
    }

    fetchDetailMicroPage = async () => {
        try {
            await trimitraCatalogServices.getDetailMicroPage({
                title: this.state.microPageCode
            }).then(async dataResp => {
                await this.promisedSetState({
                    fetchMicroPage: false,
                    dataMicroPage: dataResp.data.data,
                });
            });
        } catch (e) {
            this.setState({
                fetchMicroPage: false,
                dataMicroPage: null,
            });
        }
    }

    fetchMicroPage = async () => {
        const tag = this.state.dataMicroPage?.tags[0];
        try {
            await trimitraCatalogServices.getListMicroPage({
                tags: tag
            }).then(dataResp => {
                this.setState({
                    dataAnotherPage: dataResp.data.data,
                });
            });
        } catch (e) {
            this.setState({
                dataAnotherPage: []
            });
        }
    }

    async componentDidMount() {
        await this.fetchDetailMicroPage();
        await this.fetchMicroPage();
        await trackLogServices.sendTrackLog({
            eventName: `micro page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                        <div className={`variant-header`}>
                            <LeftOutlined onClick={() => this.props.history.goBack()} style={{fontSize: '150%', cursor: "pointer", fontWeight: "bold"}}/>
                            <img className={`header-logo`} style={{height: '40px', marginLeft: 25, marginBottom: 10}} src={'../assets/img/favicon.png'} alt={`amartahonda-logo`}/>
                            <b style={{color: '#64b48f', fontSize: '125%'}}>AMARTAHONDA</b>
                        </div>

                        <div style={{minHeight: '92vh', backgroundColor: "#fff"}}>
                            <div style={(this.state.fetchMicroPage) ? {padding: 30} : {display: 'none'}}>
                                <Skeleton active={true} avatar paragraph={{rows: 12}}/>
                            </div>
                            <div style={(!this.state.fetchMicroPage && this.state.dataMicroPage === null) ? {paddingTop: 20} : {display: 'none'}}>
                                <Empty description={`Artikel tidak ditemukan`}/>
                            </div>
                            <div style={(!this.state.fetchMicroPage && this.state.dataMicroPage !== null) ? {paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20} : {display: 'none'}}>
                                <h2 style={{textTransform: "capitalize"}}>{this.state.dataMicroPage?.title?.replace(/-/g, ' ')}</h2>
                                <i style={{fontSize: '75%', float: "right"}}>{moment(this.state.dataMicroPage?.create_time).format('dddd, MMMM Do YYYY, h:mm a')}</i>
                                <br/><br/>
                                <img alt={`imageamarta`} width={'100%'} src={this.state.dataMicroPage?.image}/>
                                <br/><br/>
                                {parse(this.state.dataMicroPage?.page ?? "")}
                                <br/>
                                {(this.state.dataMicroPage?.tags ?? []).map((item: any, i: number) =>
                                    <Tag key={i} color="grey">{item}</Tag>
                                )}
                                <br/><br/><br/><br/>

                                Halaman Terkait:<br/><br/>
                                <Row>
                                    {(this.state.dataAnotherPage ?? []).map((item: any, x: number) =>
                                        <Col key={x} span={12} style={(item.title !== this.state.dataMicroPage?.title) ? {padding: 10} : {display: 'none'}}>
                                            <a href={`https://amartahonda.com/p/${item.title}`}>
                                                <Card
                                                    hoverable
                                                    style={{width: '100%'}}
                                                    cover={<img alt="example" src={item.thumbnail}/>}
                                                    bodyStyle={{padding: 3, paddingTop: 5, fontSize: "80%"}}
                                                >
                                                    <Meta style={{padding: 0, margin: 0}} title={item.title?.replace(/-/g, ' ')} description={item.description.substring(0, 50) + "..."}/>
                                                </Card>
                                            </a>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withRouter(MicroPage);
