import React, {Component} from "react";
import {withRouter} from "../../hoc/withRouter";
import {NewVehicleVariantStates} from "./types/NewVehicleVariantDetailTypes";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import variantCodeUrlFormat from "../../helper/variantCodeUrlFormat";
import currencyFormat from "../../helper/currencyFormat";
import titleCase from "../../helper/titleCase";
import navigatorShare from "../../helper/navigatorShare";
import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';
import DetailProductLink from "../../components/product/DetailProductLink";
import {trackLogServices} from "../../services/track/TrackLogService";
import StartBookmark from "../../components/bookmarkAndSubsciption/StartBookmark";
import NormalHeader from "../../components/layouts/NormalHeader";
import WhatsAppOutlined from "@ant-design/icons/WhatsAppOutlined";
import {Link} from "react-router-dom";
import whatsappLink from "../../helper/whatsappLink";
import Cookies from 'universal-cookie';
import FooterSection from "../../components/footer/FooterSection";
import {Divider, Button, Row, Col, Image, Modal, Spin} from "antd";

import MultipleStockDealer from "../../components/product/MultipleStockDealer";

class NewVehicleVariantDetail extends Component<any, NewVehicleVariantStates> {
    private readonly initState!: NewVehicleVariantStates;

    constructor(props: any) {
        super(props);

        const {match} = this.props;
        const areaCode = match.params.area;
        const variantCode = variantCodeUrlFormat(match.params.productcode);
        document.title = `Amartahonda | Detail Variant Kendaraan Baru | ${areaCode} | ${variantCode}`;

        this.initState = {
            areaCode: areaCode,
            cityCode: "",
            variantCode: variantCode,
            showOfferCard: false,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            oldParameterCash: this.props.location.search ?? "",
            oldParameterCredit: this.props.location.search ?? "",
            parameterCash: this.props.location.search ?? "",
            parameterCredit: this.props.location.search ?? "",
            fetchStock: true,
            stockData: {},
            multipleStockData: [],
            isDesktop: false,
            desktopShowFixedBuyButton: false,
            modalAnotherDealer: false,
            chosenArea: "",
            chosenShipmentCost: 0,
            chosenTextBadge: "",
            chosenColorBadge: ""
        }
        this.state = {...this.initState}
    }

    handleScroll = () => {
        if (this.state.isDesktop) {
            if (window.scrollY >= 400) {
                this.setState({desktopShowFixedBuyButton: true})
            } else {
                this.setState({desktopShowFixedBuyButton: false})
            }
        }
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({isDesktop: false})
        } else {
            this.setState({isDesktop: true})
        }
    }

    fetchStock = async () => {
        trimitraCatalogServices.getStock({areaCode: this.state.areaCode, vehicleVariant: this.state.variantCode})
            .then(dataFetchStock => {
                this.setState({stockData: dataFetchStock?.data})
            }).catch(e => {
            this.setState({stockData: {}})
        })
    }

    fetchMultipleStock = async () => {
        trimitraCatalogServices.getMultipleStock({vehicleVariant: this.state.variantCode, cityCode: this.state.cityCode})
            .then(dataFetchStock => {
                this.setState({
                    multipleStockData: dataFetchStock?.data?.data,
                    fetchStock: false
                })
            }).catch(e => {
            this.setState({
                fetchStock: false
            })
        })
    }

    async componentDidMount() {
        try {
            const listCity = await trimitraCatalogServices.getCity({name: this.state.areaCode});
            this.setState({
                cityCode: listCity.data.data?.[0]?.city_code
            })
        } catch (e) {
            console.log(e)
        }

        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, 10)
        window.addEventListener("resize", this.handleResize)
        window.addEventListener('scroll', this.handleScroll)
        this.handleResize()

        try {
            trimitraCatalogServices.getCatalogVariant({
                areaCode: this.state.areaCode,
                variantCode: this.state.variantCode
            }).then(async dataVariantDetail => {
                if (dataVariantDetail.data.data.length === 0) {
                    this.props.history.push("/notfound")
                    return false
                }

                await this.promisedSetState({
                    listNewVariantColor: dataVariantDetail.data.data,
                    chosenNewVariantColor: dataVariantDetail?.data?.data[0]
                })

                this.fetchStock();

                this.fetchMultipleStock();

                let lastSeenVariant: any = await localStorage.getItem('lastSeenVariant');
                lastSeenVariant = lastSeenVariant ? JSON.parse(lastSeenVariant) : [];
                const picked = lastSeenVariant.find((o: { code: string; }) => o.code === this.state.variantCode);
                if (!picked) {
                    lastSeenVariant.push({
                        code: this.state.variantCode,
                        type: "variant",
                        name: dataVariantDetail.data?.meta?.VariantName,
                        url_image: dataVariantDetail.data?.meta?.UrlImage,
                        area: this.state.areaCode,
                        link: window.location.href,
                        time: Math.floor(Date.now() / 1000),
                    });

                    lastSeenVariant.sort((a: any, b: any) => (a.time < b.time) ? 1 : -1)
                    await localStorage.setItem('lastSeenVariant', JSON.stringify(lastSeenVariant.slice(0, 6)));
                }
            });
        } catch (e) {
            this.props.history.push("/notfound");
        }

        trackLogServices.sendTrackLog({
            eventName: `detail variant page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    onClickShare(productTitle: any) {
        navigatorShare(productTitle, "Dapatkan penawaran terbaik di amartahonda.com ", window.location.href);
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        await this.props.history.push(`/baru/${this.state.areaCode.toLowerCase()}/${this.state.variantCode?.replace(/\//g, '')}`);
        window.location.reload();
    }

    onClickInfo(productTitle: any) {
        const cookies = new Cookies();
        const token = cookies.get('_ga')?.split('.');
        let gaId = '';
        if (token !== undefined) {
            gaId = (token.length > 2) ? (token[2] + '.' + token[3]) : "";
        }
        whatsappLink('628112341361', `Halo, Saya tertarik dengan ${productTitle} %0a${window.location.href}?clid=${gaId}`);
    }

    onClickMultipleDealerStock = async (data: any) => {
        this.setState({
            parameterCash: (this.state.parameterCash) ? `${this.state.parameterCash}&stockarea=${data.data.city_group?.toLowerCase()}` : `?stockarea=${data.data.city_group?.toLowerCase()}`,
            parameterCredit: (this.state.parameterCredit) ? `${this.state.parameterCredit}&stockarea=${data.data.city_group?.toLowerCase()}` : `?stockarea=${data.data.city_group?.toLowerCase()}`,
            chosenArea: data.data.city_group,
            chosenShipmentCost: data.data.shipment_cost,
            chosenTextBadge: data.textBadge,
            chosenColorBadge: data.colorBadge,
            modalAnotherDealer: false,
        })
    }

    onChangeColor = async (index: number) => {
        this.setState({
            parameterCash: this.state.oldParameterCash,
            parameterCredit: this.state.oldParameterCredit,
            chosenArea: "",
            chosenShipmentCost: 0,
            chosenTextBadge: "",
            chosenColorBadge: "",
            chosenNewVariantColor: this.state.listNewVariantColor[index]
        })
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        let textBadge = "";
        let colorBadge = "#64b48f";
        if (this.state?.stockData?.data?.custom_availability) {
            textBadge = this.state?.stockData?.data?.custom_availability;
        } else {
            const stockColor = this.state?.stockData?.data?.color[this.state.chosenNewVariantColor?.color_code] ?? 0;
            if (stockColor === 0) {
                textBadge = 'NA / Pre-Order ';
                colorBadge = "#e57373";
            } else if (stockColor < this.state?.stockData?.meta?.availability_limit) {
                textBadge = 'Tersisa ' + stockColor + ' unit ';
                colorBadge = "#64b48f";
            } else {
                textBadge = 'Ready ';
                colorBadge = "#2196f3";
            }
        }

        return (
            <>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>

                <div style={this.state.fetchStock ? {paddingTop: 200, paddingBottom: 200, textAlign: "center"} : {display: 'none'}}>
                    <Spin size="large"/>
                </div>

                <div style={!this.state.fetchStock ? {paddingTop: 20} : {display: 'none'}}>


                    <Row>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Row>
                                <Col style={this.state.isDesktop ? {padding: 20} : {paddingLeft: 20, paddingRight: 20}} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <Row>
                                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                                            <Image width={`100%`} preview={false} className={`carousel-img`} src={this.state.chosenNewVariantColor?.url_image} alt={`product-image`}/>
                                        </Col>
                                    </Row>
                                    <Row justify="center" className={`row-thumb-prev`} style={{display: "none"}}>
                                        {this.state.listNewVariantColor.map((item: any, i: number) =>
                                            <Col span={4} key={i}>
                                                <div className={`card-preview-thumb-product` + (this.state?.chosenNewVariantColor?.color_code === item.color_code ? '-active' : '')}>
                                                    <div onClick={() => this.onChangeColor(i)}>
                                                        <Image preview={false} src={item.url_image} alt={`amartahonda-logo`}/>
                                                        <b style={{display: 'none'}}>{item.color_name}</b>
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                <Col style={this.state.isDesktop ? {padding: 20} : {paddingLeft: 20, paddingRight: 20}} xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <div style={this.state.isDesktop ? {paddingTop: 70} : {display: 'none'}}/>
                                    <StartBookmark
                                        bookmarkCode={"VEHICLE_VARIANT#" + this.state.variantCode}
                                        bookmarkUrl={window.location.href}
                                        bookmarkTitle={this.state.chosenNewVariantColor?.variant_name}
                                        bookmarkArea={this.state.areaCode}
                                        bookmarkType={"VEHICLE_VARIANT"}
                                        bookmarkProductCode={this.state.variantCode}
                                    />
                                    <div onClick={() => this.onClickShare(this.state.chosenNewVariantColor?.variant_name)} style={{float: "right", marginBottom: 30, marginRight: 20, cursor: "pointer", fontSize: "150%"}}><ShareAltOutlined/> <span style={{fontSize: "80%"}}>Share</span></div>
                                    <br/>
                                    <div className={`pt-20`}/>
                                    <div className={`pt-10 card-promo-caption`}>{this.state.chosenNewVariantColor?.variant_code}</div>
                                    <div className={`pt-7 detail-product-title`}>{titleCase(this.state.chosenNewVariantColor?.variant_name)}</div>
                                    <div className={`pt-10 card-promo-caption`}>Pilihan Warna: <b>{titleCase(this.state.chosenNewVariantColor?.color_name)}</b></div>
                                    <Row>
                                        {this.state.listNewVariantColor.map((item: any, i: number) =>
                                            <Col key={i} onClick={() => this.onChangeColor(i)}>
                                                <Row className={`color-preview-thumb-product` + (this.state?.chosenNewVariantColor?.color_code === item.color_code ? '-active' : '')}>
                                                    <Col span={24}>
                                                        <div style={{verticalAlign: "middle", paddingLeft: 7, paddingRight: 7, paddingTop: 0}}>{titleCase(item.color_name)}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>

                                    <div className={`pt-10 card-promo-dealer`}>
                                        Wilayah {titleCase((this.state.chosenArea) ? this.state.chosenArea : this.state.areaCode)}
                                        <i style={{paddingLeft: 10, color: (this.state.chosenColorBadge) ? this.state.chosenColorBadge : colorBadge}}>{(this.state.chosenTextBadge) ? this.state.chosenTextBadge : textBadge}</i>
                                    </div>
                                    <div className={`pt-3`}>
                                        <span style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 detail-product-price`}>
                                            {(this.state.chosenShipmentCost) ? currencyFormat((this.state.chosenShipmentCost + this.state.chosenNewVariantColor?.price)) : currencyFormat(this.state.chosenNewVariantColor?.price)}
                                        </span>
                                        <span style={!this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 detail-product-price`}>Not Available </span>
                                        <span onClick={() => this.setState({modalAnotherDealer: true})} style={this.state.chosenNewVariantColor?.price ? {fontSize: "90%", color: "#64b48f", fontStyle: "italic", float: "right", cursor: "pointer"} : {display: 'none'}} className={`pt-10`}>Lihat Stock Wilayah Lain</span>
                                    </div>
                                    <div className={`pt-20`}/>
                                    <div style={this.state.isDesktop && this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}}>
                                        <Button onClick={() => this.onClickInfo(this.state.chosenNewVariantColor?.variant_name)} icon={<WhatsAppOutlined/>} size={'large'} style={{background: "#64b48f", color: "#fff", width: '15%'}}/>
                                        <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/kredit${(this.state.parameterCredit) ? this.state.parameterCredit : ''}`}>
                                            <Button danger size={'large'} style={{fontSize: "90%", marginLeft: '2%', width: '40%'}}>Ajukan Kredit</Button>
                                        </Link>
                                        <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/tunai${(this.state.parameterCash) ? this.state.parameterCash : ''}`}>
                                            <Button type="primary" danger size={'large'} style={{fontSize: "90%", marginLeft: '2%', width: '37%'}}> Beli Tunai</Button>
                                        </Link>
                                    </div>

                                    <div style={this.state.isDesktop && this.state.desktopShowFixedBuyButton ? {} : {display: 'none'}} className={`fixed-top-button-scroll`}>
                                        <div className={`pt-10 detail-product-price`}>{titleCase(this.state.chosenNewVariantColor?.variant_name)}</div>
                                        <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 card-promo-caption`}>
                                            {(this.state.chosenShipmentCost) ? currencyFormat((this.state.chosenShipmentCost + this.state.chosenNewVariantColor?.price)) : currencyFormat(this.state.chosenNewVariantColor?.price)}
                                        </div>
                                        <div style={!this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}} className={`pt-10 card-promo-caption`}>Not Available</div>
                                        <div className={`pt-10`}/>
                                        <div style={this.state.chosenNewVariantColor?.price ? {} : {display: 'none'}}>
                                            <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/kredit${(this.state.parameterCredit) ? this.state.parameterCredit : ''}`}>
                                                <Button type="primary" danger size={'small'} style={{width: "100%"}}> Beli Tunai</Button>
                                            </Link>
                                            <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/tunai${(this.state.parameterCash) ? this.state.parameterCash : ''}`}>
                                                <Button danger size={'small'} style={{width: "100%", marginTop: 10}}>Ajukan Kredit</Button>
                                            </Link>
                                        </div>
                                    </div>

                                    <div style={!this.state.isDesktop ? {} : {display: 'none'}} className={`fixed-bottom-button`}>
                                        <Button onClick={() => this.onClickInfo(this.state.chosenNewVariantColor?.variant_name)} icon={<WhatsAppOutlined/>} size={'large'} style={{background: "#64b48f", color: "#fff", width: '10%'}}/>
                                        <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/kredit${(this.state.parameterCredit) ? this.state.parameterCredit : ''}`}>
                                            <Button danger size={'large'} style={{fontSize: "90%", marginLeft: '2%', width: '42%'}}>Ajukan Kredit</Button>
                                        </Link>
                                        <Link to={`/baru/${this.state.areaCode}/${this.state.variantCode?.replace('/', '') + "-" + this.state.chosenNewVariantColor?.color_code}/tunai${(this.state.parameterCash) ? this.state.parameterCash : ''}`}>
                                            <Button type="primary" danger size={'large'} style={{fontSize: "90%", marginLeft: '2%', width: '42%'}}> Beli Tunai</Button>
                                        </Link>
                                    </div>

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider style={{marginTop: 3, marginBottom: 3}}></Divider>

                    <Row style={{paddingTop: 40}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <Row>
                                <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <DetailProductLink target={`_self`} icon={`CalculatorOutlined`} title={`Simulasi Cicilan`} description={`Dapatkan informasi penawaran kredit dari berbagai leasing.`} link={`/baru/${this.state.areaCode.toLowerCase()}/${this.state.variantCode?.replace(/\//g, '')}/simulasi-kredit`}/>
                                </Col>
                                <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <DetailProductLink target={`_blank`} icon={`SyncOutlined`} title={`Tukar Tambah`} description={`Tukarkan motor lama Anda dengan motor ini dengan mudah`} link={`/tukar-tambah-motor?variant_code=${this.state.variantCode}&area=${this.state.areaCode.toLowerCase()}`}/>
                                </Col>
                                <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <DetailProductLink target={`_self`} icon={`DashboardOutlined`} title={`Detail Produk`} description={`Informasi lengkap seputar kendaraan`} link={`/baru/${this.state.areaCode.toLowerCase()}/${this.state.variantCode.replace(/\//g, '')}/detail`}/>
                                </Col>
                                <Col xxl={{span: 12}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                    <a href={`/${this.state.areaCode.toLowerCase()}/promo?model=${this.state.listNewVariantColor[0]?.model_name?.toLowerCase()?.replace(/ /g, '')}`}>
                                        <DetailProductLink target={`_self`} icon={`PercentageOutlined`} title={`Promo`} description={`Informasi lengkap seputar promo`} link={null}/>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div style={{height: 50}}/>
                <FooterSection areaCode={this.state.areaCode}/>

                <Modal
                    title="Pilihan Wilayah Lainnya"
                    open={this.state.modalAnotherDealer}
                    onCancel={() => this.setState({modalAnotherDealer: false})}
                    footer={null}
                >
                    <div className={`detail-product-title`}>{titleCase(this.state.chosenNewVariantColor?.variant_name)}</div>
                    <div className={`pt-3 card-promo-caption`}>Pilihan Warna: <b>{titleCase(this.state.chosenNewVariantColor?.color_name)}</b></div>

                    <Divider/>
                    <MultipleStockDealer onClickMultipleDealerStock={this.onClickMultipleDealerStock} multipleStockData={this.state.multipleStockData} colorCode={this.state.chosenNewVariantColor?.color_code} price={this.state.chosenNewVariantColor?.price}/>
                    <br/><i className={`card-promo-caption`}>*Biaya Ongkir dapat berubah sewaktu-waktu </i>
                </Modal>
            </>
        );
    }
}

export default withRouter(NewVehicleVariantDetail);