import React, {Component} from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {DivClassProduct} from "./DivClassProduct";
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import {CardProduct} from "./CardProduct";
import {Link} from "react-router-dom";
import configHomeArea from "../../config/setupConfig/homeInitConditionConf";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";

class AllNewModelProduct extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            listNewProduct: [],
            listNewProductFiltered: [],
            newProductClass: "",
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        await this.promisedSetState({
            newProductClass: configHomeArea.newProductClass.toUpperCase(),
        });
        if (this.props.showAllNewModelProduct) {
            await this.fetchAllModelProduct();
        }
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.areaCode !== prevProps.areaCode || this.props.showAllNewModelProduct !== prevProps.showAllNewModelProduct) {
            if (this.props.showAllNewModelProduct) {
                await this.fetchAllModelProduct();
            }
        }
    }

    fetchAllModelProduct = async () => {
        try {
            await trimitraCatalogServices.getCatalogModel({
                areaCode: this.props.areaCode,
            }).then(async dataModelHome => {
                await this.promisedSetState({
                    listNewProduct: dataModelHome.data.data ?? []
                });
                await this.setupListModelProductFiltered();
            });
        } catch (e) {
            console.log(e)
        }
    }

    onClassModelProductChange = async (data: string) => {
        await this.promisedSetState({
            newProductClass: data
        });
        await this.setupListModelProductFiltered();
    }

    setupListModelProductFiltered = async () => {
        let filteredData: any[] = [];
        if (this.state.newProductClass === 'ALL') {
            filteredData = this.state.listNewProduct
        } else {
            this.state.listNewProduct.forEach((el: any) => {
                if ((el.class === "scooter" || el.class === "scooter-high") && (this.state.newProductClass === 'MATIC')) {
                    filteredData.push(el);
                } else if ((el.class === "cub") && (this.state.newProductClass === 'CUB')) {
                    filteredData.push(el);
                } else if ((el.class === "sport" || el.class === "sport-low") && (this.state.newProductClass === 'SPORT')) {
                    filteredData.push(el);
                }
            });
        }
        await this.promisedSetState({
            listNewProductFiltered: filteredData
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className={`chosen-product`}>
                    <span className={`chosen-product-text`}>MOTOR BARU</span>
                    <Link to={`/baru/${this.props.areaCode.toLowerCase()}/variant`}><span className={`chosen-product-other`}>Lainya <DoubleRightOutlined/></span></Link>
                </div>

                <DivClassProduct newProductClass={this.state.newProductClass} onClickClass={this.onClassModelProductChange}/>

                <Row className={`home-row-chosen-product`}>
                    {
                        this.state.listNewProductFiltered.map((item: any, i: number) =>
                            <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                <CardProduct option={'NEW_MODEL'} productImageUrl={item.url_image} product={item.model_name} productArea={this.props.areaCode} productName={item.model_name} productPrice={item.price_min} productStrikethroughtPrice={item.price_strikethrough}/>
                            </Col>
                        )
                    }
                </Row>
            </React.Fragment>
        );
    }
}

export default AllNewModelProduct;
