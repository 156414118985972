import React, {Component} from "react";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {Select} from "antd";

class VehicleVariantColorSelect extends Component<any, any> {
    private initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            brandCode: "",
            modelCode: "",
            variantCode: "",
            dataVehicleVariantColor: [],
            selectVehicleVariantValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleVariantData = (variantCode: any) => {
        if(variantCode){
            // FETCH API
            try {
                trimitraCatalogServices.getCatalogVariant({
                    variantCode: variantCode,
                    areaCode: "BANDUNG"
                }).then(dataFetchModel => {
                    const dataForState: any = dataFetchModel.data.data.map((variant: any) => ({
                        value: variant.color_code,
                        name: variant.color_name,
                    }));
                    this.setState({
                        variantCode: variantCode,
                        dataVehicleVariantColor: dataForState,
                    });
                });
            } catch (e) {
                this.setState({
                    variantCode: variantCode,
                    dataVehicleVariantColor: [],
                    selectVehicleVariantValue: null,
                });
            }
        }else{
            this.setState({
                variantCode: "",
                dataVehicleVariantColor: [],
                selectVehicleVariantValue: null,
            });
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });

        this.props.onVehicleVariantColorChangeProps(option);
    }

    async componentDidMount() {
        await this.promisedSetState({
            variantCode: this.props.variantCode
        });
        await this.fetchVehicleVariantData(this.props.variantCode);
        await this.onValueChange(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.brandCode !== prevProps.brandCode) {
            this.fetchVehicleVariantData("");
        }
        if (this.props.modelCode !== prevProps.modelCode) {
            this.fetchVehicleVariantData("");
        }
        if (this.props.variantCode !== prevProps.variantCode) {
            this.fetchVehicleVariantData(this.props.variantCode);
        }
        if (this.props.time !== prevProps.time) {
            this.onValueChange(this.props.value);
        }
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Warna Variant Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                style={{width: "100%"}}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.selectVehicleVariantValue}
            >
                {
                    this.state.dataVehicleVariantColor.map((item: any, i: number) =>
                        <Select.Option key={item?.value} value={item?.value}>{item?.name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default VehicleVariantColorSelect;
