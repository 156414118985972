import React, {Component} from "react";
import HeartTwoTone from "@ant-design/icons/HeartTwoTone";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import TextArea from "antd/es/input/TextArea";
import {bookmarkApiService} from "../../services/amartaVip/BookmarkApiService";
import Cookies from "universal-cookie";

class StartBookmark extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            showModalBookmarkLogin: false,
            showModalBookmarkAdd: false,
            processBookmarkAdd: false,
            amartaVipToken: "",
            bookmarkNotes: "",
        }
        this.state = {...this.initState}
    }

    private cookies = new Cookies();

    async onClickBookmark() {
        const isExistAmartaVipToken = await authApiService.isExistAmartaVipToken();
        if (!isExistAmartaVipToken) {
            this.setState({
                showModalBookmarkLogin: true,
                bookmarkNotes: "",
            });
        } else {
            await this.promisedSetState({
                showModalBookmarkAdd: true,
                amartaVipToken: isExistAmartaVipToken,
                bookmarkNotes: "",
            });
        }
    }

    async onClickAddBookmark() {
        this.setState({
            processBookmarkAdd: true,
        });

        try {
            authApiService.setToken(this.state.amartaVipToken);
            await authApiService.checkJwt();
        } catch (e) {
            await authApiService.removeAllToken();
            setTimeout(() => {}, 100);
            await authApiService.removeAllToken();

            setTimeout(() => {
                this.setState({
                    processBookmarkAdd: false,
                    showModalBookmarkAdd: false,
                    showModalBookmarkLogin: true
                });
            }, 1000);

            return;
        }

        const dataBookmark = {
            source: "amartahonda",
            code: this.props.bookmarkCode,
            url: this.props.bookmarkUrl,
            title: this.props.bookmarkTitle,

            url_image: "",
            url_thumb_image: "",
            notes: this.state.bookmarkNotes,
            custom_data: {
                area: this.props.bookmarkArea,
                type: this.props.bookmarkType,
                code: this.props.bookmarkProductCode,
            }
        }

        bookmarkApiService.setToken(this.state.amartaVipToken);
        try {
            await bookmarkApiService.createBookmark(dataBookmark);
            this.setState({
                processBookmarkAdd: false,
                showModalBookmarkAdd: false,
            });

            Modal.success({
                title: 'Proses Sukses',
                content: 'data kendaraan telah di bookmarkAndSubsciption'
            });
        } catch (e) {
            Modal.error({
                title: 'Register Failed',
                content: 'error: ' + e,
            });

            this.setState({
                processBookmarkAdd: false,
                showModalBookmarkAdd: false,
            });
        }
    }

    onFieldChange = <T extends keyof any>(target: T, value: string) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "bookmarkNotes":
                currentState.bookmarkNotes = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }
    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div className={`header-right`} style={{marginRight: "10%", paddingTop: 5}}><HeartTwoTone twoToneColor="#eb2f96" onClick={() => this.onClickBookmark()} style={{fontSize: '175%', cursor: "pointer"}}/></div>
                <Modal title={"Tambahkan kedalam Bookmark"}
                       open={this.state.showModalBookmarkAdd}
                       onCancel={() => {
                           this.setState({showModalBookmarkAdd: false})
                       }}
                       onOk={() => {
                           this.setState({showModalBookmarkAdd: false})
                       }}
                       footer={[
                           <Button loading={this.state.processBookmarkAdd} key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                               this.onClickAddBookmark()
                           }}>Tambahkan Bookmark</Button>,
                           <Button key="close" onClick={() => {
                               this.setState({showModalBookmarkAdd: false})
                           }}>Cancel</Button>
                       ]}
                >
                    Anda Yakin akan menambahkan bookmark dengan kendaraan ini?<br/><br/>
                    <TextArea showCount maxLength={200} value={this.state.bookmarkNotes} onChange={event => this.onFieldChange("bookmarkNotes", event.target.value)} placeholder="Catatan jika dibutuhkan"/><br/>
                </Modal>

                <Modal
                    title={"Tambahkan kedalam Bookmark"}
                    open={this.state.showModalBookmarkLogin}
                    onCancel={() => {
                        this.setState({showModalBookmarkLogin: false})
                    }}
                    onOk={() => {
                        this.setState({showModalBookmarkLogin: false})
                    }}
                    footer={[
                        <Button key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            window.location.href = "/login";
                        }}>Login / Daftar AmartaVIP</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalBookmarkLogin: false})
                        }}>Cancel</Button>
                    ]}
                >
                    Anda perlu login terlebih dahulu
                </Modal>
            </React.Fragment>
        );
    }
}

export default StartBookmark;
