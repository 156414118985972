import React, {Component} from "react";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {Select} from "antd";

class VehicleVariantSelect extends Component<any, any> {
    private initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            modelCode: "",
            brandCode: "",
            dataVehicleVariant: [],
            selectVehicleVariantValue: null,
        }
        this.state = {...this.initState}
    }

    fetchVehicleVariantData = async (modelCode: any, showUnActive: any) => {
        if (modelCode) {
            try {
                await trimitraCatalogServices.getCatalogVariant({
                    areaCode: "BANDUNG",
                    modelName: modelCode,
                    showUnActive: this.props.showUnActive,
                }).then(async dataFetchModel => {
                    const dataForState: any = dataFetchModel.data.data.map((variant: any) => ({
                        value: variant.variant_code,
                        name: variant.variant_name + " - " + variant.variant_code,
                        uuid: variant.variant_uuid,
                        variant_name: variant.variant_name,
                        price: variant.price,
                    }));
                    this.setState({
                        modelCode: modelCode,
                        dataVehicleVariant: dataForState,
                    });
                });
            } catch (e: any) {
                this.setState({
                    modelCode: modelCode,
                    dataVehicleVariant: [],
                    selectVehicleVariantValue: null,
                });
            }
        } else {
            this.setState({
                modelCode: "",
                dataVehicleVariant: [],
                selectVehicleVariantValue: null,
            });
        }

    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });
        const picked = this.state.dataVehicleVariant.find((o: { value: any; }) => o.value === option.value);
        option.uuid = picked.uuid;
        option.variant_name = picked.variant_name;
        option.price = picked.price;
        this.props.onVehicleVariantChangeProps(option);
    }

    async componentDidMount() {
        await this.promisedSetState({
            modelCode: this.props.modelCode
        });
        await this.fetchVehicleVariantData(this.props.modelCode, this.props.showUnActive);
        await this.onValueChange(this.props.value);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.modelCode !== prevProps.modelCode) {
            this.fetchVehicleVariantData(this.props.modelCode, this.props.showUnActive);
        }
        if (this.props.brandCode !== prevProps.brandCode) {
            this.fetchVehicleVariantData("", this.props.showUnActive);
        }
        if (this.props.time !== prevProps.time) {
            this.onValueChange(this.props.value);
        }
    }

    onValueChange = (value: any) => {
        this.setState({
            selectVehicleVariantValue: value,
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Variant Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                style={{width: "100%"}}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.selectVehicleVariantValue}
            >
                {
                    this.state.dataVehicleVariant.map((item: any, i: number) =>
                        <Select.Option key={item?.value} value={item?.value}>{item?.name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default VehicleVariantSelect;
