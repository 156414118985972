import React, {Component} from "react";
import {catalog3RdPartyService} from "../../services/momotor/Catalog3RdPartyService";
import Select from "antd/lib/select";

class MomotorCitySelect extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            dataCity: [],
            selectCityValue: null,
        }
        this.state = {...this.initState}
    }

    fetchCityData = async () => {
        try {
            await catalog3RdPartyService.getMomotorCity().then(dataFetchCity => {
                const dataForState: any = dataFetchCity.data;
                this.setState({
                    dataCity: dataForState,
                    selectCityValue: this.props.cityName ?? null,
                });
            });
        } catch (e) {
            this.setState({
                dataCity: [],
                selectCityValue: null,
            });
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectCityValue: value,
        });

        const picked = this.state.dataCity.find((o: { regency_code: any; }) => o.regency_code === option.value);
        option.province_code = picked.province_code
        option.province_name = picked.province_name

        this.props.onAreaCityChangeProps(option);
    }

    async componentDidMount() {
        this.fetchCityData();
    }

    render() {
        return (
            <Select
                showSearch
                style={{width: "100%"}}
                placeholder="Pilih Kota"
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.cityName ?? this.state.selectCityValue}
            >
                {this.state?.dataCity?.map((item: any, i: number) =>
                    <Select.Option key={i} value={item?.regency_code}>{item?.regency_name}</Select.Option>
                )}
            </Select>
        );
    }
}

export default MomotorCitySelect;
