import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import "./css/CardCatalog.css";
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';

export const CardCatalog: FunctionComponent<any> = (props: any) => {
    return (
        <Link to={`/baru/${props.budgetArea.toLowerCase()}/variant/${props.budgetVariantLink}`}>
            <div className={`card-budget`}>
                <div className={`img-budget-center`}>
                    <img alt={`imageAmarta`} width={'95%'} src={props.budgetImage}/>
                </div>
                <p className={'card-budget-caption'}>{props.budgetText} <DoubleRightOutlined/>
                </p>
            </div>
        </Link>
    );
}
