import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import {Breadcrumb, Col, Row} from "antd";
import FooterSection from "../../components/footer/FooterSection";
import AppLayout from "../../components/layouts/AppLayout";

class PrivacyPage extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            isDesktop: false,
            areaCode: "bandung",
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);

        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>

                <AppLayout areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}>
                    <Row className={`pt-20`}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>Amartahonda</Breadcrumb.Item>
                            <Breadcrumb.Item>PrivacyPage</Breadcrumb.Item>
                        </Breadcrumb>
                    </Row>

                    <Row className={`p-20 mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <h2 style={{textAlign: "center"}}>KEBIJAKAN PRIVASI</h2>
                            Tanggal Efektif: 1 Februari 2023
                        </Col>
                    </Row>

                    <Row className={`p-20 mt-20`} style={{backgroundColor: "#fff"}}>
                        <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                            <p style={{textAlign: "justify"}}>
                                Kebijakan Privasi ini mengatur mengenai bagaimana PT Arimbi Amartapura (“<b>Kami</b>”) menggunakan, memproses, menyimpan, dan membagi Informasi Pribadi Anda (“<b>Anda</b>” atau “<b>Pengguna</b>”) sehubungan dengan penggunaan Anda atas aplikasi amartahonda.com. Dalam hal Anda tidak menyetujui Kebijakan Privasi ini (berikut perubahan-perubahannya), Anda harus menghentikan penggunaan aplikasi amartahonda.com.
                            </p>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Informasi Pribadi</b></p>
                            <p style={{textAlign: "justify"}}>
                                Informasi Pribadi adalah informasi sehubungan dengan pribadi Pengguna yang dapat diindentifikasi, termasuk namun tidak terbatas kepada akun Facebook, nama lengkap, alamat domisili, tempat dan tanggal lahir, alamat surat elektronik (<i>e-mail</i>), dan nomor telepon Pengguna, serta data pada SIM dan data aktivitas Pengguna pada aplikasi amartahonda.com.
                            </p>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Pemberian Informasi Pribadi oleh Anda</b></p>
                            <p style={{textAlign: "justify"}}>
                                Kami menerima, menyimpan, dan memproses Informasi Pribadi yang Anda berikan kepada Kami ketika Anda mendaftarkan diri, mengakses atau menggunakan aplikasi amartahonda.com pada sistem Kami, antara lain ketika Anda:
                            </p>
                            <ol type="a">
                                <li>mendaftarkan diri (<i>sign up</i>) pada aplikasi amartahonda.com;</li>
                                <li>mengubah profil Anda pada aplikasi amartahonda.com;</li>
                                <li>menjelajah (<i>browsing</i>) pada aplikasi amartahonda.com;</li>
                                <li>menghubungkan akun amartahonda.com Anda dengan akun situs pihak ketiga, antara lain Facebook. Dalam hal demikian, Kami juga akan memperoleh Informasi Pribadi dari situs pihak ketiga dimaksud;</li>
                                <li>berkomunikasi dengan Kami melalui sarana yang kami sediakan;</li>
                                <li>berkomunikasi dengan pengguna aplikasi amartahonda.com lainnya.</li>
                            </ol>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Informasi yang Kami Peroleh</b></p>
                            <p style={{textAlign: "justify"}}>
                                Kami menerima, menyimpan, dan memproses Informasi Pribadi Anda ketika Anda mendaftarkan diri, mengakses atau menggunakan aplikasi amartahonda.com, antara lain:
                            </p>
                            <ol type="a">
                                <li>Informasi Pribadi. Kami dapat mengirimkan, antara lain materi promosi, informasi apa pun sehubungan dengan aplikasi amartahonda.com, dan/atau pengkinian (update) Syarat dan Ketentuan Penggunaan serta Kebijakan Privasi ke alamat <i>e-mail</i> atau alamat domisili atau nomor telepon Anda.</li>
                                <li>Log/ <i>Traffic</i> Data, yang disimpan otomatis oleh sistem Kami, antara lain: alamat IP, tanggal dan waktu penggunaan aplikasi amartahonda.com, perangkat (<i>device</i>) dan piranti lunak (software) yang Anda gunakan, lokasi Anda, sumber/ asal yang merujuk Anda ke dalam aplikasi, jumlah klik, laman yang Anda kunjungi, dan lamanya waktu yang Anda habiskan pada suatu laman.</li>
                                <li>Cookies, merupakan file data yang disimpan pada perangkat (<i>device</i>) Anda yang bertujuan antara lain untuk mengingat preferensi Anda atas aplikasi amartahonda.com dan iklan apa yang sesuai dengan Anda. Anda dapat menghapus Cookies dari <i>device</i> Anda, namun hal tersebut dapat menghalangi Anda untuk mengakses hal-hal tertentu dari aplikasi amartahonda.com.</li>
                                <li><i>Plug-in</i> Pihak Ketiga, antara lain: Google Analytics dan Google Firebase. Ketentuan penggunaan dan kebijakan privasi terkait dengan Informasi Pribadi pada <i>Plug-in</i> diatur masing-masing oleh pihak ketiga yang mengelola <i>Plug-in</i> dimaksud.</li>
                            </ol>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Penggunaan Informasi Pribadi</b></p>
                            <p style={{textAlign: "justify"}}>
                                Kami menggunakan, menyimpan, memproses, dan/atau membagi Informasi Pribadi Anda untuk tujuan-tujuan di bawah ini, antara lain:
                            </p>
                            <ol type="a">
                                <li>agar Anda dapat mengakses dan menggunakan aplikasi amartahonda.com;</li>
                                <li>agar Anda dapat berkomunikasi dengan pengguna aplikasi amartahonda.com lainnya;</li>
                                <li>agar Anda dapat memesan kendaraan dan melakukan pembayaran sebagian/total harga kendaraan;</li>
                                <li>untuk melakukan verifikasi dan otentikasi penggunaan aplikasi amartahonda.com oleh Anda;</li>
                                <li>untuk menghubungi Anda secara langsung baik melalui surat elektronik (<i>e-mail</i>) dan/atau nomor telepon yang Anda berikan;</li>
                                <li>untuk melakukan riset, analisis, penyesuaian/personalisasi, serta menyediakan layanan pelanggan dalam rangka operasi, proteksi, pengembangan, dan optimisasi aplikasi amartahonda.com;</li>
                                <li>untuk mengirimkan pesan, pemberitahuan, peringatan keamanan, dan informasi yang Anda minta sehubungan dengan pemesanan kendaraan pada khususnya atau aplikasi amartahonda.com pada umumnya;</li>
                                <li>untuk menyediakan layanan pelanggan bagi Pengguna;</li>
                                <li>untuk melindungi hak kekayaan intelektual Kami;</li>
                                <li>untuk mengirimkan materi pemasaran, promosi, dan iklan sehubungan dengan aplikasi amartahonda.com itu sendiri maupun dengan promosi layanan milik rekan bisnis Kami;</li>
                                <li>untuk bekerja sama dengan pihak ketiga guna mengembangkan layanan Kami;</li>
                                <li>untuk mematuhi dan memenuhi ketentuan hukum yang berlaku, antara lain dalam hal terdapat perintah dari otoritas yang berwenang kepada Kami untuk menyediakan/ mengungkapkan Informasi Pribadi Anda;</li>
                                <li>untuk membantu penyelesaian sengketa antara Anda dengan pengguna aplikasi amartahonda.com lainnya atau dengan Kami, baik di dalam maupun di luar pengadilan.</li>
                            </ol>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Keamanan</b></p>
                            <p style={{textAlign: "justify"}}>
                                Kami senantiasa berusaha untuk menjaga dan mengimplementasikan standar administratif, standar teknis, dan standar keamanan dengan sebaik-baiknya secara wajar untuk melindungi Informasi Pribadi Anda dari akses/ pembobolan oleh pihak-pihak yang tidak berwenang. Namun demikian, Kami tidak dapat menjamin bahwa transmisi, penyimpanan, dan penggunaan Informasi Pribadi Anda adalah aman 100%. Kami akan memberitahukan Anda dalam hal terjadi pembobolan Informasi Pribadi
                                Anda
                                oleh pihak-pihak yang tidak berwenang dan Kami akan mengambil langkah-langkah pengamanan yang wajar untuk mencegah pembobolan tersebut.
                            </p>
                            <p style={{textAlign: "justify"}}>
                                Seluruh informasi, termasuk Informasi Pribadi, yang diberikan/ diungkapkan oleh Pengguna sendiri atau secara khusus diperuntukkan agar diketahui publik, bukan merupakan tanggung jawab Kami. Kami berharap agar Pengguna dapat berhati-hati dalam memberikan Informasi Pribadi-nya kepada publik.
                            </p>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>URL Pihak Ketiga</b></p>
                            <p style={{textAlign: "justify"}}>
                                Aplikasi amartahonda.com dapat memuat iklan-iklan atau informasi yang terhubung dengan situs dan/atau aplikasi lain di luar aplikasi amartahonda.com. Situs-situs dan/atau aplikasi-aplikasi tersebut mempunyai kebijakan privasi masing-masing. Dalam hal Anda meng-klik iklan-iklan atau informasi dimaksud, Anda akan tunduk pada ketentuan penggunaan serta kebijakan privasi dari pihak yang memasang iklan-iklan atau informasi tersebut.
                            </p>

                            <p style={{textAlign: "justify", paddingTop: 20}}><b>Perubahan Kebijakan Privasi</b></p>
                            <p style={{textAlign: "justify"}}>
                                Kami mempunyai hak untuk mengubah Kebijakan Privasi ini tanpa pemberitahuan terlebih dahulu. Anda diharapkan untuk meninjau Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui versi terkini dari Kebijakan Privasi ini. Anda mengakui dan menyetujui bahwa dalam hal Anda tidak menyetujui perubahan Kebijakan Privasi ini, Anda akan menghentikan penggunaan aplikasi amartahonda.com.
                            </p>

                            <br/>
                            <b className={`pt-50`}>KONTAK</b><br/>
                            PT Arimbi Amartapura<br/>
                            [MOH TOHA 136, BANDUNG]<br/>
                            [halo@amartahonda]<br/>
                            [-]

                        </Col>
                    </Row>
                </AppLayout>

                <div style={{minHeight: 100}}/>
                <FooterSection areaCode={this.state.areaCode}/>
            </React.Fragment>
        );
    }
}

export default withRouter(PrivacyPage);
