import React, {Component} from "react";
import {connect} from "react-redux";
import AreaProvinceSelect from "../area/AreaProvinceSelect";
import AreaCitySelect from "../area/AreaCitySelect";
import AreaDistrictSelect from "../area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../area/AreaSubdistrictSelect";
import '../../screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit.css';
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import Checkbox from "antd/lib/checkbox";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

class CreditSubmissionOwner extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            dataStatusUpdateImage: false,
            ownerDisablePostalCode: false,
            shippingDisablePostalCode: false,
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
        this.setState({
            ownerDisablePostalCode: !!(this.props.ownerPostalCode),
            shippingDisablePostalCode: !!(this.props.shippingPostalCode)
        });
    }

    onFieldChange = <T extends keyof Pick<any, "ownerCopyFromGuarantor" | "ownerIdCardNumber" | "ownerIdCardName" | "ownerFullAddress" | "ownerPostalCode" | "ownerHamlet" | "ownerNeighbourhood" | "shippingHamlet" | "shippingNeighbourhood" | "ownerDifferentAddress" | "shippingFullAddress" | "shippingPostalCode" | "ownerBirthPlace">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "ownerCopyFromGuarantor":
                this.props.changeOwnerCopyFromGuarantor(value);
                if (value === true) {
                    this.props.changeOwnerIdCardNumber(this.props.guarantorIdCardNumber);
                    this.props.changeOwnerIdCardName(this.props.guarantorIdCardName);
                    this.props.changeOwnerBirthPlace(this.props.guarantorBirthPlace);
                    this.props.changeOwnerBirthDate(this.props?.guarantorBirthDate);

                    this.props.changeOwnerFullAddress(this.props.guarantorFullAddress);
                    this.props.changeOwnerProvinceCode(this.props.guarantorProvinceCode);
                    this.props.changeOwnerProvinceName(this.props.guarantorProvinceName);
                    this.props.changeOwnerCityCode(this.props?.guarantorCityCode);
                    this.props.changeOwnerCityName(this.props?.guarantorCityName);
                    this.props.changeOwnerDistrictCode(this.props?.guarantorDistrictCode);
                    this.props.changeOwnerDistrictName(this.props?.guarantorDistrictName);
                    this.props.changeOwnerSubdistrictCode(this.props?.guarantorSubdistrictCode);
                    this.props.changeOwnerSubdistrictName(this.props?.guarantorSubdistrictName);
                    this.props.changeOwnerPostalCode(this.props?.guarantorPostalCode);
                    this.props.changeOwnerHamlet((this.props.guarantorHamlet !== "undefined") ? this.props.guarantorHamlet : "");
                    this.props.changeOwnerNeighbourhood((this.props.guarantorNeighbourhood !== "undefined") ? this.props.guarantorNeighbourhood : "");
                }
                break;
            case "ownerIdCardNumber":
                this.props.changeOwnerIdCardNumber(value.replace(/[^0-9.]/g, ''));
                break;
            case "ownerIdCardName":
                this.props.changeOwnerIdCardName(value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, ''));
                break;
            case "ownerFullAddress":
                this.props.changeOwnerFullAddress(value);
                break;
            case "ownerPostalCode":
                this.props.changeOwnerPostalCode(value.replace(/[^0-9.]/g, ''));
                break;
            case "ownerDifferentAddress":
                this.props.changeOwnerDifferentAddress(value);
                if (!value) {
                    this.props.changeShippingProvinceCode(null);
                    this.props.changeShippingProvinceName(null);
                    this.props.changeShippingCityCode(null);
                    this.props.changeShippingCityName(null);
                    this.props.changeShippingDistrictCode(null);
                    this.props.changeShippingDistrictName(null);
                    this.props.changeShippingSubdistrictCode(null);
                    this.props.changeShippingSubdistrictName(null);
                    this.props.changeShippingDifferentAddress("");
                    this.props.changeShippingPostalCode("");
                    this.props.changeShippingHamlet("000");
                    this.props.changeShippingNeighbourhood("000");
                }
                break;
            case "shippingFullAddress":
                this.props.changeShippingDifferentAddress(value);
                break;
            case "shippingPostalCode":
                this.props.changeShippingPostalCode(value);
                break;
            case "ownerBirthPlace":
                this.props.changeOwnerBirthPlace(value);
                break;
            case "ownerHamlet":
                this.props.changeOwnerHamlet(value.replace(/[^0-9.]/g, ''));
                break;
            case "ownerNeighbourhood":
                this.props.changeOwnerNeighbourhood(value.replace(/[^0-9.]/g, ''));
                break;
            case "shippingHamlet":
                this.props.changeShippingHamlet(value.replace(/[^0-9.]/g, ''));
                break;
            case "shippingNeighbourhood":
                this.props.changeShippingNeighbourhood(value.replace(/[^0-9.]/g, ''));
                break;
        }

        this.setState({
            ...currentState,
        });
    }

    onAreaProvinceChange = (data: any) => {
        this.props.changeOwnerProvinceCode(data.value);
        this.props.changeOwnerProvinceName(data.children);
        this.props.changeOwnerCityCode(null);
        this.props.changeOwnerCityName(null);
        this.props.changeOwnerDistrictCode(null);
        this.props.changeOwnerDistrictName(null);
        this.props.changeOwnerSubdistrictCode(null);
        this.props.changeOwnerSubdistrictName(null);
        this.props.changeOwnerPostalCode("");
    }

    onAreaCityChange = (data: any) => {
        this.props.changeOwnerCityCode(data.value);
        this.props.changeOwnerCityName(data.children);
        this.props.changeOwnerDistrictCode(null);
        this.props.changeOwnerDistrictName(null);
        this.props.changeOwnerSubdistrictCode(null);
        this.props.changeOwnerSubdistrictName(null);
        this.props.changeOwnerPostalCode("");
    }

    onAreaDistrictChange = (data: any) => {
        this.props.changeOwnerDistrictCode(data.value);
        this.props.changeOwnerDistrictName(data.children);
        this.props.changeOwnerSubdistrictCode(null);
        this.props.changeOwnerSubdistrictName(null);
        this.props.changeOwnerPostalCode("");
    }

    onAreaSubdistrictChange = (data: any) => {
        this.props.changeOwnerSubdistrictCode(data.value);
        this.props.changeOwnerSubdistrictName(data.children);
        this.props.changeOwnerPostalCode(data.postalCode);

        this.setState({
            ownerDisablePostalCode: !!(data.postalCode),
        });
    }

    onShippingProvinceChange = (data: any) => {
        this.props.changeShippingProvinceCode(data.value);
        this.props.changeShippingProvinceName(data.children);
        this.props.changeShippingCityCode(null);
        this.props.changeShippingCityName(null);
        this.props.changeShippingDistrictCode(null);
        this.props.changeShippingDistrictName(null);
        this.props.changeShippingSubdistrictCode(null);
        this.props.changeShippingSubdistrictName(null);
        this.props.changeShippingPostalCode("");
    }

    onShippingCityChange = (data: any) => {
        this.props.changeShippingCityCode(data.value);
        this.props.changeShippingCityName(data.children);
        this.props.changeShippingDistrictCode(null);
        this.props.changeShippingDistrictName(null);
        this.props.changeShippingSubdistrictCode(null);
        this.props.changeShippingSubdistrictName(null);
        this.props.changeShippingPostalCode("");
    }

    onShippingDistrictChange = (data: any) => {
        this.props.changeShippingDistrictCode(data.value);
        this.props.changeShippingDistrictName(data.children);
        this.props.changeShippingSubdistrictCode(null);
        this.props.changeShippingSubdistrictName(null);
        this.props.changeShippingPostalCode("");
    }

    onShippingSubdistrictChange = (data: any) => {
        this.props.changeShippingSubdistrictCode(data.value);
        this.props.changeShippingSubdistrictName(data.children);
        this.props.changeShippingPostalCode(data.postalCode);

        this.setState({
            shippingDisablePostalCode: !!(data.postalCode),
        });
    }

    startDateChange = (data: any) => {
        if (data) {
            this.props.changeOwnerBirthDate(data);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Form layout="vertical">

                    <div style={{marginBottom: 10, width: "100%", border: 1, borderColor: "grey", backgroundColor: "#eeeeee", padding: 5, fontSize: "80%"}}>
                        <i>*Data Pemilik digunakan sebagai data surat-surat STNK dll</i>
                    </div>

                    {/*
                    <Form.Item>
                        <Checkbox onChange={e => this.onFieldChange("ownerCopyFromGuarantor", e.target.checked)} checked={this.props.ownerCopyFromGuarantor}>
                            <i className={`text-small-grey-nopadding`}>Samakan <b>Data Pemilik</b> dengan <b>Data Pemohon.</b></i>
                        </Checkbox>
                    </Form.Item>
                    */}

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nomor KTP Pemilik *</i>
                        <Input
                            maxLength={16}
                            value={this.props.ownerIdCardNumber}
                            placeholder="Nomor Sesuai KTP Pemilik"
                            onChange={event => this.onFieldChange("ownerIdCardNumber", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Nama KTP Pemilik *</i>
                        <Input
                            value={this.props.ownerIdCardName}
                            placeholder="Nama Sesuai KTP Pemilik"
                            onChange={event => this.onFieldChange("ownerIdCardName", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Tempat Lahir Pemilik *</i>
                        <Input
                            value={this.props.ownerBirthPlace}
                            placeholder="Tempat Lahir Sesuai KTP Pemilik"
                            onChange={event => this.onFieldChange("ownerBirthPlace", event.target.value)}
                        />
                    </Form.Item>

                    <Form.Item className={'padTop-7'}>
                        <i className={`text-small-grey-nopadding`}>Tanggal Lahir Pemilik *</i>
                        <DatePicker value={moment(this.props.ownerBirthDate, 'YYYY-MM-DD')} onChange={event => this.startDateChange(event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Alamat Sesuai KTP *</i>
                        <TextArea showCount maxLength={200} value={this.props.ownerFullAddress} onChange={event => this.onFieldChange("ownerFullAddress", event.target.value)} placeholder="Alamat Sesuai KTP"/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Provinsi Sesuai KTP *</i>
                        <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceCode={this.props.ownerProvinceCode} provinceName={this.props.ownerProvinceName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Sesuai KTP *</i>
                        <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.props.ownerProvinceCode} cityName={this.props.ownerCityName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kecamatan Sesuai KTP *</i>
                        <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.props.ownerCityCode} districtName={this.props.ownerDistrictName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kelurahan Sesuai KTP *</i>
                        <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.props.ownerDistrictCode} subdistrictName={this.props.ownerSubdistrictName}/>
                    </Form.Item>

                    <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Kode Pos Sesuai KTP *</i>
                        <Input disabled={this.state.ownerDisablePostalCode} value={this.props.ownerPostalCode} placeholder="Kodepos" onChange={event => this.onFieldChange("ownerPostalCode", event.target.value)}/>
                    </Form.Item>

                    <Row>
                        <Col span={12} style={{paddingRight: '2px'}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>RT</i>
                                <Input maxLength={3} value={this.props.ownerHamlet} onChange={event => this.onFieldChange("ownerHamlet", event.target.value)} placeholder="RT"/>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{paddingLeft: '2px'}}>
                            <Form.Item>
                                <i className={`text-small-grey-nopadding`}>RW</i>
                                <Input maxLength={3} value={this.props.ownerNeighbourhood} onChange={event => this.onFieldChange("ownerNeighbourhood", event.target.value)} placeholder="RW"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Checkbox onChange={e => this.onFieldChange("ownerDifferentAddress", e.target.checked)} checked={this.props.ownerDifferentAddress}>
                            <i className={`text-small-grey-nopadding`}>Alamat Pengiriman <b> berbeda </b> dari KTP Pemilik.</i>
                        </Checkbox>
                    </Form.Item>

                    <div style={{display: (this.props.ownerDifferentAddress) ? 'block' : 'none'}}>
                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Alamat Pengiriman</i>
                            <TextArea showCount maxLength={200} value={this.props.shippingFullAddress} onChange={event => this.onFieldChange("shippingFullAddress", event.target.value)} placeholder="Alamat Tempat Tinggal"/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Provinsi Pengiriman</i>
                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onShippingProvinceChange} country={"idn"} provinceCode={this.props.shippingProvinceCode} provinceName={this.props.shippingProvinceName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kota / Kabupaten Pengiriman</i>
                            <AreaCitySelect onAreaCityChangeProps={this.onShippingCityChange} provinceCode={this.props.shippingProvinceCode} cityName={this.props.shippingCityName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kecamatan Pengiriman</i>
                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onShippingDistrictChange} cityCode={this.props.shippingCityCode} districtName={this.props.shippingDistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kelurahan Pengiriman</i>
                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onShippingSubdistrictChange} districtCode={this.props.shippingDistrictCode} subdistrictName={this.props.shippingSubdistrictName}/>
                        </Form.Item>

                        <Form.Item>
                            <i className={`text-small-grey-nopadding`}>Kode Pos Pengiriman</i>
                            <Input disabled={this.state.shippingDisablePostalCode} value={this.props.shippingPostalCode} placeholder="Kodepos" onChange={event => this.onFieldChange("shippingPostalCode", event.target.value)}/>
                        </Form.Item>

                        <Row>
                            <Col span={12} style={{paddingRight: '2px'}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>RT</i>
                                    <Input maxLength={3} value={this.props.shippingHamlet} onChange={event => this.onFieldChange("shippingHamlet", event.target.value)} placeholder="RT"/>
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{paddingLeft: '2px'}}>
                                <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>RW</i>
                                    <Input maxLength={3} value={this.props.shippingNeighbourhood} onChange={event => this.onFieldChange("shippingNeighbourhood", event.target.value)} placeholder="RW"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const reduxState = (state: any) => ({
    guarantorIdCardNumber: state.RguarantorIdCardNumber,
    guarantorIdCardName: state.RguarantorIdCardName,
    guarantorBirthPlace: state.RguarantorBirthPlace,
    guarantorBirthDate: state.RguarantorBirthDate,
    guarantorFullAddress: state.RguarantorFullAddress,
    guarantorProvinceCode: state.RguarantorProvinceCode,
    guarantorProvinceName: state.RguarantorProvinceName,
    guarantorCityCode: state.RguarantorCityCode,
    guarantorCityName: state.RguarantorCityName,
    guarantorDistrictCode: state.RguarantorDistrictCode,
    guarantorDistrictName: state.RguarantorDistrictName,
    guarantorSubdistrictCode: state.RguarantorSubdistrictCode,
    guarantorSubdistrictName: state.RguarantorSubdistrictName,
    guarantorPostalCode: state.RguarantorPostalCode,
    guarantorHamlet: state.RguarantorHamlet,
    guarantorNeighbourhood: state.RguarantorNeighbourhood,

    ownerIdCardNumber: state.RownerIdCardNumber,
    ownerIdCardName: state.RownerIdCardName,

    ownerBirthPlace: state.RownerBirthPlace,
    ownerBirthDate: state.RownerBirthDate,
    ownerIdCardImage: state.RownerIdCardImage,
    ownerFullAddress: state.RownerFullAddress,
    ownerProvinceCode: state.RownerProvinceCode,
    ownerProvinceName: state.RownerProvinceName,
    ownerCityCode: state.RownerCityCode,
    ownerCityName: state.RownerCityName,
    ownerDistrictCode: state.RownerDistrictCode,
    ownerDistrictName: state.RownerDistrictName,
    ownerSubdistrictCode: state.RownerSubdistrictCode,
    ownerSubdistrictName: state.RownerSubdistrictName,
    ownerPostalCode: state.RownerPostalCode,
    ownerHamlet: state.RownerHamlet,
    ownerNeighbourhood: state.RownerNeighbourhood,

    ownerDifferentAddress: state.RownerDifferentAddress,
    ownerCopyFromGuarantor: state.RownerCopyFromGuarantor,

    shippingFullAddress: state.RshippingFullAddress,
    shippingProvinceCode: state.RshippingProvinceCode,
    shippingProvinceName: state.RshippingProvinceName,
    shippingCityCode: state.RshippingCityCode,
    shippingCityName: state.RshippingCityName,
    shippingDistrictCode: state.RshippingDistrictCode,
    shippingDistrictName: state.RshippingDistrictName,
    shippingSubdistrictCode: state.RshippingSubdistrictCode,
    shippingSubdistrictName: state.RshippingSubdistrictName,
    shippingPostalCode: state.RshippingPostalCode,
    shippingHamlet: state.RshippingHamlet,
    shippingNeighbourhood: state.RshippingNeighbourhood,

});

const reduxDispatch = (dispatch: any) => ({
    changeOwnerIdCardNumber: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_NUMBER', value: data}),
    changeOwnerIdCardName: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_NAME', value: data}),
    changeOwnerIdCardImage: (data: string) => dispatch({type: 'CHANGE_OWNER_IDCARD_IMAGE', value: data}),
    changeOwnerBirthPlace: (data: string) => dispatch({type: 'CHANGE_OWNER_BIRTHPLACE', value: data}),
    changeOwnerBirthDate: (data: string) => dispatch({type: 'CHANGE_OWNER_BIRTHDATE', value: data}),
    changeOwnerFullAddress: (data: string) => dispatch({type: 'CHANGE_OWNER_FULLADDRESS', value: data}),
    changeOwnerProvinceCode: (data: string) => dispatch({type: 'CHANGE_OWNER_PROVINCECODE', value: data}),
    changeOwnerProvinceName: (data: string) => dispatch({type: 'CHANGE_OWNER_PROVINCENAME', value: data}),
    changeOwnerCityCode: (data: string) => dispatch({type: 'CHANGE_OWNER_CITYCODE', value: data}),
    changeOwnerCityName: (data: string) => dispatch({type: 'CHANGE_OWNER_CITYNAME', value: data}),
    changeOwnerDistrictCode: (data: string) => dispatch({type: 'CHANGE_OWNER_DISTRICTCODE', value: data}),
    changeOwnerDistrictName: (data: string) => dispatch({type: 'CHANGE_OWNER_DISTRICTNAME', value: data}),
    changeOwnerSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_OWNER_SUBDISTRICTCODE', value: data}),
    changeOwnerSubdistrictName: (data: string) => dispatch({type: 'CHANGE_OWNER_SUBDISTRICTNAME', value: data}),
    changeOwnerPostalCode: (data: string) => dispatch({type: 'CHANGE_OWNER_POSTALCODE', value: data}),
    changeOwnerHamlet: (data: string) => dispatch({type: 'CHANGE_OWNER_HAMLET', value: data}),
    changeOwnerNeighbourhood: (data: string) => dispatch({type: 'CHANGE_OWNER_NEIGHBOURHOOD', value: data}),

    changeOwnerDifferentAddress: (data: string) => dispatch({type: 'CHANGE_OWNER_DIFFERENTADDRESS', value: data}),
    changeOwnerCopyFromGuarantor: (data: string) => dispatch({type: 'CHANGE_OWNER_COPYFROMGUARANTOR', value: data}),

    changeShippingDifferentAddress: (data: string) => dispatch({type: 'CHANGE_SHIPPING_FULLADDRESS', value: data}),
    changeShippingProvinceCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_PROVINCECODE', value: data}),
    changeShippingProvinceName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_PROVINCENAME', value: data}),
    changeShippingCityCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_CITYCODE', value: data}),
    changeShippingCityName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_CITYNAME', value: data}),
    changeShippingDistrictCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_DISTRICTCODE', value: data}),
    changeShippingDistrictName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_DISTRICTNAME', value: data}),
    changeShippingSubdistrictCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_SUBDISTRICTCODE', value: data}),
    changeShippingSubdistrictName: (data: string) => dispatch({type: 'CHANGE_SHIPPING_SUBDISTRICTNAME', value: data}),
    changeShippingPostalCode: (data: string) => dispatch({type: 'CHANGE_SHIPPING_POSTALCODE', value: data}),
    changeShippingHamlet: (data: string) => dispatch({type: 'CHANGE_SHIPPING_HAMLET', value: data}),
    changeShippingNeighbourhood: (data: string) => dispatch({type: 'CHANGE_SHIPPING_NEIGHBOURHOOD', value: data}),
});

export default connect(reduxState, reduxDispatch)(CreditSubmissionOwner);
