import React, {Component} from 'react';
import {withRouter} from "../../hoc/withRouter";
import "../../screen/home/Home.css";
import {PromoStates} from "./types/PromoTypes";
import 'reactjs-popup/dist/index.css';
import {promoServices} from "../../services/promo/PromoService";
import parse from 'html-react-parser';
import currencyFormat from "../../helper/currencyFormat";
import ListVariantPromo from "../../components/promo/ListVariantPromo";
import Image from "antd/lib/image";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Badge from "antd/lib/badge";
import Typography from "antd/lib/typography";
import Empty from "antd/lib/empty";
import Select from "antd/lib/select";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Tooltip from "antd/lib/tooltip";
import BulbOutlined from '@ant-design/icons/BulbOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {trackLogServices} from "../../services/track/TrackLogService";
import {Breadcrumb, Checkbox} from "antd";
import {promoLogServices} from "../../services/amartahondaLog/PromoLogService";
import Tabs from "antd/lib/tabs";
import Input from "antd/lib/input";
import NormalHeader from "../../components/layouts/NormalHeader";
import FooterSection from "../../components/footer/FooterSection";
import moment from "moment";

const {TabPane} = Tabs;

class Promo extends Component<any, PromoStates> {
    private readonly initState!: PromoStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        document.title = "Amartahonda | Promo | " + match.params.area.trim().toUpperCase();

        const queryParams = new URLSearchParams(window.location.search);
        let getPurchaseMethod: any = "all";
        let getModelSelected: any = "all";
        let getAgentSelected: any = "all";
        let getAllowAgent: any = false;

        if (queryParams.get('purchase-method')?.toLowerCase() === "cash" || queryParams.get('purchase-method')?.toLowerCase() === "credit") {
            getPurchaseMethod = queryParams.get('purchase-method')?.toLowerCase();
        }
        if (queryParams.get('model')) {
            getModelSelected = queryParams.get('model')?.toLowerCase();
        }
        if (queryParams.get('agent')) {
            getAgentSelected = "true";
        }
        if (queryParams.get('agent')) {
            getAllowAgent = true;
        }

        this.initState = {
            company: "AMARTA",
            areaCode: match.params.area.trim().toUpperCase(),
            loading: false,
            dataPromo: [],
            showModalPromo: false,
            showModalUsePromo: false,
            allowAgent: getAllowAgent,
            dataChosenPromo: {},
            listModel: [],
            modelSelected: getModelSelected,
            agentSelected: getAgentSelected,
            methodSelected: getPurchaseMethod,
            promoCodeCheckString: "",
            promoCodeFetch: false,
            dataPromoCodeFetch: {},
            resultPromoCodeFetch: false,
            isDesktop: false,
            initCondition: true,
        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    fetchPromoData = async (fetchType: string) => {
        await this.promisedSetState({
            loading: true,
            initCondition: false,
        });

        // promoLogServices
        if (fetchType === "log") {
            try {
                await promoLogServices.createB2bCreditOffer({
                    company: "amarta",
                    promo_type: "new_vehicle",
                    city_group: this.state.areaCode?.toLowerCase(),
                    purchase_method: (this.state.methodSelected !== 'all') ? this.state.methodSelected : "",
                    vehicle_brand: "honda",
                    vehicle_model: (this.state.modelSelected !== 'all') ? this.state.modelSelected : "",
                    vehicle_variant: "",
                    allow_agent: this.state.allowAgent
                }).then(successData => {
                    console.log('success');
                });
            } catch (e) {
                console.log(e);
            }
        }

        const dataRequest = {
            cityGroup: this.state.areaCode,
            brand: 'honda',
            purchaseMethod: (this.state.methodSelected !== 'all') ? this.state.methodSelected : "",
            model: (this.state.modelSelected !== 'all') ? this.state.modelSelected : "",
            agentSelected: (this.state.agentSelected !== 'all') ? this.state.agentSelected : "",
            allowAgent: this.state.allowAgent,
            log: false,
        };

        try {
            promoServices.getPromo2(
                dataRequest
            ).then(dataFetchPromo => {
                this.setState({
                    dataPromo: dataFetchPromo?.data?.data,
                    loading: false,
                });
            }).catch(e => {
                this.setState({
                    dataPromo: [],
                    loading: false,
                });
            });
        } catch (e) {
            this.setState({
                dataPromo: [],
                loading: false,
            });
        }
    }

    fetchAllModelProduct = async () => {
        try {
            await trimitraCatalogServices.getCatalogModel({
                areaCode: this.state.areaCode,
            }).then(dataModelHome => {
                this.setState({
                    listModel: dataModelHome.data.data ?? []
                });
            });
        } catch (e) {
            this.setState({
                listModel: []
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
        await this.fetchAllModelProduct();
        await trackLogServices.sendTrackLog({
            eventName: `promo page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
        // await this.fetchPromoData("fetch");
    }

    onClickDetailPromo = async (data: any) => {
        this.setState({
            showModalPromo: true,
            dataChosenPromo: data,
        });
    }

    closeDetailPromo = () => {
        this.setState({
            showModalPromo: false,
        });
    }

    onClickUsePromo = async (data: any) => {
        this.setState({
            showModalUsePromo: true,
            dataChosenPromo: data,
        });
    }

    closeUsePromo = () => {
        this.setState({
            showModalUsePromo: false,
        });
    }

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Kode Promo Berhasil Dicopy');
    }

    onChangeModel = async (value: any, option: any) => {
        await this.promisedSetState({
            modelSelected: value,
        });
    }

    onChangeAgent = async (value: any, option: any) => {
        await this.promisedSetState({
            agentSelected: value,
        });
    }

    onChangeMethod = async (value: any, option: any) => {
        await this.promisedSetState({
            methodSelected: value,
        });
    }

    onFieldChange = <T extends keyof Pick<any, "allowAgent" | "promoCodeCheckString">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "allowAgent":
                currentState.allowAgent = value;
                break;
            case "promoCodeCheckString":
                currentState.promoCodeCheckString = value?.toUpperCase()?.trim();
                break;
        }
        this.setState({
            ...currentState,
        });
    }

    fetchDetailPromo = async () => {
        await this.promisedSetState({
            promoCodeFetch: true,
            dataPromoCodeFetch: {},
            resultPromoCodeFetch: false,
            initCondition: false,
        });

        promoServices.getInfoDetailPromo(
            {
                promoCode: this.state.promoCodeCheckString
            }
        ).then(async dataFetchDetailPromo => {
            await this.promisedSetState({
                promoCodeFetch: false,
                resultPromoCodeFetch: true,
                dataPromoCodeFetch: dataFetchDetailPromo?.data?.data
            });

            console.log(this.state.dataPromoCodeFetch);

        }).catch(e => {
            this.setState({
                promoCodeFetch: false,
                resultPromoCodeFetch: true,
                dataPromoCodeFetch: {},
            });
        });
    }

    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
        document.title = "Amartahonda | Promo | " + data?.city_group?.toUpperCase();
        this.props.history.push("/" + data?.city_group?.toLowerCase() + "/promo");
        await this.fetchPromoData("fetch");
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>

                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>
                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Breadcrumb className={`pl-20`}>
                            <Breadcrumb.Item>AmartaHonda</Breadcrumb.Item>
                            <Breadcrumb.Item>Promo</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-20`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Promo AmartaHonda</div>
                                <br/>
                                <div className={`detail-product-caption`}>Dapatkan penawaran terbaik AmartaHonda {this.state.areaCode}, gunakan fitur pencarian untuk mendapatkan promo yang diinginkan.</div>
                                <br/>
                                <Select bordered={false} onChange={this.onChangeMethod} defaultValue={this.state.methodSelected} style={{fontSize: "80%", width: 120, paddingTop: 2, paddingBottom: 2, paddingRight: 2}}>
                                    <Select.Option value="all">Semua Metode</Select.Option>
                                    <Select.Option value="cash">Tunai</Select.Option>
                                    <Select.Option value="credit">Kredit</Select.Option>
                                </Select>
                                <Select bordered={false} showSearch onChange={this.onChangeModel} defaultValue={this.state.modelSelected} style={{fontSize: "80%", width: 120, paddingTop: 2, paddingBottom: 2, paddingRight: 2}}>
                                    <Select.Option value="all">Semua Model</Select.Option>
                                    {this.state.listModel.map((item: any, i: number) =>
                                        <Select.Option key={i} value={item.model_name}>{item.model_name}</Select.Option>
                                    )}
                                </Select>

                                <Select bordered={false} onChange={this.onChangeAgent} defaultValue={this.state.agentSelected} style={{fontSize: "80%", width: 150, paddingTop: 2, paddingBottom: 2, paddingRight: 2}}>
                                    <Select.Option value="all">Agen & Non Agen</Select.Option>
                                    <Select.Option value="true">Agen</Select.Option>
                                    <Select.Option value="false">Non Agen</Select.Option>
                                </Select>

                                <Checkbox style={{display: "none"}} checked={this.state.allowAgent} onChange={e => this.onFieldChange("allowAgent", e.target.checked)}>
                                    <span style={{fontSize: "80%"}}>Agen</span>
                                </Checkbox>
                                <br/><br/>

                                <Button loading={this.state.loading} onClick={() => this.fetchPromoData("log")} type="primary" style={{backgroundColor: `#64b48f`, borderColor: `#64b48f`}}>Cari Promo</Button>
                            </Col>
                            <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 8}} sm={{span: 0}} xs={{span: 0}}>
                                <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/Wallet_Monochromatic.png`} style={{height: 200}} alt={`logo`}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <div style={!this.state.loading ? {display: 'none'} : {textAlign: "center", padding: 70, minHeight: 1080}}>
                            <div style={{marginTop: 150, textAlign: "center"}}>
                                <img className={`header-logo`} src={'https://trimitra-catalog.s3-ap-southeast-1.amazonaws.com/favicon.png'} alt={`amartahonda-logo`}/>
                                <b style={{color: '#64b48f', fontSize: '125%'}}>AMARTAHONDA</b>
                            </div>
                            <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                        </div>

                        <div style={this.state.loading ? {display: 'none'} : {}}>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Promo" key="1">
                                    <div style={{padding: "7px", marginBottom: "20px"}}>
                                        <br/><br/>
                                        <div style={this.state.initCondition ? {} : {display: 'none'}}>
                                            <p style={{textAlign: "center"}}>
                                                <BulbOutlined style={{fontSize: "200%"}}/> <br/>
                                                Silakan gunakan fitur pencarian promo <br/> sesuai yang diinginkan.
                                            </p>
                                        </div>

                                        <div style={((this.state.dataPromo.length === 0) && (!this.state.initCondition)) ? {} : {display: 'none'}}>
                                            <Empty/>
                                        </div>
                                        <Row>
                                            {this.state.dataPromo.map((item: any, i: number) =>
                                                <Col key={'a' + i} style={{padding: 10}} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Badge.Ribbon color="#e95a62" text={item.purchase_method.toString()}>
                                                        <div className={`card-shadow-promo`} style={{marginBottom: "20px"}}>
                                                            <Row>
                                                                <Col className={`card-col`} style={{justifyContent: "center", display: "flex", alignItems: "center", backgroundColor: '#64b48f', borderTopLeftRadius: 7, borderBottomLeftRadius: 7}} span={7}>
                                                                    <Image style={{display: "block", margin: "auto"}} preview={false} src={`https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/amarta/promo/aaaaaaa.png`}/>
                                                                </Col>
                                                                <Col span={17} style={{padding: 10}}>
                                                                    <b style={{color: '#64b48f', marginBottom: '20px', fontWeight: 500}}>{item.caption}</b><br/>
                                                                    <Typography.Text code style={{fontSize: "90%"}}>{item.promo_code}</Typography.Text>

                                                                    <Tooltip title="Click untuk copy Kode Promo" color={'cyan'}>
                                                                        <CopyOutlined style={{color: "#757575", paddingLeft: 2}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(item.promo_code)}/>
                                                                    </Tooltip>

                                                                    <br/>&nbsp;
                                                                    <span style={{fontSize: '88%'}}>{parse(item.information)}</span>
                                                                    <div style={{marginTop: 10}}>
                                                                    <span onClick={() => this.onClickDetailPromo(item)} style={{cursor: "pointer"}}>
                                                                        <span style={{fontSize: '85%', color: 'grey'}}>Lihat Detail <DoubleRightOutlined/>
                                                                    </span>
                                                                    </span>
                                                                        <span onClick={() => this.onClickUsePromo(item)} style={{cursor: "pointer", float: "right"}}>
                                                                        <span style={{fontSize: '85%', color: 'grey'}}>Gunakan <DoubleRightOutlined/></span>
                                                                    </span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Badge.Ribbon>
                                                </Col>
                                            )}
                                        </Row>
                                    </div>
                                </TabPane>
                                <TabPane tab="Check Kode Promo" key="2">
                                    <br/><i style={{color: "grey", fontSize: "80%"}}>Kode Promo</i>
                                    <Input value={this.state.promoCodeCheckString} onChange={event => this.onFieldChange("promoCodeCheckString", event.target.value)} placeholder="Masukan Kode Promo"/>
                                    <Button loading={this.state.promoCodeFetch} onClick={() => this.fetchDetailPromo()} type="primary" style={{backgroundColor: `#64b48f`, borderColor: `#64b48f`, marginTop: "10px"}}>Check Promo</Button>

                                    <div style={!this.state.promoCodeFetch ? {display: 'none'} : {textAlign: "center", padding: 70}}>
                                        <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                                    </div>

                                    <div style={!this.state.resultPromoCodeFetch ? {display: 'none'} : {}}>
                                        <div style={this.state.dataPromoCodeFetch?.promo_code ? {display: 'none'} : {textAlign: "center", padding: 70}}>
                                            <Empty/>
                                        </div>

                                        <Row style={!this.state.dataPromoCodeFetch?.promo_code ? {display: 'none'} : {paddingTop: 25}}>
                                            <Col span={24}>
                                                <Image style={{width: '100%'}} preview={false} src={this.state.dataPromoCodeFetch?.url_image}/>
                                            </Col>
                                            <Col span={24}>
                                                <i style={{color: "grey", fontSize: "85%"}}>Kode Promo:</i><br/>
                                                <span>{this.state.dataPromoCodeFetch?.promo_code}</span>
                                                <Tooltip title="Click untuk copy Kode Promo" color={'cyan'}>
                                                    <CopyOutlined style={{color: "#259261", paddingLeft: 10}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.dataPromoCodeFetch?.promo_code)}/>
                                                </Tooltip>
                                            </Col>
                                            <Col span={24} style={{paddingTop: 10}}>
                                                <i style={{color: "grey", fontSize: "85%"}}>Caption Promo:</i> <br/>
                                                <span>{parse(this.state.dataPromoCodeFetch?.caption ?? "")}</span>
                                            </Col>
                                            <Col span={24} style={{paddingTop: 10}}>
                                                <i style={{color: "grey", fontSize: "85%"}}>Ketentuan Promo:</i> <br/>
                                                <span>{parse(this.state.dataPromoCodeFetch?.term_condition ?? "-")}</span>
                                            </Col>
                                            <Col span={24} style={{paddingTop: 10}}>
                                                <i style={{color: "grey", fontSize: "85%"}}>Masa Berlaku Promo:</i> <br/>
                                                <span> {moment(this.state.dataPromoCodeFetch?.start_period).format('DD MMM YYYY')} - {moment(this.state.dataPromoCodeFetch?.end_period).format('DD MMM YYYY')}</span>
                                            </Col>
                                            <Col span={24} style={{marginTop: 15}}>
                                                <i style={{color: "grey", fontSize: "85%"}}>Discount Promo:</i><br/>
                                                <span>{currencyFormat(this.state.dataPromoCodeFetch?.discount_value)}</span>
                                            </Col>
                                        </Row>
                                        <br/><br/>
                                    </div>

                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>

                <Modal title={this.state.dataChosenPromo?.caption}
                       open={this.state.showModalPromo}
                       onCancel={this.closeDetailPromo}
                       onOk={this.closeDetailPromo}
                       footer={[
                           <Button key="close" onClick={this.closeDetailPromo}>
                               Close
                           </Button>
                       ]}
                >
                    <Row>
                        <Col span={20}>
                            <Col span={24}>
                                <span style={{color: "grey"}}>Kode Promo:</span><br/>
                                <span>{this.state.dataChosenPromo?.promo_code}</span>
                                <Tooltip title="Click untuk copy Kode Promo" color={'cyan'}>
                                    <CopyOutlined style={{color: "#259261", paddingLeft: 10}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.dataChosenPromo?.promo_code)}/>
                                </Tooltip>
                            </Col>
                            <Col span={24} style={{marginTop: 15}}>
                                <span>Discount Promo:</span><br/>
                                <span style={{color: "grey"}}>{(this.state.dataChosenPromo?.discount_type === 'nominal') ? currencyFormat(this.state.dataChosenPromo?.discount_value) : this.state.dataChosenPromo?.discount_value + ' %'}</span>
                            </Col>
                            <Col span={24} style={{marginTop: 15}}>
                                <span>Masa Berlaku:</span><br/>
                                <span style={{color: "grey"}}>{this.state.dataChosenPromo?.start_period?.slice(0, -3)} - {this.state.dataChosenPromo?.end_period?.slice(0, -3)}</span>
                            </Col>
                        </Col>
                        <Col span={4}>
                            <Image style={{width: '100%'}} preview={false} src={this.state.dataChosenPromo?.url_image}/>
                        </Col>
                        <Col span={24} style={{paddingTop: 10}}>
                            <span>Informasi Promo:</span> <span style={{color: "grey"}}>{parse(this.state.dataChosenPromo?.information ?? "")}</span>
                            <span>Ketentuan Promo:</span> <span style={{color: "grey"}}>{parse(this.state.dataChosenPromo?.term_condition ?? "")}</span>
                        </Col>
                    </Row>
                </Modal>

                <Modal title={this.state.dataChosenPromo?.caption}
                       open={this.state.showModalUsePromo}
                       onCancel={this.closeUsePromo}
                       onOk={this.closeUsePromo}
                       footer={[
                           <Button key="close" onClick={this.closeUsePromo}>
                               Close
                           </Button>
                       ]}
                >
                    <ListVariantPromo promoCode={this.state.dataChosenPromo?.promo_code} areaCode={this.state.areaCode} vehicleModel={this.state.dataChosenPromo?.vehicle_model} vehicleVariant={this.state.dataChosenPromo?.vehicle_variant}/>
                </Modal>

                <FooterSection areaCode={this.state.areaCode}/>
            </React.Fragment>
        );
    }
}

export default withRouter(Promo);