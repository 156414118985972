import React, {FunctionComponent} from "react";
import currencyMillionFormat from "../../helper/currencyMillionFormat";
import "./css/CardProduct.css";
import {useNavigate} from "react-router-dom";
import Image from "antd/lib/image";
import Badge from "antd/lib/badge";

export const CardProduct: FunctionComponent<any> = (props: any) => {

    const navigate = useNavigate();

    function onClickProduct() {
        const option = props.option;
        const product = props.product;
        if (option === "NEW_VARIANT") {
            navigate(`/baru/${props.productArea.toLowerCase()}/${product.replace('/', '')}`);
        } else if (option === "NEW_MODEL") {
            navigate(`/baru/${props.productArea.toLowerCase()}/variant/model/${product.replace(/ /g, '').toLowerCase()}`);
        } else if (option === "MOKAS") {
            navigate(`/mokas/${props.uid}`);
        } else if (option === "CATALOG") {
            window.location.href = (`/baru/${props.productArea.toLowerCase()}/variant/${props.product}`);
        } else {
            alert(option + " == " + product);
        }
    }

    let textBadge = "";
    let colorBadge = "#64b48f";
    if (props.option === 'NEW_VARIANT') {
        if (props?.stock?.custom_availability) {
            textBadge = props?.stock?.custom_availability;
        } else {
            if (props?.stock?.stock_qty === 0) {
                textBadge = 'NA / Pre-Order';
                colorBadge = "#e57373";
            } else if (props?.stock?.stock_qty < props.stockLimit) {
                textBadge = 'Tersisa ' + props?.stock?.stock_qty + ' unit';
                colorBadge = "#64b48f";
            } else {
                textBadge = 'Ready';
                colorBadge = "#2196f3";
            }
        }
    }

    function amartaWord(productName: string) {
        const productX = productName?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase());
        if (productX) {
            const stringArray = productX?.split(" ");
            let newName = "";
            for (const x of stringArray) {
                newName += (x?.replace(/[0-9]/g, '')?.length < 4 ? x?.toUpperCase() : x) + " ";
            }
            return newName?.trim();
        } else {
            return productName;
        }
    }

    const productName = amartaWord(props?.productName?.trim());

    return (
        <React.Fragment>
            {props.option !== 'MOKAS' &&
                <div className={`card-product`} onClick={() => onClickProduct()}>
                    {props.option === 'NEW_VARIANT' && props.stock && !props.fetchStock &&
                        <Badge.Ribbon style={{zIndex: 300, fontSize: '85%'}} text={textBadge} color={colorBadge}/>
                    }

                    <div className={`product-img-center`}>
                        <Image className={`img-product-card`} preview={false} alt={`Loading Image...`} width={'100%'} src={props.productImageUrl} fallback={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/vehicle-not-found.png'}/>
                    </div>

                    <p className={`p-chosen-name`}>
                        {productName}
                        <br/><span style={{color: "gray", fontSize: "85%"}}>{props?.registration_year}</span>
                    </p>
                    <p style={(props?.option === "CATALOG") ? {display: 'none'} : {}} className={`p-chosen-price`}>
                        <span style={(props?.productPrice) ? {} : {display: 'none'}}>{currencyMillionFormat(props?.productPrice)}</span>
                        <span style={(props?.productPrice) ? {display: 'none'} : {}}>Not Available</span>
                        {props?.productStrikethroughtPrice > 0 &&
                            <span className={`p-chosen-strikethroughprice`}> {currencyMillionFormat(props?.productStrikethroughtPrice)}</span>
                        }
                    </p>
                    <p style={(props.option !== "CATALOG") ? {display: 'none'} : {}} className={`p-chosen-price`}>
                        KATALOG
                    </p>
                </div>
            }
            {props.option === 'MOKAS' &&
                <div className={`card-product`} onClick={() => onClickProduct()}>
                    <div className={`gallery-mokas`}>
                        <img src={props.productImageUrl} alt="Loading..." className="thumbnail-mokas"/>
                    </div>
                    <div style={(props?.option === "CATALOG") ? {display: 'none'} : {}} className={`p-chosen-price-used`}>
                        <span style={(props?.productPrice) ? {} : {display: 'none'}}>{currencyMillionFormat(props?.productPrice)}</span>
                    </div>
                    <div className={`p-chosen-name-used`}>
                        <span style={{fontSize: "110%"}}>{props?.registration_year}</span>
                        <br/>
                        <span style={{fontSize: "110%", color: "gray"}}>{productName?.split('', 23).reduce((o: any, c: any) => o.length === 22 ? `${o}${c}...` : `${o}${c}`, '')}</span>
                        <br/>
                        <span style={{fontSize: "80%",color: "gray"}}>{props?.productArea?.toUpperCase()}</span>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}
