import React, {Component} from "react";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import CardVariantPromo from "./CardVariantPromo";

class ListVariantPromo extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            company: "amarta",
            fetchingData: true,
            listVariant: [],
        }
        this.state = {...this.initState}
    }

    fetchListVariant = async () => {
        await this.promisedSetState({
            listVariant: [],
            fetchingData: true,
        });

        let listVariant = [];
        if (this.props.vehicleVariant[0]?.toLowerCase() === 'all') {
            let tempListVariant: any = [];
            for (const modelName of this.props.vehicleModel) {
                await trimitraCatalogServices.getCatalogVariant({
                    areaCode: this.props.areaCode,
                    modelName: modelName
                }).then(resp => {
                    for (const item of resp.data.data) {
                        tempListVariant.push(item.variant_code);
                    }
                });
            }
            listVariant = tempListVariant;
        } else {
            listVariant = this.props.vehicleVariant;
        }

        await this.promisedSetState({
            listVariant: listVariant,
            fetchingData: false,
        });
    }

    async componentDidMount() {
        await this.fetchListVariant();
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.vehicleModel !== prevProps.vehicleModel || this.props.vehicleVariant !== prevProps.vehicleVariant) {
            await this.fetchListVariant();
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <div style={this.state.fetchingData ? {textAlign: "center", padding: 20, minHeight: 200} : {display: 'none'}}>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>
                <Row>
                    {this.state.listVariant.map((item: any, i: number) =>
                        <CardVariantPromo promoCode={this.props.promoCode} areaCode={this.props.areaCode} variantCode={item} key={i}/>
                    )}
                </Row>
            </React.Fragment>
        );
    }
}

export default ListVariantPromo;
