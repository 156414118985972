import React, {Component} from 'react';
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {Link} from "react-router-dom";
import "./css/AppLayout.css";
import Col from "antd/lib/col";
import Layout from "antd/lib/layout";
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import LoginOutlined from "@ant-design/icons/LoginOutlined";
import {Divider, Dropdown, MenuProps} from "antd";
import Cookies from "universal-cookie";
import EnvironmentFilled from "@ant-design/icons/EnvironmentFilled";
import Row from "antd/lib/row";
import Drawer from "antd/lib/drawer";
import {DownOutlined} from "@ant-design/icons";
import MenuConfig from "../../config/dataConfig/menuConfig";

const {Header} = Layout;

class NormalHeader extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda";

        this.initState = {
            openDrawer: false,
            listCityGroup: [],
        }
        this.state = {...this.initState}
    }

    private cookies = new Cookies();

    logout = async () => {
        const c = this.cookies.get('_amh-t');
        if (c) {
            await this.cookies.remove('_amh-t');
            await this.cookies.remove('_amh-n');
            await this.cookies.remove('_amh-p');
            await this.cookies.remove('_amh-e');
            await this.cookies.remove('_amh-ut');
            await this.cookies.remove('_amh-uc');
            await this.cookies.remove('_amh-ui');
            await this.cookies.remove('_amh-aff-c');
            await this.cookies.remove('_amh-aff-i');
        }
        window.location.href = "/login";
    }

    showChangeLocationDrawer = async () => {
        this.setState({
            openDrawer: true,
        });
    }

    changeLocation = async (data: any) => {
        this.props.onAreaChangeProps(data);
        this.setState({
            openDrawer: false,
        });
    }

    async componentDidMount() {
        try {
            const listCityGroup = await trimitraCatalogServices.getCityGroup({});
            this.setState({
                listCityGroup: listCityGroup.data.data
            });
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const c = this.cookies.get('_amh-t');
        const itemMenu: MenuProps['items'] = MenuConfig;
        const itemLogout: MenuProps['items'] = [{type: 'divider',}, {key: '99', label: (<div onClick={this.logout}>Log Out</div>)}];
        const items: any = itemMenu?.concat(itemLogout);

        return (
            <React.Fragment>
                {!this.props.isDesktop ? (
                    <Col xl={{span: 16, offset: 4}} lg={{span: 16, offset: 4}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Header className={`desktop-mobile`}>
                            <Row>
                                <Col span={16}>
                                    <Link to={`/login`} style={!c ? {} : {display: 'none'}}>
                                        <LoginOutlined className={`header-home-top`}/>
                                    </Link>
                                    <span style={c ? {} : {display: 'none'}}>
                                        <Dropdown menu={{items}} trigger={['click']} placement="bottomLeft">
                                            <MenuOutlined className={`header-home-top`}/>
                                        </Dropdown>
                                    </span>
                                    <Link to={`/${this.props.areaCode}`}>
                                        <img style={{height: 22, paddingLeft: 15, marginTop: "-10px", cursor: "pointer"}} className={`header-logo`} src={'https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/header-text.png'} alt={`amartahonda-logo`}/>
                                    </Link>
                                </Col>
                                <Col span={8} onClick={this.showChangeLocationDrawer} style={{textAlign: "right", cursor: "pointer"}}>
                                    <span style={{fontSize: "90%", color: "#4b4b4b", fontWeight: 500, paddingRight: 7}}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span>
                                    <EnvironmentFilled style={{fontSize: "140%"}} className={`header-home-top`}/>
                                </Col>
                            </Row>
                        </Header>
                    </Col>
                ) : (
                    <div style={{position: "fixed", width: "100%", marginBottom: 20, zIndex: 500, marginTop: -5, paddingTop: 0}}>
                        <Header className={`desktop-header shadow-on-bottom`} style={{paddingTop: 5}}>
                            <Col xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                <Row>
                                    <Col xl={{span: 9}} lg={{span: 9}}>
                                        <Link to={`/login`} style={!c ? {} : {display: 'none'}}>
                                            <LoginOutlined className={`header-home-top`}/>
                                        </Link>
                                        <span style={c ? {} : {display: 'none'}}>
                                            <Dropdown menu={{items}} trigger={['click']} placement="bottomLeft">
                                                <MenuOutlined className={`header-home-top`}/>
                                            </Dropdown>
                                        </span>
                                        <Link to={`/${this.props.areaCode}`}>
                                            <img style={{height: 22, paddingLeft: 15, marginTop: "-10px", cursor: "pointer"}} className={`header-logo`} src={'https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/header-text.png'} alt={`amartahonda-logo`}/>
                                        </Link>
                                    </Col>
                                    <Col xl={{span: 10}} lg={{span: 3}}>

                                    </Col>
                                    <Col xl={{span: 5}} lg={{span: 6}} style={{textAlign: "right"}}>
                                        <EnvironmentFilled onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", fontSize: "140%"}} className={`header-home-top`}/>
                                        <span onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", fontSize: "90%", color: "#4b4b4b", fontWeight: 500, paddingRight: 7}}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span>
                                        <DownOutlined onClick={this.showChangeLocationDrawer} style={{cursor: "pointer", fontSize: "100%"}} className={`header-home-top`}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Header>
                    </div>
                )}

                <Col xl={{span: 16, offset: 4}} lg={{span: 16, offset: 4}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>

                    {/*FIXED HEDADER GAP*/}
                    {this.props.isDesktop ? (
                        <div style={{paddingTop: 70}}></div>
                    ) : (null)}

                    <Drawer
                        title="Ubah Lokasi"
                        placement={(this.props.isDesktop) ? `right` : `top`}
                        onClose={() => this.setState({openDrawer: false})}
                        open={this.state.openDrawer}
                        // getContainer={false}
                        height={"100vh"}
                        closable={true}
                    >
                        <div style={{textAlign: "center"}}>
                            <i style={{color: "#7a7a7a"}}>Lokasi Saat Ini:</i> <br/>
                            <EnvironmentFilled className={`header-home-top`} style={{color: "#64b48f"}}/> <b style={{fontSize: "120%", color: "#64b48f"}}>Area {this.props.areaCode?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</b> <br/>
                            <div style={{marginTop: 20, color: "#7a7a7a"}}>Pilih area terdekat anda untuk mendapatkan informasi <br/> harga dan promo yang berlaku di area anda.</div>
                        </div>
                        <Divider/>
                        <span style={{color: "#b0b0b0"}}>PILIH LOKASI</span>
                        <div style={{paddingTop: 15, paddingBottom: 60}}>
                            <Row>
                                {this.state.listCityGroup.map((item: any, i: number) =>
                                    <Col span={12} key={i} style={{padding: 7, cursor: "pointer"}}>
                                        <span onClick={() => {
                                            this.changeLocation(item)
                                        }}><EnvironmentFilled/> {item?.city_group.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</span><br/>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        &nbsp;
                    </Drawer>
                </Col>
            </React.Fragment>
        );
    }
}

export default NormalHeader;
