import axios from "axios";
import {baseUrlAmartaVip, xApiKeyAmartaVip} from "../../config/apiConfig/apiConfig";
import Cookies from "universal-cookie";

class AuthApiService {
    private cookies = new Cookies();

    private axios = axios.create({
        baseURL: baseUrlAmartaVip,
    });

    public setToken(token: string) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    }

    public async login(params: any) {
        try {
            return await this.axios.post<any>('/auth/login', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async register(params: any) {
        try {
            return await this.axios.post<any>('/auth/register', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async removeAllToken() {
        await this.cookies.remove('_amh-t');
        await this.cookies.remove('_amh-n');
        await this.cookies.remove('_amh-p');
        await this.cookies.remove('_amh-e');
        await this.cookies.remove('_amh-ut');
        await this.cookies.remove('_amh-uc');
        await this.cookies.remove('_amh-ui');
        await this.cookies.remove('_amh-aff-c');
        await this.cookies.remove('_amh-aff-i');
    }

    public async checkJwt() {
        try {
            return await this.axios.get<any>(`/auth/check`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async validateJwt() {
        try {
            return await this.axios.get<any>(`/auth/validate`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                    "App-Code": "triforce",
                    "App-Build-Code": "2",
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async isExistAmartaVipToken() {
        const c = this.cookies.get('_amh-t');
        return (c) ? c : false;
    }

    public async isExistAmartaVipUserId() {
        const c = this.cookies.get('_amh-ui');
        return (c) ? c : false;
    }

    public async isExistAmartaVipInternalAffiliateData() {
        const affiliateCompanyCode = this.cookies.get('_amh-aff-c');
        const affiliateFincoId = this.cookies.get('_amh-aff-i');

        const userName = this.cookies.get('_amh-n');
        const userType = this.cookies.get('_amh-ut');
        const userUid = this.cookies.get('_amh-ui');
        const userAgentCode = this.cookies.get('_amh-uc');

        return {
            affiliateCompanyCode: (affiliateCompanyCode) ? affiliateCompanyCode : null,
            affiliateFincoId: (affiliateFincoId) ? affiliateFincoId : null,
            userName: (userName) ? userName : null,
            userType: (userType) ? userType : null,
            userUid: (userUid) ? userUid : null,
            userAgentCode: (userAgentCode) ? userAgentCode : null,
        }
    }

    public async getUserBiodata() {
        try {
            return await this.axios.get<any>(`/profile`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getUserOrder(params: any) {
        const queries = {
            source: "amartahonda",
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            })
        }

        try {
            return await this.axios.get<any>(`/profile/order`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async getDetailUserOrder(params: any) {
        const queries = {
            source: "amartahonda",
        }

        try {
            return await this.axios.get<any>(`/profile/order/${params?.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            console.log(e)
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async updateBiodata(inputData?: any) {
        try {
            return await this.axios.put<any>('/profile',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async sendResetPassword(params: any) {
        try {
            return await this.axios.post<any>('/user/send-reset-password', params, {
                headers: {
                    "X-Api-Key": xApiKeyAmartaVip,
                }
            });
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }

    public async resetPassword(inputData?: any) {
        try {
            return await this.axios.put<any>('/user/reset-password',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyAmartaVip
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const authApiService = new AuthApiService();
