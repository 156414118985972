import axios from "axios";
import {baseUrlSubAndNotify, xApiKeySubAndNotify} from "../../config/apiConfig/apiConfig";

class SubApiService {
    private axios = axios.create({
        baseURL: baseUrlSubAndNotify,
    });

    public async createSubscription(token: string) {
        try {
            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            return await this.axios.post<any>('/event-subscription', {
                company: "amarta",
                subscription_category: "EVENT",
                subscription_code: "LIVE",
                subscription_notes: "-"
            }, {
                headers: {
                    "X-Api-Key": xApiKeySubAndNotify,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const subApiService = new SubApiService();