import React, {Component} from 'react';
import MenuOutlined from "@ant-design/icons/MenuOutlined";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {Header} from "antd/es/layout/layout";
import {Dropdown, MenuProps} from "antd";
import {Link} from "react-router-dom";

import Cookies from "universal-cookie";
import MenuConfig from "../../config/dataConfig/menuConfig";

class MyProfileLoginHeader extends Component<any> {
    private cookies = new Cookies();

    logout = async () => {
        const c = this.cookies.get('_amh-t');
        if (c) {
            await this.cookies.remove('_amh-t');
            await this.cookies.remove('_amh-n');
            await this.cookies.remove('_amh-p');
            await this.cookies.remove('_amh-e');
            await this.cookies.remove('_amh-ut');
            await this.cookies.remove('_amh-uc');
            await this.cookies.remove('_amh-ui');
            await this.cookies.remove('_amh-aff-c');
            await this.cookies.remove('_amh-aff-i');
        }

        window.location.href = "/login";
    }

    render() {
        const itemHome: MenuProps['items'] = [{
            key: '0',
            label: (<Link to={`/bandung`}>
                <div style={{minWidth: 160}}>Home</div>
            </Link>)
        }];
        const itemMenu: any = MenuConfig;
        const itemLogout: MenuProps['items'] = [{type: 'divider',}, {key: '99', label: (<div onClick={this.logout}>Log Out</div>)}];
        const items: any = [...itemHome, ...itemMenu, ...itemLogout];
        return (
            <Header className={`desktop-header-search`} style={{backgroundColor: '#fff'}}>
                <Row>
                    <Col span={16}>
                        <span>
                            <Dropdown menu={{items}} trigger={['click']} placement="bottomLeft">
                                <MenuOutlined className={`header-home-top`}/>
                            </Dropdown>
                        </span>
                        <Link to={`/bandung`}>
                            <img style={{height: 22, paddingLeft: 15, marginTop: "-10px"}} className={`header-logo`} src={'https://trimitra-catalog.s3.ap-southeast-1.amazonaws.com/asset/header-text.png'} alt={`amartahonda-logo`}/>
                        </Link>
                    </Col>
                    <Col span={8} style={{textAlign: "right"}}>
                        <div style={{fontSize: "110%", color: "#4b4b4b", fontWeight: 500, paddingRight: 7, paddingTop: 5}}>{this.props.title?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default MyProfileLoginHeader;
