import React, {Component} from 'react';
import withAuth from "../../hoc/withAuth";
import "../../screen/home/Home.css";
import {CreatePricelistStates} from "./types/CreatePricelistTypes";
import 'reactjs-popup/dist/index.css';
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import NormalHeader from "../../components/layouts/NormalHeader";
import {Divider} from "antd";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import moment from "moment";
import VehicleModelSelect from "../../components/vehicle-copan/VehicleModelSelect";
import VehicleVariantSelect from "../../components/vehicle-copan/VehicleVariantSelect";
import Spin from "antd/lib/spin";
import Button from "antd/lib/button";
import FileSearchOutlined from "@ant-design/icons/FileSearchOutlined";
import {b2bService} from "../../services/b2b/b2bService";
import Result from "antd/lib/result";
import Modal from "antd/lib/modal";

class CreatePricelist extends Component<any, CreatePricelistStates> {
    private readonly initState!: CreatePricelistStates;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda | Order Memo";

        this.initState = {
            company: "AMARTA",
            areaCode: "BANDUNG",
            isDesktop: false,
            showModalMasterPricelist: false,
            freezeStatus: false,
            loadPricelist: false,
            errorPricelist: false,

            title: "Spesial Promo Penawaran Terbaik",
            expiredDate: moment(new Date(), 'YYYY-MM-DD').add(14, 'days'),
            vehicleBrand: "HONDA",
            vehicleModel: null,
            vehicleModelUuid: null,
            vehicleVariantCode: null,
            vehicleVariantUuid: null,
            vehicleVariantName: null,
            dataMasterPricelist: [],

        }
        this.state = {...this.initState}
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({
                isDesktop: false,
            });
        } else {
            this.setState({
                isDesktop: true,
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();
    }

    onFieldChange = <T extends keyof Pick<any, "title" | "expiredDate">>(target: T, value: any) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "title":
                currentState.title = value;
                break;
            case "expiredDate":
                currentState.expiredDate = value;
                break;
        }

        this.setState({
            ...currentState,
        });
    }


    onAreaChange = async (data: any) => {
        await this.promisedSetState({
            areaCode: data.city_group,
        });
    }

    onVehicleModelChange = async (data: any) => {
        await this.promisedSetState({
            vehicleModel: data?.value,
            vehicleModelUuid: data?.uuid,
            vehicleVariantCode: null,
            vehicleVariantUuid: null,
            vehicleVariantName: null,
        });
    }

    onVehicleVariantChange = async (data: any) => {
        if (data) {
            await this.promisedSetState({
                vehicleVariantCode: data.value,
                vehicleVariantName: data?.variant_name,
                vehicleVariantUuid: data?.uuid,
            });

            await this.onFetchPricelist();
        }
    }

    onFetchPricelist = async () => {
        await this.promisedSetState({
            loadPricelist: true
        });

        try {
            const dataMasterPricelist = await b2bService.getmasterPricelist({
                cityGroup: this.state.areaCode,
                leasingCode: "_GLOBAL_",
                modelName: this.state.vehicleModel,
                variantCode: this.state.vehicleVariantCode,
            });
            const filteredData = dataMasterPricelist?.data?.data?.formatted.filter((item: any) => item.installment !== null);
            this.setState({
                loadPricelist: false,
                errorPricelist: false,
                dataMasterPricelist: filteredData,
            });
        } catch (e) {
            this.setState({
                loadPricelist: false,
                errorPricelist: true,
            })
        }
    }


    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>
                <NormalHeader areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}/>

                <Row className={`mt-20`} style={{backgroundColor: "#f8f8f8"}}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Row>
                            <Col className={`p-10`} xxl={{span: 16}} xl={{span: 16}} md={{span: 16}} sm={{span: 24}} xs={{span: 24}}>
                                <div className={`detail-product-title`}>Pricelist AmartaHonda {this.state.areaCode}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className={`mt-20`}>
                    <Col className={`p-20`} xxl={{span: 16, offset: 4}} xl={{span: 16, offset: 4}} lg={{span: 20, offset: 2}} md={{span: 20, offset: 2}} sm={{span: 24}} xs={{span: 24}}>
                        <Divider plain={true} orientation="left">Pricelist Kendaraan</Divider>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 20, paddingRight: 20}}>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Judul Pricelist*</i>
                                    <Input value={this.state.title} onChange={event => this.onFieldChange("title", event.target.value)} placeholder="Masukan Judul Pricelist"/>
                                </div>
                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 20, paddingRight: 20}}>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Tanggal Akhir Berlaku PriceList *</i>
                                    <DatePicker value={moment(this.state.expiredDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("expiredDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 20, paddingRight: 20}}>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Model</i><br/>
                                    <VehicleModelSelect onVehicleModelChangeProps={this.onVehicleModelChange} companyCode={this.state.company} cityGroup={this.state.areaCode} value={this.state.vehicleModel} brandCode={this.state.vehicleBrand} category={"motorcycle"} time={Math.floor(+new Date() / 1000)}/>
                                </div>
                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}} style={{paddingLeft: 20, paddingRight: 20}}>
                                <div className={`mt-5`}>
                                    <i className={`small-text-grey`}>Variant</i><br/>
                                    <VehicleVariantSelect onVehicleVariantChangeProps={this.onVehicleVariantChange} companyCode={this.state.company} cityGroup={this.state.areaCode} brandCode={this.state.vehicleBrand} modelCode={this.state.vehicleModel} value={this.state.vehicleVariantCode} time={Math.floor(+new Date() / 1000)}/>
                                </div>
                            </Col>
                        </Row>

                        <Row style={this.state.loadPricelist ? {textAlign: "center", padding: 70, minHeight: 1080} : {display: 'none'}}>
                            <Col span={24} style={{paddingLeft: 20, paddingRight: 20}}>
                                <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                            </Col>
                        </Row>
                        <Row style={!this.state.loadPricelist && this.state.errorPricelist ? {} : {display: 'none'}}>
                            <Col span={24} style={{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
                                <Result title="Cicilan untuk Variant yang dipilih belum tersedia, silakan gunakan variant lain."/>
                            </Col>
                        </Row>


                        <Row style={!this.state.loadPricelist && this.state.vehicleVariantCode && !this.state.errorPricelist ? {} : {display: 'none'}}>
                            <Col span={24} style={{paddingLeft: 20, paddingRight: 20, paddingTop: 20}}>
                                <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => this.setState({showModalMasterPricelist: true})} type="primary"><FileSearchOutlined/> Lihat Master Pricelist</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Modal
                    title={`Master Pricelist ${this.state.vehicleVariantName}`}
                    open={this.state.showModalMasterPricelist}
                    onCancel={() => {
                        this.setState({showModalMasterPricelist: false})
                    }}
                    onOk={() => {
                        this.setState({showModalMasterPricelist: false})
                    }}
                    footer={[
                        <Button key="close" onClick={() => {
                            this.setState({showModalMasterPricelist: false})
                        }}>Close</Button>
                    ]}
                >

                </Modal>
            </React.Fragment>
        );
    }
}

export default withAuth(CreatePricelist);