/**
 * PRODUCTION LINK.
 */
const baseUrlTrimitraCatalog = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlCdnOrc = "https://asia-southeast1-autotrimitra.cloudfunctions.net/api-cdn-trimitra-biz__ocr-document";
const baseUrlAutotrimitra = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev";
const baseUrlB2b = "https://asia-southeast2-amh-b2b.cloudfunctions.net/api-b2b";
const baseUrlAmh = "https://order.amartahonda.com/rest/index.php/api/amh/orderbaru";
const baseUrlPromo = "https://80pj23grqd.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlOffer = "https://42cjbxpaa8.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlCreditSimulator = "https://fh63sf7bye.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlTrack = "https://w7ycz3l377.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlLogBq = "https://asia-southeast2-autotrimitra.cloudfunctions.net/activityLog-AmartahondaBQ";
const baseUrlBooking = "https://nc048fl7l8.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlLogAmartaHonda = "https://asia-southeast2-autotrimitra.cloudfunctions.net/amartahonda__log_promo_BQ";
const baseUrlOpenSearch = "https://asia-southeast2-ideal-trimitra.cloudfunctions.net/api-open-search";
const baseUrlCrm = "https://asia-southeast2-ideal-trimitra.cloudfunctions.net/api-check-phone-number-crm";
const baseUrlSubAndNotify = "https://dlh1y5kkh6.execute-api.ap-southeast-3.amazonaws.com/v1";

const baseUrlMomotor = "http://34.101.167.126:8000";


/**
 * KEY.
 */
const xApiKeyTrimitraCatalog = '5ukoYcllpl6lIeKsbeIPI4hOZGDszFVk1dDBddHi';
const xApiKeyCdnUpload = 'zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0';
const xApiKeyOffer = '9bekwohY878MgiMsRL0Wk2Xsgv4QsxtW4jEIuBqb';
const xApiKeyPromo = 'itMzx2LEJU2VtfzkBsZXs7U4p2ScXXXn4qY3Xdbw';
const xApiKeyCreditSimulator = '-';
const xApiKeyTrack = '92b53lWKwg1mu2sRDumFl5Q158DQjbRh8XlH8VX0';
const xApiKeyLogTrack = 'XQD8cgJDI82tGlDph0oAzVmq6JTqEWS2XEPcqhEh';
const xApiKeyAmartaVip = 'u4xYpyMOX7H7Gxb0maT19w5SzLsRU2q8aMeJ3Ln8';
const xApiKeyLogAmartaHonda = '3ca22b78r3b9f644545b164p462857e8f29e';
const secretKeyOcr = 'Mn3zrubT3AM5NA8UFXZO6BgV6Rd69YrH';
const xApiKeySubAndNotify = 'YoNCgaeOHN83vYfSM1QLc1rujF3BQyvO4cSHK5mz';

export {
    baseUrlLogBq, baseUrlMomotor, baseUrlSubAndNotify, xApiKeySubAndNotify, baseUrlTrimitraCatalog, baseUrlCdnOrc, secretKeyOcr, baseUrlOpenSearch, baseUrlAutotrimitra, baseUrlCdnUpload, baseUrlB2b, baseUrlAmh, baseUrlOffer, baseUrlPromo, xApiKeyTrimitraCatalog, xApiKeyCdnUpload, xApiKeyOffer, xApiKeyPromo, baseUrlCreditSimulator, xApiKeyCreditSimulator, baseUrlTrack, xApiKeyTrack, baseUrlBooking, xApiKeyLogTrack, baseUrlAmartaVip, xApiKeyAmartaVip, baseUrlLogAmartaHonda, xApiKeyLogAmartaHonda, baseUrlCrm
}
