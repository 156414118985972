import React, {Component} from 'react';
import {HomeStates} from "./types/HomeTypes";
import {withRouter} from "../../hoc/withRouter";
import {Link} from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import AppLayout from "../../components/layouts/AppLayout";
import {CardProduct} from "../../components/product/CardProduct";
import {CardCatalog} from "../../components/product/CardCatalog";
import Testimonial from "../../components/testimonial/Testimonial";
import FooterReasonBuy from "../../components/footer/FooterReasonBuy";
import AllNewModelProduct from "../../components/product/allNewModelProduct";
import configHomeArea from "../../config/setupConfig/homeInitConditionConf";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Spin from "antd/lib/spin";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import "./Home.css";
import {trackLogServices} from "../../services/track/TrackLogService";
import Cookies from "universal-cookie";
import getHereLocation from "../../helper/getHereLocation";
import FindingRightProduct from "../../components/landing/FindingRightProduct";
import LandingCarousel from "../../components/landing/LandingCarousel";
import HomeMenu from "../../components/landing/HomeMenu";
import Carousel from "antd/lib/carousel";
import FooterSection from "../../components/footer/FooterSection";
import {Divider} from "antd";

class Home extends Component<any, HomeStates> {
    private readonly initState!: HomeStates;

    constructor(props: any) {
        super(props);
        document.title = "Amartahonda";
        this.initState = {
            areaCode: "",
            loading: true,
            listHomepageCuration: [],
            showAllNewModelProduct: false,
            newProductClass: configHomeArea.newProductClass.toUpperCase(),
            listHomepageCatalog: [],
            ip: null,
            latitude: null,
            longitude: null,
            homeBanner: [],
            homeMenu: [],
            homeComponent: {},
            landingActive: false,
            landingImage: "",
            landingTargetUrl: "# ",
            landingTargetOpen: "_self",
            fetchStock: true,
            stockData: {},
            modalChangeCityGroup: false,
            isDesktop: false,
        }
        this.state = {...this.initState}
    }

    onCityChange = async (dataCityGroup: any) => {
        this.props.history.push("/" + dataCityGroup.city_group.toLowerCase());
        document.title = "Amartahonda | " + dataCityGroup?.city_group?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase());
        await this.promisedSetState({
            areaCode: dataCityGroup.city_group,
            listHomepageCatalog: [],
            listHomepageCuration: {catalog_list: [], show_default_model_product: true},
        });
        await this.fetchCityCuration();
    }

    fetchCityCuration = async () => {
        try {
            await trimitraCatalogServices.getCurationHomepage({
                areaCode: this.state.areaCode,
            }).then(async dataCurationHome => {
                const activeMenu: any = [];
                let activeComponent: any = {};
                for (const item of dataCurationHome?.data?.data?.home_setting?.home_menu) {
                    if (item.active) {
                        activeMenu.push(item);
                    }
                }

                //customComponent
                for (const cusComp of dataCurationHome?.data?.data?.home_setting?.custom_component) {
                    if (cusComp.active === true && cusComp.code === 'finding_right_product') {
                        activeComponent['finding_right_product'] = true;
                    }
                    if (cusComp.active === true && cusComp.code === 'testimonial') {
                        activeComponent['testimonial'] = true;
                    }
                    if (cusComp.active === true && cusComp.code === 'amh_advantages') {
                        activeComponent['amh_advantages'] = true;
                    }
                }

                await this.promisedSetState({
                    listHomepageCuration: dataCurationHome?.data?.data ?? [],
                    homeBanner: dataCurationHome?.data?.data?.home_setting?.banner,
                    homeMenu: activeMenu,
                    homeComponent: activeComponent,
                    loading: false,
                    landingActive: dataCurationHome?.data?.data?.home_setting?.landing_block?.active ?? false,
                    landingImage: dataCurationHome?.data?.data?.home_setting?.landing_block?.url ?? "",
                    landingTargetUrl: dataCurationHome?.data?.data?.home_setting?.landing_block?.target_link ?? "# ",
                    landingTargetOpen: dataCurationHome?.data?.data?.home_setting?.landing_block?.target_type ?? "_self",
                    showAllNewModelProduct: dataCurationHome?.data?.data?.show_default_model_product,
                })
                await this.fetchHomepageCuration();

                this.props.history.push("/" + this.state.areaCode.toLowerCase());
            });
        } catch (e) {
            this.setState({
                loading: false,
                showAllNewModelProduct: true,
            });
            this.props.history.push("/notfound");
        }
    }

    fetchHomepageCuration = async () => {
        const listHomePageCuration = this.state.listHomepageCuration?.catalog_list;
        const tempFullCatalogResponse: any = [];
        await Promise.all(listHomePageCuration.map(async (item: any) => {
            try {
                const dataCuration = await trimitraCatalogServices.getListCatalog({
                    areaCode: this.state.areaCode,
                    code: item.code
                });
                tempFullCatalogResponse.push({
                    code: dataCuration?.data?.meta?.code,
                    name: dataCuration?.data?.meta?.name,
                    destinationLink: dataCuration?.data?.meta?.destination_link,
                    displayLayout: dataCuration?.data?.meta?.display_layout,
                    data: dataCuration?.data?.data
                });
            } catch (e) {
                console.log(e);
            }
        }));

        const listHomepageCatalog = [];
        for (const x of listHomePageCuration) {
            const picked = tempFullCatalogResponse.find((o: { code: string; }) => o.code === x?.code);
            listHomepageCatalog.push(picked)
        }

        await this.promisedSetState({
            listHomepageCatalog: listHomepageCatalog
        });
    }

    fetchStock = async () => {
        trimitraCatalogServices.getStock({areaCode: this.state.areaCode})
            .then(dataFetchStock => {
                this.setState({
                    stockData: dataFetchStock?.data,
                    fetchStock: false,
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleResize = () => {
        if (window.innerWidth < 992) {
            this.setState({isDesktop: false});
        } else {
            this.setState({isDesktop: true});
        }
    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize.bind(this));
        this.handleResize();

        const {match} = this.props;
        const cookies = new Cookies();
        const hereCookies = cookies.get('_here');
        if (hereCookies) {
            if (!match.params.area) {
                console.log('city get fom here');
                await this.promisedSetState({
                    areaCode: getHereLocation(),
                });
            } else {
                await this.promisedSetState({
                    areaCode: match.params.area.trim().toUpperCase(),
                });
            }
        } else {
            const cityInit = (match.params.area) ? match.params.area.trim().toUpperCase() : configHomeArea.areaCode;
            await this.promisedSetState({
                areaCode: cityInit,
            });
        }

        await this.onCityChange({
            area: this.state.areaCode,
            city_group: this.state.areaCode,
            city_group_name: this.state.areaCode,
            additional_city_group_price: 0 // count of additional city group // not used in this case
        });

        // LOG
        try {
            await trackLogServices.getReverseLocation({
                eventName: `home page`,
                eventSourceUrl: window.location.href,
                customData: null
            }).then(resp => {
                if (!hereCookies) {
                    setTimeout(() => {
                        if (getHereLocation() !== this.state.areaCode) {
                            this.setState({
                                modalChangeCityGroup: true,
                            })
                        }
                    }, 3500);
                }
            })
        } catch (e) {
            console.log(e);
        }

        // await this.fetchStock();
    }

    closeLandingBlockModal = async () => {
        await this.promisedSetState({
            landingActive: false
        });
    }

    changeCityGroupByHere = async () => {
        await this.promisedSetState({modalChangeCityGroup: false});
        await this.onCityChange({
            area: getHereLocation(),
            city_group: getHereLocation(),
            city_group_name: getHereLocation(),
            additional_city_group_price: 0
        });
    }

    onAreaChange = (data: any) => {
        this.onCityChange(data);
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <React.Fragment>

                {/*Loading Pre Load*/}
                <div style={!this.state.loading ? {display: 'none'} : {textAlign: "center", padding: 70, minHeight: 1080}}>
                    <div style={{marginTop: 150, textAlign: "center"}}>
                        <img className={`header-logo`} src={'assets/img/favicon.png'} alt={`amartahonda-logo`}/>
                        <b style={{color: '#64b48f', fontSize: '125%'}}>AMARTAHONDA</b>
                    </div>
                    <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                </div>
                {/*Loading Pre Load*/}

                <div style={this.state.loading ? {display: 'none'} : {}}>
                    <AppLayout areaCode={this.state.areaCode} onAreaChangeProps={this.onAreaChange} isDesktop={this.state.isDesktop}>

                        {/*Landing Block*/}
                        <Popup open={this.state.landingActive} closeOnDocumentClick onClose={this.closeLandingBlockModal}>
                            <div className="modal">
                                <b className="close" onClick={this.closeLandingBlockModal}>
                                    &times;
                                </b>
                                <a href={this.state.landingTargetUrl} target={this.state.landingTargetOpen}>
                                    <img
                                        style={{width: "100%", borderTopRightRadius: 10, borderTopLeftRadius: 10, cursor: "pointer"}}
                                        alt={`landing`}
                                        src={this.state.landingImage}
                                    />
                                </a>
                            </div>
                        </Popup>
                        {/*End of Landing Block*/}

                        <Modal
                            title="Informasi"
                            open={this.state.modalChangeCityGroup}
                            maskClosable={false}
                            closable={false}
                            footer={[
                                <Button key={1} style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f"}} onClick={this.changeCityGroupByHere}> Ya </Button>,
                                <Button key={2} onClick={() => this.setState({modalChangeCityGroup: false})}> Tidak </Button>,
                            ]}
                        >
                            <p>Apakah anda ingin menggunakan area {getHereLocation()} sebagai area anda?</p>
                        </Modal>

                        {/*Banner Carousel*/}
                        <LandingCarousel homeBanner={this.state.homeBanner}/>

                        {/*CustomComponent - Finding Right Product*/}
                        {this.state.homeComponent.finding_right_product ? <FindingRightProduct/> : <div style={{margin: 20}}/>}

                        {/* Home Menu */}
                        <HomeMenu homeMenu={this.state.homeMenu} areaCode={this.state.areaCode}/>

                        {/* All Curation Catalog */}
                        {this.state.listHomepageCatalog.map((listCatalogData: any, x: number) =>
                            <React.Fragment key={x}>
                                <div className={`chosen-product pt-20`}>
                                    <span className={`chosen-product-text ${(this.state.isDesktop) ? "font-140" : ""}`}>{listCatalogData.name}</span>
                                    {listCatalogData.destinationLink === 'variant' &&
                                        <Link to={`/baru/${this.state.areaCode.toLowerCase()}/variant/${listCatalogData.code}`}><span className={`chosen-product-other`}> Lihat Semua <DoubleRightOutlined/></span></Link>
                                    }
                                    {listCatalogData.destinationLink === 'used' &&
                                        <Link to={`/mokas`}><span className={`chosen-product-other`}> Lihat Semua <DoubleRightOutlined/></span></Link>
                                    }
                                </div>

                                {listCatalogData?.displayLayout === 'horizontal-carousel' &&
                                    <React.Fragment>
                                        <Carousel
                                            style={{padding: 10}}
                                            autoplay={false} dots={true}
                                            speed={500}
                                            infinite={false}
                                            slidesToShow={(this.state.isDesktop) ? 5 : 2}
                                            slidesToScroll={(this.state.isDesktop) ? 5 : 2}
                                            draggable={true}
                                        >
                                            {listCatalogData.data.map((item: any, i: number) => {
                                                if (item.entity_type === 'variant') {
                                                    return (
                                                        <div key={i}>
                                                            <CardProduct option={'NEW_VARIANT'} fetchStock={this.state.fetchStock} stock={this.state.stockData?.data?.[item.variant_code] ?? {stock_qty: 0}} stockLimit={this.state.stockData?.meta?.availability_limit ?? 0} productImageUrl={item.url_image} product={item.variant_code} productArea={this.state.areaCode} productName={item.variant_name} productPrice={item.price_min} productStrikethroughtPrice={item.price_strikethrough}/>
                                                            <div style={{height: 10}}/>
                                                        </div>
                                                    );
                                                } else if (item.entity_type === 'model') {
                                                    return (
                                                        <div key={i}>
                                                            <CardProduct option={'NEW_MODEL'} productImageUrl={item.url_image} product={item.model_name} productArea={this.state.areaCode} productName={item.model_name} productPrice={item.price_min} productStrikethroughtPrice={item.price_strikethrough}/>
                                                            <div style={{height: 10}}/>
                                                        </div>
                                                    )
                                                } else if (item.entity_type === 'used_vehicle') {
                                                    return (
                                                        <div key={i}>
                                                            <CardProduct option={'MOKAS'} registration_year={item.registration_year} productImageUrl={item.url_image} product={item.license_plate} productArea={this.state.areaCode} productName={item.variant_name} productPrice={item.price} productStrikethroughtPrice={item.price_strikethrough}/>
                                                            <div style={{height: 10}}/>
                                                        </div>
                                                    )
                                                } else if (item.entity_type === 'catalog') {
                                                    return (
                                                        <div key={i}>
                                                            <CardCatalog budgetImage={item?.url_image} budgetVariantLink={item?.catalog_code} budgetArea={this.state?.areaCode} budgetText={""}/>
                                                            <div style={{height: 10}}/>
                                                        </div>
                                                    )
                                                } else {
                                                    return '';
                                                }
                                            })}
                                        </Carousel>
                                        <Divider/>
                                    </React.Fragment>
                                }

                                {listCatalogData?.displayLayout !== 'horizontal-carousel' &&
                                    <React.Fragment>
                                        <Row className={`home-row-chosen-product`}>
                                            {listCatalogData.data.map((item: any, i: number) => {
                                                if (item.entity_type === 'variant') {
                                                    return (
                                                        <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                                            <CardProduct option={'NEW_VARIANT'} fetchStock={this.state.fetchStock} stock={this.state.stockData?.data?.[item.variant_code] ?? {stock_qty: 0}} stockLimit={this.state.stockData?.meta?.availability_limit ?? 0} productImageUrl={item.url_image} product={item.variant_code} productArea={this.state.areaCode} productName={item.variant_name} productPrice={item.price_min} productStrikethroughtPrice={item.price_strikethrough}/>
                                                        </Col>
                                                    )
                                                } else if (item.entity_type === 'model') {
                                                    return (
                                                        <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                                            <CardProduct option={'NEW_MODEL'} productImageUrl={item.url_image} product={item.model_name} productArea={this.state.areaCode} productName={item.model_name} productPrice={item.price_min} productStrikethroughtPrice={item.price_strikethrough}/>
                                                        </Col>
                                                    )
                                                } else if (item.entity_type === 'used_vehicle') {
                                                    return (
                                                        <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                                            <CardProduct option={'MOKAS'} registration_year={item.registration_year} productImageUrl={item.url_image} product={item.license_plate} productArea={this.state.areaCode} productName={item.variant_name} productPrice={item.price} productStrikethroughtPrice={item.price_strikethrough}/>
                                                        </Col>
                                                    )
                                                } else if (item.entity_type === 'catalog') {
                                                    return (
                                                        <Col xl={{span: 6}} lg={{span: 5}} md={{span: 12}} sm={{span: 12}} xs={{span: 12}} key={i}>
                                                            <CardCatalog budgetImage={item?.url_image} budgetVariantLink={item?.catalog_code} budgetArea={this.state?.areaCode} budgetText={""}/>
                                                        </Col>
                                                    )
                                                } else {
                                                    return '';
                                                }
                                            })}
                                        </Row>
                                        <Divider/>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}
                        {/* End Of All Curation Catalog */}


                        {/*CustomComponent - All Product Model*/}
                        {this.state.showAllNewModelProduct && <AllNewModelProduct areaCode={this.state.areaCode} showAllNewModelProduct={this.state.showAllNewModelProduct}/>}

                        <Row style={{paddingTop: 50, paddingBottom: 50}}>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                {/*CustomComponent - AMH Advantages*/}
                                {this.state.homeComponent.amh_advantages && <FooterReasonBuy/>}
                            </Col>
                            <Col xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                {/*CustomComponent - Testimonial*/}
                                {this.state.homeComponent.testimonial && <Testimonial/>}
                            </Col>
                        </Row>
                    </AppLayout>

                    <FooterSection areaCode={this.state.areaCode}/>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Home);