import React, {Component} from "react";
import Select from "antd/lib/select";

class CustomerMariageAge extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            customerMaritalStatus: null,
        }
        this.state = {...this.initState}
    }

    onChange = (value: any, option: any) => {
        this.setState({
            customerMaritalStatus: value,
        });
        this.props.onCustomerMariageAgeChangeProps(option);
    }

    render() {
        return (
            <Select
                placeholder="Usia Pernikahan"
                showSearch
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.props.value ?? this.state.customerMaritalStatus}
            >
                <Select.Option value="1">1 tahun</Select.Option>
                <Select.Option value="2">2 tahun</Select.Option>
                <Select.Option value="3">3 tahun</Select.Option>
                <Select.Option value="5">5 tahun</Select.Option>
                <Select.Option value="6">6 tahun</Select.Option>
                <Select.Option value="7">7 tahun</Select.Option>
                <Select.Option value="8">8 tahun</Select.Option>
                <Select.Option value="9">9 tahun</Select.Option>
                <Select.Option value="10">10 tahun</Select.Option>
                <Select.Option value="11">{'>'}10 tahun</Select.Option>
            </Select>
        );
    }
}

export default CustomerMariageAge;
