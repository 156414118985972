import React, {Component} from "react";
import {BookingStates} from "./types/BookingTypes";
import {Link} from "react-router-dom";
import {Header} from "antd/es/layout/layout";
import {withRouter} from "../../hoc/withRouter";
import './Booking.css';
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Select from "antd/lib/select";
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import dealerConfig from "../../config/dataConfig/dealerConfig";
import LeasingSurveyTime from "../../components/submission/LeasingSurveyTime";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import moment from "moment";
import DatePicker from "antd/lib/date-picker";
import {bookingApiServices} from "../../services/booking/BookingApiService";
import TextArea from "antd/es/input/TextArea";
import {trackLogServices} from "../../services/track/TrackLogService";

class Booking extends Component<any, BookingStates> {
    private readonly initState!: BookingStates;

    constructor(props: any) {
        super(props);
        const {match} = this.props;
        const dealerCode = match.params.dealerCode ?? null;

        document.title = `Amartahonda | Booking`;

        this.initState = {
            clientPhoneNumber: "",
            clientName: "",
            appointmentDate: moment(new Date(), 'YYYY-MM-DD').add(1, 'days'),
            appointmentTime: "10:00",
            processBooking: false,
            vendorCode: dealerCode,
            vendorName: "",
            vehicleLicensePlate: "",
            vehicleOdometer: "",
            note: "",
            agentCode: "",
            listModel: [],
            modelSelected: null,
        }
        this.state = {...this.initState}
    }

    onFieldChange = <T extends keyof Pick<any, "clientPhoneNumber" | "clientName" | "vehicleLicensePlate" | "vehicleOdometer" | "note" | "agentCode">>(target: T, value: any) => {
        const currentState = {...this.state};
        switch (target) {
            case "clientPhoneNumber":
                currentState.clientPhoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "clientName":
                currentState.clientName = value;
                break;
            case "vehicleLicensePlate":
                currentState.vehicleLicensePlate = value;
                break;
            case "vehicleOdometer":
                currentState.vehicleOdometer = value;
                break;
            case "note":
                currentState.note = value;
                break;
            case "agentCode":
                currentState.agentCode = value;
                break;
        }

        if (target === 'clientPhoneNumber') {
            if (currentState.clientPhoneNumber.length > 1) {
                if (currentState.clientPhoneNumber.substring(0, 2) !== '08') {
                    currentState.clientPhoneNumber = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onClickBook = async () => {
        if (!this.state.vendorCode || !this.state.clientPhoneNumber || !this.state.clientName || !this.state.vehicleLicensePlate || !this.state.modelSelected) {
            Modal.error({
                title: 'Proses Gagal',
                content: "Mohon Lengkapi Data"
            });
            return false;
        }

        this.setState({
            processBooking: true
        });

        const input = [
            {
                type: "workshop",
                vendor_code: this.state.vendorCode,
                vendor_name: this.state.vendorName,
                appointment_time: moment(this.state?.appointmentDate).format("YYYY-MM-DD") + " " + this.state.appointmentTime + ":00",
                client_phone_number: this.state.clientPhoneNumber,
                client_name: this.state.clientName,
                source: "amartahonda.com",
                vehicle_license_plate: this.state.vehicleLicensePlate,
                vehicle_odometer: Number(this.state.vehicleOdometer),
                vehicle_model: this.state.modelSelected,
                note: this.state.note,
                agen_code: this.state?.agentCode ?? ""
            }
        ]

        try {
            await bookingApiServices.createBooking(
                input
            ).then(successData => {
                this.setState({
                    clientPhoneNumber: "",
                    clientName: "",
                    appointmentDate: moment(new Date(), 'YYYY-MM-DD'),
                    appointmentTime: "10:00",
                    processBooking: false,
                    vendorCode: null,
                    vendorName: "",
                    vehicleLicensePlate: "",
                    vehicleOdometer: "",
                    note: "",
                    agentCode: "",
                });

                Modal.success({
                    title: 'Proses Sukses',
                    content: 'Terimakasih telah mengisi data booking, data anda telah kami catat'
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.promisedSetState({
                processBooking: false
            });
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            vendorCode: value,
            vendorName: option?.children[3] ?? ""
        });
    }

    onChangeModel = (value: any, option: any) => {
        this.setState({
            modelSelected: value
        })
    }

    onLeasingSurveyTimeChange = (data: any) => {
        this.setState({
            appointmentTime: data.value
        });
    }

    surveyDateChange = (data: any) => {
        if (data) {
            this.setState({
                appointmentDate: data
            });
        }
    }

    async componentDidMount() {
        await trimitraCatalogServices.getCatalogVariant({
            areaCode: "BANDUNG"
        }).then(dataVariantHome => {
            const listModel = [];
            for (const item of dataVariantHome.data.data) {
                if (listModel.indexOf(item.model_name) === -1) {
                    listModel.push(item.model_name);
                }
            }
            this.setState({
                listModel: listModel
            });
        });

        trackLogServices.sendTrackLog({
            eventName: `booking service page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Layout className="layout">
                <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                    <Header className={`desktop-header-search`} style={{backgroundColor: '#fff', paddingBottom: 55, paddingTop: 7, borderBottom: "#f0f2f5 3px solid"}}>
                        <Row>
                            <Col span={3}>
                                <Link to={`/`}><LeftOutlined className={'hedaer2-back'}/></Link>
                            </Col>
                            <Col span={21}>
                                <b className={`track-header`}>Booking Service</b>
                            </Col>
                        </Row>
                    </Header>

                    <div style={{minHeight: 500, backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                        <Form layout="vertical" className={`track-form`}>
                            <Form.Item>
                                <div className={`text-small-grey`}>Nomor Polisi Kendaraan *</div>
                                <Input
                                    value={this.state.vehicleLicensePlate}
                                    placeholder="Masukan Nomor Polisi Kendaraan"
                                    onChange={event => this.onFieldChange("vehicleLicensePlate", event.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>Pilih Lokasi Service *</div>
                                <Select
                                    placeholder="Pilih Dealer"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={this.onChange}
                                    value={this.state.vendorCode}
                                >
                                    {dealerConfig.map((item: any, i: number) =>
                                        <Select.Option key={i} value={item.code}>({item.city_group}) {item.name}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>Nama Anda *</div>
                                <Input
                                    value={this.state.clientName}
                                    placeholder="Masukan Nama Anda"
                                    onChange={event => this.onFieldChange("clientName", event.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>Model Kendaraan *</div>
                                <Select
                                    placeholder="Pilih Model"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={this.onChangeModel}
                                    value={this.state.modelSelected}
                                >
                                    {this.state.listModel.map((item: any, i: number) =>
                                        <Select.Option key={i} value={item}>{item}</Select.Option>
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>Nomor Telpon *</div>
                                <Input
                                    value={this.state.clientPhoneNumber}
                                    maxLength={17}
                                    placeholder="Format: 08xxxxxxxx"
                                    onChange={event => this.onFieldChange("clientPhoneNumber", event.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>Odometer Kendaraan</div>
                                <Input
                                    type={"number"}
                                    value={this.state.vehicleOdometer}
                                    placeholder="Masukan Odometer Kendaraan"
                                    onChange={event => this.onFieldChange("vehicleOdometer", event.target.value)}
                                />
                            </Form.Item>
                            <Form.Item className={'padTop-7'}>
                                <div className={`text-small-grey`}>Tanggal Service</div>
                                <DatePicker
                                    value={moment(this.state.appointmentDate, 'YYYY-MM-DD')}
                                    onChange={event => this.surveyDateChange(event)}
                                    format={'YYYY-MM-DD'}
                                    disabledDate={current => {
                                        return current && (current < moment().add(0, "day") || current > moment().add(14, "day"));
                                    }}
                                    style={{width: "100%"}}/>
                            </Form.Item>
                            <Form.Item>
                                <div className={`text-small-grey`}>Jam Service</div>
                                <LeasingSurveyTime onLeasingSurveyTimeChangeProps={this.onLeasingSurveyTimeChange} value={this.state.appointmentTime}/>
                            </Form.Item>
                            <Form.Item>
                                <div className={`text-small-grey`}>Catatan</div>
                                <TextArea showCount maxLength={200} value={this.state.note} onChange={event => this.onFieldChange("note", event.target.value)} placeholder="Catatan"/>
                            </Form.Item>

                            <Form.Item>
                                <div className={`text-small-grey`}>kode Agen</div>
                                <Input
                                    value={this.state.agentCode}
                                    placeholder="Kode Agen (jika memiliki)"
                                    onChange={event => this.onFieldChange("agentCode", event.target.value)}
                                />
                            </Form.Item>

                            <Button loading={this.state.processBooking} onClick={() => this.onClickBook()} style={{background: "#64b48f", color: "#fff", borderColor: "#64b48f"}}> Buat Booking</Button>
                        </Form>
                    </div>
                </Col>
            </Layout>
        );
    }
}

export default withRouter(Booking);
